import { Course } from './Course';
import { Source } from './Source';

export class AddEnquiry{
    name:string;
    address:string
    mobile1:number
    mobile2:number
    district:string
    centerPlace:string
    courseNeeded:string
    followUpOption:string
    uniquId:string
    todayDate:string
    studentId:number
    CenterName:string
    comment:string
    followUpDate:string
    days:number
    followStatus:number
    applicationNo:string
    AmounntPaid:number
    totalCall:number=0
    notConnected:number=0
    notAttended:number=0
    followuptotalCall:number
    followupnotConnected:number
    followupnotAttended:number
    lastFollowUp:string
    nextFollowUp:string
    message:string
    journalName:string
    financialYear:string
    lastupdate:string
    sourceId:string
    joiningstatus:number  //1-joined 2-admission confirmed
    fromenquirydropout:boolean
    enquirydroouttotalCall:number
    enquirydrooutnotConnected:number
    enquirydrooutnotAttended:number
    prefollowlastfollowupdate:string
}
export class AddEnquiryList{
    enquiry:AddEnquiry=new AddEnquiry()
    course:Course[]=new Array()
    source:Source[]=new Array()
}