import { Component, OnInit } from '@angular/core';
import { CenterPlace } from '../models/Center';
import { DbService } from '../dbService';
import { element } from '@angular/core/src/render3';
import { AddEnquiry, AddEnquiryList } from '../models/AddEnquiry';
import { Course } from '../models/Course';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { Common } from '../models/common';
import { stdId } from '../models/stdId';

@Component({
  selector: 'app-view-enquiry',
  templateUrl: './view-enquiry.component.html',
  styleUrls: ['./view-enquiry.component.css']
})
export class ViewEnquiryComponent implements OnInit {
  fromDate;
  enquiryDetails: AddEnquiry[] = []
  toDate;
  district
  centerPlaceList: CenterPlace[] = []
  tempCenterPlaceList: CenterPlace[] = []
  centerData: CenterPlace[] = []
  centerList
  districtList
  Name;
  idFrom;
  to;
  centerPlace;
  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  tempenquiryDetailsList: AddEnquiryList[] = []
  enquiryDetailsList: AddEnquiryList[] = []
  course: Course[] = []
  enquiryList: AddEnquiryList[] = []
  currentyear
  count:number
  previousyear
  nextyear
  constructor(private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieService, private router: Router) {

      let year = new Date().getFullYear()
      let month = new Date().getMonth()
      if(month > 2){
        this.currentyear= year+"-"+(year+1)
        this.previousyear = (year-1) + "-" + year
        this.nextyear = (year+1) + "-" + (year+2)
      }
      if(month <= 2){
        this.currentyear = (year-1) + "-" + (year)
        this.previousyear = (year-1) + "-" + year
        this.nextyear = (year+1) + "-" + (year+2)

      }
    this.dbservice.getCourse("course").subscribe(data => {
      this.course = data['Data']
      this.dbservice.GetRowforDB("centers").subscribe(data => {
        this.tempCenterPlaceList = data['Data']
        for(let i=0;i<this.tempCenterPlaceList.length;i++){
          if(this.tempCenterPlaceList[i].hidden != true && this.tempCenterPlaceList[i].Financialyear == this.currentyear){
            this.centerPlaceList.push(this.tempCenterPlaceList[i])
          }
        }
        this.centerData = this.centerPlaceList
     
        // this.dbservice.getDistrict().subscribe(data => {
        //   this.districtList = data['Data']
          this.getEnquireyRows()
        },
          err => {
            this.dbservice.LogError(err)
          })
      },
        err => {
          this.dbservice.LogError(err)
        })
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   })


  }
  getEnquireyRows() {
    this.dbservice.GetRows("AddEnquiry").subscribe(data => {
      this.enquiryDetails = data['Data']
      for (let i = 0; i < this.enquiryDetails.length; i++) {
        let element = this.enquiryDetails[i]
        if (element != null) {
          let enquiryDetails = new AddEnquiryList()
          let enquiryDetailsObj: AddEnquiry = element
          enquiryDetails.enquiry = enquiryDetailsObj
          let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
          enquiryDetails.course.push(itemData[0])
          this.tempenquiryDetailsList.push(enquiryDetails)

        }
      }
      if (this.authenticationService.centerName != 'undefined') {
      this.enquiryList = this.tempenquiryDetailsList.filter(s => s.enquiry.followUpOption == "enquiry" && s.enquiry.CenterName == this.authenticationService.centerName)
      }
      else{
        this.enquiryList = this.tempenquiryDetailsList.filter(s => s.enquiry.followUpOption == "enquiry" )
      }
      this.enquiryDetailsList = this.enquiryList
      if(this.enquiryList.length != 0){
      for(let i=0;i<this.enquiryList.length;i++){
        this.count=i+1
      }
    }
    else{
      this.count=0
    }
    },
      err => {
        this.dbservice.LogError(err)
      })


  }
  updateData(UniqueId) {

    this.router.navigate(['save-Information', UniqueId])
  }
  // preFollow(){
  //   var temp
  //   let Data={
  //     "followUpOption":
  //   }
  //   this.dbservice.UpdateRows("AddEnquiry",Data,whereBody).subscribe(data => {

  //   },
  //     err => {
  //       this.dbservice.LogError(err)
  //     })
  // }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }

  getCenterPlace() {
    this.enquiryList=[]
    this.enquiryList= this.enquiryDetailsList.filter(s => s.enquiry.district == this.district)
  }
  CenterPlaceDeatils() {
    this.enquiryList = this.enquiryDetailsList.filter(s => s.enquiry.centerPlace == this.centerPlace)
  }


  GetById() {
    this.enquiryList = this.enquiryDetailsList.filter(d => {
      var id = d.enquiry.studentId
      return this.idFrom <= id && id <= this.to
    });
  }

  dateFilter() {
    this.enquiryList = []
    let tempFromDate = this.formatDate(this.fromDate)
    let tempToDate = this.formatDate(this.toDate)
    let fromYear = tempFromDate.slice(6)
    let toYear = tempToDate.slice(6)
    let fromMonth = tempFromDate.slice(3, 5)
    let toMonth = tempToDate.slice(3, 5)
    let fromDay = tempFromDate.slice(0, 2)
    let toDay = tempToDate.slice(0, 2)
    let yearList: AddEnquiryList[] = []
    let monthList: AddEnquiryList[] = []

    for (let i = 0; i < this.enquiryDetailsList.length; i++) {
      let year = this.enquiryDetailsList[i].enquiry.todayDate.slice(6)
      if (year >= fromYear && year <= toYear) {
        yearList.push(this.enquiryDetailsList[i])
      }
    }
    for (let i = 0; i < yearList.length; i++) {
      let month = yearList[i].enquiry.todayDate.slice(3, 5)
      if (month >= fromMonth && month <= toMonth) {
        monthList.push(yearList[i])
      }
    }
    for (let i = 0; i < monthList.length; i++) {
      let day = monthList[i].enquiry.todayDate.slice(0, 2)
      let monthDay = monthList[i].enquiry.todayDate.slice(3, 5)
      if (monthDay == fromMonth) {
        if (day >= fromDay && day <= toDay) {
          this.enquiryList.push(monthList[i])
        }
      }
      else if (monthDay == toMonth) {
        if (day >= fromDay && day <= toDay) {

          this.enquiryList.push(monthList[i])
        }
      }
      else {
        this.enquiryList.push(monthList[i])
      }
    }
  }
    
 
  ngOnInit() {
    this.authenticationService.userName = this.cookieService.get('userName');
    this.authenticationService.userId = this.cookieService.get('userId');
    this.authenticationService.userType = this.cookieService.get('userType');
    this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    this.authenticationService.password = this.cookieService.get('password');
    this.authenticationService.centerName = this.cookieService.get('centerName');
    this.authenticationService.userAuthentication().subscribe(resdata => {
      if (resdata.Data.length == 1) {
        this.authenticationService.isAutherisedUser = true;
        if (this.authenticationService.userType != 'Super Admin') {
          console.log('this is not super admin');
          let whereBody = {
            "featureId": "2c7f20f5-b7f7-481e-a2ae-52b3f540dfdb",
            "userTypeId": this.authenticationService.userTypeId,
          }
          this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
            console.log(resdata);
            if (resdata.Data.length > 0) {
              this.isprivilegeChecked = true;
              this.isAuthenticityChecked = true;
              this.dbservice.isLogin = true
              this.authenticationService.isLoggedIn = true
            }
            else {
              this.router.navigate(['/error'])
            }



          },
            err => {
              this.dbservice.LogError(err);

            },
            () => {

            }
          )

        }
        else {
          this.isAuthenticityChecked = true;
          this.authenticationService.isLoggedIn = true
          this.dbservice.isLogin = true
        }
      }
      if (this.authenticationService.isAutherisedUser != true) {
        this.router.navigate(['login'])
      }
    },
      err => {
        this.dbservice.LogError(err)
      },
      () => {
        console.log('completed');
      }
    )
  }

}
