import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DbService } from '../dbService';
import { AddEnquiry, AddEnquiryList } from '../models/AddEnquiry';
import { Course } from '../models/Course';
import { Center, CenterPlace } from '../models/Center';
import { CookieserviceService } from '../cookieservice.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-joined-info',
  templateUrl: './joined-info.component.html',
  styleUrls: ['./joined-info.component.css']
})
export class JoinedInfoComponent implements OnInit {

  enquiryDetails: AddEnquiry[] = []
  course: Course[] = []
  enquiryDetailsList: AddEnquiryList[] = []
  name;
  Name;
  district;
  districtList
  courseNeeded;
  comment
  id;
  amountPaid
  applicationNo;
  CenterName;
  followUpOption;
  todayDate = new Date()
  courseArray: Course[] = []
  centerDetailsList: Center[] = []
  centers: CenterPlace[] = []
  tempCenters: Center[] = []
  currentyear
  joiningstatus
  constructor(private route: ActivatedRoute, private router: Router,
    private fb: FormBuilder,
    private dbservice: DbService, public cookie: CookieserviceService, ) {
    this.Joinedcreateform()
    let year = new Date().getFullYear()
    let month = new Date().getMonth()
    if (month > 2) {
      this.currentyear = year + "-" + (year + 1)
    }
    if (month <= 2) {
      this.currentyear = (year - 1) + "-" + (year)

    }

    // this.dbservice.getDistrict().subscribe(data => {
    //   this.districtList = data['Data']

    this.dbservice.getCourse("course").subscribe(data => {
      this.course = data['Data']
      this.dbservice.GetRowforDB("centers").subscribe(data => {
        this.centers = data['Data']
        for (let i = 0; i < this.centers.length; i++) {
          let Obj = this.centers[i]
          if (Obj != null && Obj.hidden != true && Obj.Financialyear == this.currentyear) {
            let centerDetails = new Center()
            let centerDetailsObj: CenterPlace = Obj
            centerDetails.centerDetails = centerDetailsObj
            if (Obj.courseId != null) {
              for (let j = 0; j < Obj.courseId.length; j++) {
                let element = Obj.courseId[j]
                let itemData = this.course.filter(s => s.UniqueId == (element))
                centerDetails.course.push(itemData[0])
              }
            }
            this.centerDetailsList.push(centerDetails)
          }
        }

        this.tempCenters = this.centerDetailsList
        this.centerDetailsList = this.tempCenters.filter(s => s.centerDetails.DistrictId == this.district)


        this.id = this.route.snapshot.paramMap.get('UniqueId');
        this.dbservice.GetRows("AddEnquiry").subscribe(data => {
          this.enquiryDetails = data['Data']
          for (let i = 0; i < this.enquiryDetails.length; i++) {
            let element = this.enquiryDetails[i]
            if (element != null) {
              let enquiryDetails = new AddEnquiryList()
              let enquiryDetailsObj: AddEnquiry = element
              enquiryDetails.enquiry = enquiryDetailsObj
              let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
              enquiryDetails.course.push(itemData[0])
              this.enquiryDetailsList.push(enquiryDetails)
            }
          }
          if (this.id != undefined) {
            for (let i = 0; i < this.enquiryDetailsList.length; i++) {
              let Obj = this.enquiryDetailsList[i]


              if (Obj != null && Obj.enquiry.uniquId == this.id) {
                console.log(Obj.enquiry, 'object')
                this.name = Obj.enquiry.name
                this.district = Obj.enquiry.district
                this.CenterName = Obj.enquiry.CenterName
                this.courseNeeded = Obj.enquiry.courseNeeded
              }
            }
          }
          if (this.cookie.cookiecenter != 'undefined') {
            this.getCourse()
          }
          this.centerDetailsList = this.tempCenters.filter(s => s.centerDetails.DistrictId == this.district)
          this.CenterName = this.CenterName
          console.log(this.CenterName, 'center')
          console.log(this.district, 'district')
          console.log(this.centerDetailsList, 'center list')
        })
      })
    })

    // })
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }
  getCenter() {
    this.centerDetailsList = this.tempCenters.filter(s => s.centerDetails.DistrictId == this.district)
  }
  saveInfo() {
    this.followUpOption = "Joined"
    this.dbservice.getddtrackerstudentdetails('studentApplicationForm').subscribe(data => {
      let tempstudent = data['Data']
      let temp = tempstudent.filter(x => x.applicationNumber == this.applicationNo)
      if (temp.length == '0') {
        this.joiningstatus = 1
      }
      else {
        this.joiningstatus = 2
      }
      this.updateData()
    })
  }
  updateData() {
    let whereBody = {
      "uniquId": this.id
    }
    let data = {
      "todayDate": this.formatDate(this.todayDate),
      "comment": this.comment,
      "CenterName": this.CenterName,
      "followStatus": 2,
      "followUpOption": this.followUpOption,
      "applicationNo": this.applicationNo,
      "amountPaid": this.amountPaid,
      "joiningstatus": this.joiningstatus
    }
    this.dbservice.UpdateRows("AddEnquiry", data, whereBody).subscribe(data => {
      console.log(data)
      alert('Updated Successfully')

    })
  }
  getCourse() {
    let whereBody = {
      "CenterName": this.CenterName,
      "DistrictId": this.district
    }

    this.dbservice.GetRowforDB("centers", whereBody).subscribe(data => {
      this.centers = data['Data']
      this.courseArray = []
      for (let i = 0; i < this.course.length; i++) {
        for (let j = 0; j < this.centers.length; j++) {
          let element = this.centers[j]
          if (element.courseId != null && element.Financialyear == this.currentyear) {
            for (let k = 0; k < element.courseId.length; k++) {
              if (this.course[i].UniqueId == element.courseId[k]) {
                let c = new Course()
                c.Name = this.course[i].Name
                c.UniqueId = element.courseId[k]
                this.courseArray.push(c)
              }
            }
          }

        }
      }
      console.log(this.courseArray)
    },
      err => {
        this.dbservice.LogError(err)
      })
  }
  Joinedform = new FormGroup({
    amountPai: new FormControl(),
    applicationNumbe: new FormControl()
  })
  Joinedcreateform() {
    this.Joinedform = this.fb.group({
      amountPai: [null, Validators.required],
      applicationNumbe: [null, Validators.required]
    })
  }
  get amountPai() { return this.Joinedform.get('amountPai') }
  get applicationNumbe() { return this.Joinedform.get('applicationNumbe') }

  ngOnInit() {
  }

}
