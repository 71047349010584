import { Component, OnInit } from '@angular/core';
import { User, UserList, UserData } from '../models/user';
import { UserType } from '../models/usertype';
import { DbService } from '../../dbService';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  isprivilegeChecked: boolean;
  UserType;
  newUser: User = new User();
  userList: UserList[] = [];
  userTypeList: UserType[] = [];
  userData: UserData;
  isAuthenticityChecked: boolean;
  constructor(public dbservice: DbService, private router: Router,
    private route: ActivatedRoute, private authenticationService: AuthenticationService,
    private cookieService: CookieService) {


    let id = this.cookieService.get('userId');
    if (id != undefined) {
      this.dbservice.GetRows('User').subscribe(data => {
        this.userData = data
        for (let i = 0; i < this.userData.Data.length; i++) {
          let userObj = this.userData.Data[i];
          if (userObj.userId == id) {
            this.newUser = userObj
          }
        }

      })

    }
    this.dbservice.GetRows('UserType').subscribe(resdata => {
      this.userTypeList = new Array<UserType>();
      for (let i = 0; i <= resdata.Data.length; i++) {
        let c = new UserType();
        if (resdata.Data[i] != null) {

          c = resdata.Data[i];

          this.userTypeList.push(c);
        }
      }
    })
  }

  register() {


    console.log(this.newUser);
    let whereBody = {
      "userId": this.newUser.userId
    }
    let updateData = {
      "username": this.newUser.username,
      "password": this.newUser.password,
      "firstName": this.newUser.firstName,
      "lastName": this.newUser.lastName,
      "userTypeId": this.newUser.userTypeId,
      "remarks": this.newUser.remarks
    };
    this.dbservice.UpdateRows('User', updateData, whereBody).subscribe(resdata => {
      console.log(resdata);

    },
      err => { this.dbservice.LogError(err) },
      () => {
        alert('updated successfully');
        this.router.navigate(['home'])
      }
    )
  }


  ngOnInit() {
    this.authenticationService.userName = this.cookieService.get('userName');
    this.authenticationService.userId = this.cookieService.get('userId');
    console.log(this.authenticationService.userId, '**********')
    this.authenticationService.userType = this.cookieService.get('userType');
    this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    this.authenticationService.password = this.cookieService.get('password');
    console.log('userName :', this.authenticationService.userName, 'userId :', this.authenticationService.userId, 'userType :',
      this.authenticationService.userType, 'userTypeId :', this.authenticationService.userTypeId, 'password', this.authenticationService.password);
    this.authenticationService.userAuthentication().subscribe(resdata => {
      console.log(resdata);
      console.log(resdata.Data.length)
      if (resdata.Data.length == 1) {
        this.authenticationService.isAutherisedUser = true;
        this.dbservice.isLogin = true
        this.authenticationService.isLoggedIn=true

      }
      if (this.authenticationService.isAutherisedUser != true) {
        this.router.navigate(['login'])
      }
    },
      err => {
        this.dbservice.LogError(err)
      },
      () => {
        console.log('completed');
      }
    )
  }
  activate(user: User) {
    if (confirm('are you sure want to activate this user')) {
      let updateData = {
        "isActive": true
      }
      let whereBody = {
        "userId": user.userId
      };
      this.dbservice.UpdateRows('User', updateData, whereBody).subscribe(resdata => {
        console.log(resdata);

      },
        err => { this.dbservice.LogError(err) },
        () => {
          alert('activated successfully');
          user.isActive = true
        }
      )
    }
  }

  delete(key, user: User) {


    // this.db.database.ref(`user/${key}`).once("value", snapshot => {
    //   let sid = snapshot.key;
    //   if (snapshot.exists()) {
    //     if (confirm('Are you sure to delete ' + usr.username)) {

    //       var updates = {};
    //       updates['/user/' + sid] = JSON.stringify(usr);
    //       try {
    //         let up = this.db.database.ref().update(updates);
    //         this.router.navigate(['/listuser']);
    //       }
    //       catch (ex) {
    //         alert("Error in Deleting user");
    //       }
    //     }
    //   }
    // })

  }



}


