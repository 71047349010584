import { Component, OnInit } from '@angular/core';
import { CookieserviceService } from '../cookieservice.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  feature;
  userType
  username
  centername
  isloggedin
  expiredDate
  u
  t
  f
  c
  user
  constructor(public cookieService: CookieserviceService,
    private route:ActivatedRoute,
    private cookie: CookieService,
    ) { 
      // this.route.queryParams.subscribe(params => {
      //   var userName = params['u'];
        // let password = params['p'];
        // let privilege = params['q']
        // let centerName = params['c']
        // let userType = params['t']
        // this.authenticationService.isLoggedIn = true
        // try {
        //   var u = window.atob(userName);
        //   var c = window.atob(centerName);
          // var t = window.atob(userType);
          // var p = window.atob(password)
          // this.cookieService.cookievalue = privilege;
          // this.cookieService.cookiename = u;
          // this.cookieService.password=p
          // this.cookieService.cookiecenter = c;
          // this.cookieService.cookieuser = t;
          // this.expiredDate = new Date();
          // this.expiredDate.setDate(this.expiredDate.getDate() + 1);
          // this.cookieService.setCookie(this.cookieService.cookiename, this.cookieService.cookievalue, this.expiredDate, this.cookieService.cookieuser, this.cookieService.cookiecenter)
          // console.log('cookiee user' ,this.ets.cookieuser )
      //   }
      //   catch (e) {
      //     console.log(e);
      //   }
      // });
      this.user = this.cookie.get('User');
      this.cookieService.cookiename = this.user
      console.log(this.user,'user')
      if (this.cookieService.cookiename == '' ) {
        window.location.href="http://ets.ttcmis.in"
      }     
    // this.feature = this.cookieService.get('feature');
    // this.userType = this.cookieService.get('userType');
    // this.username = this.cookieService.get('userName')
    // // this.isloggedin = this.cookieService.get('isLoggedIn')
    // this.centername = this.cookieService.get('centerName')
    // console.log(this.feature,'in feature')
    // console.log(this.username,'in userName')
    // console.log(this.centername,'centername')
    // this.u=window.btoa(this.username)
    // this.t=window.btoa(this.userType)
    // this.f=window.btoa(this.feature)
    // this.c=window.btoa(this.centername)
  }

  ngOnInit() {
  }

}
