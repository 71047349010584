export class SourceReport{
    source:string
    noOfEnquiry:number
    notInterested:number
    fakeCall:number
    centerNA:number
    followUp:number
    prefollowup:number
    joined:number
    admissionconfirmation:number
    dd:number
    enquirydropout:number
    admissionconfirmationpercent:string
    nextyear:number
    getadmission:number
}