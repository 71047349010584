import { Component, OnInit } from '@angular/core';
import { CenterPlace } from '../models/Center';
import { AddEnquiryList, AddEnquiry } from '../models/AddEnquiry';
import { Course } from '../models/Course';
import { DbService } from '../dbService';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { CookieserviceService } from '../cookieservice.service';
import { Source } from '../models/Source';
@Component({
  selector: 'app-enquirydropout',
  templateUrl: './enquirydropout.component.html',
  styleUrls: ['./enquirydropout.component.css']
})
export class EnquirydropoutComponent implements OnInit {
  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  districtList
  centerPlaceList: CenterPlace[] = []
  tempCenterPlaceList: CenterPlace[] = []
  centerData: CenterPlace[] = []
  district
  centerList
  prefollowList: AddEnquiryList[] = []
  centerNotAvailableList: AddEnquiryList[] = []
  tempCenterNotAvailableList: AddEnquiryList[] = []
  fromDate;
  toDate
  centerPlace
  idFrom
  to
  Name
  enquiryDetails: AddEnquiry[] = []
  course: Course[] = []
  currentyear
  count: number
  previousyear
  nextyear
  financialYear
  notConnected;
  notAttended;
  totalCall;
  sourcelist: Source[] = []
  constructor(private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieserviceService, private router: Router) {
    let year = new Date().getFullYear()
    let month = new Date().getMonth()
    if (month > 2) {
      this.currentyear = year + "-" + (year + 1)
      this.previousyear = (year - 1) + "-" + year
      this.nextyear = (year + 1) + "-" + (year + 2)
    }
    if (month <= 2) {
      this.currentyear = (year - 1) + "-" + (year)
      this.previousyear = (year - 1) + "-" + year
      this.nextyear = (year + 1) + "-" + (year + 2)

    }
    this.dbservice.GetRowforDB("centers").subscribe(data => {
      this.tempCenterPlaceList = data['Data']
      for (let i = 0; i < this.tempCenterPlaceList.length; i++) {
        if (this.tempCenterPlaceList[i].hidden != true && this.tempCenterPlaceList[i].Financialyear == this.currentyear) {
          this.centerPlaceList.push(this.tempCenterPlaceList[i])
        }
      }
      this.centerData = this.centerPlaceList

      this.dbservice.GetRowforDB("course").subscribe(data => {
        this.course = data['Data']
        this.dbservice.GetRows("AddEnquiry").subscribe(data => {
          this.enquiryDetails = data['Data']
          this.dbservice.GetRows('Source').subscribe(data => {
            this.sourcelist = data['Data']
          for (let i = 0; i < this.enquiryDetails.length; i++) {
            let element = this.enquiryDetails[i]
            if (element != null) {
              let enquiryDetails = new AddEnquiryList()
              let enquiryDetailsObj: AddEnquiry = element
              enquiryDetails.enquiry = enquiryDetailsObj
              let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
              enquiryDetails.course.push(itemData[0])
              let sourcedata = this.sourcelist.filter(s => s.uniqueId == (element.sourceId))
                if (sourcedata[0] != undefined) {
                  enquiryDetails.source.push(sourcedata[0])
                }
              this.tempCenterNotAvailableList.push(enquiryDetails)
            }
          }
          if (this.cookieService.cookiecenter != 'undefined') {
            this.centerNotAvailableList = this.tempCenterNotAvailableList.filter(s => s.enquiry.followUpOption == "Enquiry DropOut" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2)
              && s.enquiry.CenterName == this.cookieService.cookiecenter)
          }
          else {
            this.centerNotAvailableList = this.tempCenterNotAvailableList.filter(s => s.enquiry.followUpOption == "Enquiry DropOut" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2))
          }
          this.tempCenterNotAvailableList = this.centerNotAvailableList
          
          if (this.centerNotAvailableList.length != 0) {
            this.count = this.centerNotAvailableList.length
          }
          else {
            this.count = 0
          }
          this.isAuthenticityChecked = true
          this.centerNotAvailableList.sort((a, b) => a.enquiry.enquirydroouttotalCall - b.enquiry.enquirydroouttotalCall)
        },
          err => {
            this.dbservice.LogError(err)
          })

      },
        err => {
          this.dbservice.LogError(err)
        })
    },
      err => {
        this.dbservice.LogError(err)
      })
    })
  }

  getYear() {
    this.centerNotAvailableList = []
    for (let i = 0; i < this.tempCenterNotAvailableList.length; i++) {
      let year: any = this.formatDate(this.tempCenterNotAvailableList[i].enquiry.todayDate).slice(6)
      let m = this.formatDate(this.tempCenterNotAvailableList[i].enquiry.todayDate)
      let month = m.slice(0, 2)
      if (month > '02') {
        let currentyear = year + "-" + (parseInt(year) + 1)
        this.tempCenterNotAvailableList[i].enquiry.financialYear = currentyear
      }
      else if (month <= '02') {
        let currentyear = (parseInt(year) - 1) + "-" + (year)
        this.tempCenterNotAvailableList[i].enquiry.financialYear = currentyear
      }
    }
    for (let i = 0; i < this.tempCenterNotAvailableList.length; i++) {
      if (this.tempCenterNotAvailableList[i].enquiry.financialYear == this.financialYear) {
        this.centerNotAvailableList.push(this.tempCenterNotAvailableList[i])
      }
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }
  dateFilter() {
    this.centerNotAvailableList = []
    let tempFromDate = this.formatDate(this.fromDate)
    let tempToDate = this.formatDate(this.toDate)
    let fromYear = tempFromDate.slice(6)
    let toYear = tempToDate.slice(6)
    let fromMonth = tempFromDate.slice(3, 5)
    let toMonth = tempToDate.slice(3, 5)
    let fromDay = tempFromDate.slice(0, 2)
    let toDay = tempToDate.slice(0, 2)
    let yearList: AddEnquiryList[] = []
    let monthList: AddEnquiryList[] = []

    for (let i = 0; i < this.tempCenterNotAvailableList.length; i++) {
      let year = this.tempCenterNotAvailableList[i].enquiry.todayDate.slice(6)
      if (year >= fromYear && year <= toYear) {
        yearList.push(this.tempCenterNotAvailableList[i])
      }
    }
    for (let i = 0; i < yearList.length; i++) {
      let month = yearList[i].enquiry.todayDate.slice(3, 5)
      if (month >= fromMonth && month <= toMonth) {
        monthList.push(yearList[i])
      }
    }
    for (let i = 0; i < monthList.length; i++) {
      let day = monthList[i].enquiry.todayDate.slice(0, 2)
      let monthDay = monthList[i].enquiry.todayDate.slice(3, 5)
      if (monthDay == fromMonth) {
        if (day >= fromDay && day <= toDay) {
          this.centerNotAvailableList.push(monthList[i])
        }
      }
      else if (monthDay == toMonth) {
        if (day >= fromDay && day <= toDay) {

          this.centerNotAvailableList.push(monthList[i])
        }
      }
      else {
        this.centerNotAvailableList.push(monthList[i])
      }
    }
  }

  FollowUp(UniqueId) {

    this.router.navigate(['save-Information', UniqueId])
  }
  joinInfo(UniqueId) {

    this.router.navigate(['join-info', UniqueId])
  }
  notConnectedCount(s) {
    this.tempCenterNotAvailableList = []
    this.centerNotAvailableList = []
    if(s.enquirydrooutnotAttended == undefined){
      s.enquirydrooutnotAttended = 0

    }
    if(s.enquirydrooutnotConnected == undefined){
      s.enquirydrooutnotConnected = 0
    }
    if (s.enquirydrooutnotConnected != 0) {
      this.notConnected = s.enquirydrooutnotConnected + 1
      this.totalCall = s.enquirydrooutnotAttended + this.notConnected
    }
    else if (s.enquirydrooutnotAttended == 0 && s.enquirydrooutnotConnected == 0) {
      this.notConnected = 1
      this.totalCall = 1
    }
    else {
      this.notConnected = 1
      this.totalCall = s.enquirydrooutnotAttended + this.notConnected
    }
    let whereBody = {
      "uniquId": s.uniquId
    }
    let data = {
      "enquirydrooutnotConnected": this.notConnected,
      "enquirydroouttotalCall": this.totalCall
    }
    this.dbservice.UpdateRows("AddEnquiry", data, whereBody).subscribe(data => {
      this.dbservice.GetRowforDB("centers").subscribe(data => {
        this.tempCenterPlaceList = data['Data']
        for (let i = 0; i < this.tempCenterPlaceList.length; i++) {
          if (this.tempCenterPlaceList[i].hidden != true && this.tempCenterPlaceList[i].Financialyear == this.currentyear) {
            this.centerPlaceList.push(this.tempCenterPlaceList[i])
          }
        }
        this.centerData = this.centerPlaceList

        this.dbservice.GetRowforDB("course").subscribe(data => {
          this.course = data['Data']
          this.dbservice.GetRows("AddEnquiry").subscribe(data => {
            this.enquiryDetails = data['Data']
            for (let i = 0; i < this.enquiryDetails.length; i++) {
              let element = this.enquiryDetails[i]
              if (element != null) {
                let enquiryDetails = new AddEnquiryList()
                let enquiryDetailsObj: AddEnquiry = element
                enquiryDetails.enquiry = enquiryDetailsObj
                let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
                enquiryDetails.course.push(itemData[0])
                this.tempCenterNotAvailableList.push(enquiryDetails)
              }
            }
            if (this.cookieService.cookiecenter != 'undefined') {
              this.centerNotAvailableList = this.tempCenterNotAvailableList.filter(s => s.enquiry.followUpOption == "Enquiry DropOut" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2)
                && s.enquiry.CenterName == this.cookieService.cookiecenter)
            }
            else {
              this.centerNotAvailableList = this.tempCenterNotAvailableList.filter(s => s.enquiry.followUpOption == "Enquiry DropOut" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2))
            }
            this.tempCenterNotAvailableList = this.centerNotAvailableList
            if (this.centerNotAvailableList.length != 0) {
              this.count = this.centerNotAvailableList.length
            }
            else {
              this.count = 0
            }
            this.isAuthenticityChecked = true
            this.centerNotAvailableList.sort((a, b) => a.enquiry.enquirydroouttotalCall - b.enquiry.enquirydroouttotalCall)

          })
        })
      })
    })
  }
  notAttendedCount(s) {
    this.tempCenterNotAvailableList = []
    this.centerNotAvailableList = []
    if(s.enquirydrooutnotAttended == undefined){
      s.enquirydrooutnotAttended = 0

    }
    if(s.enquirydrooutnotConnected == undefined){
      s.enquirydrooutnotConnected = 0
    }
    if (s.enquirydrooutnotAttended != 0) {
      this.notAttended = s.enquirydrooutnotAttended + 1
      this.totalCall = s.enquirydrooutnotConnected + this.notAttended
    }
    else if (s.enquirydrooutnotAttended == 0 && s.enquirydrooutnotConnected == 0) {
      this.notAttended = 1
      this.totalCall = 1
    }
    else {
      this.notAttended = 1
      this.totalCall = s.enquirydrooutnotAttended + this.notConnected
    }
    let whereBody = {
      "uniquId": s.uniquId
    }
    let data = {
      "enquirydrooutnotAttended": this.notAttended,
      "enquirydroouttotalCall": this.totalCall
    }
    this.dbservice.UpdateRows("AddEnquiry", data, whereBody).subscribe(data => {
      this.dbservice.GetRowforDB("centers").subscribe(data => {
        this.tempCenterPlaceList = data['Data']
        for (let i = 0; i < this.tempCenterPlaceList.length; i++) {
          if (this.tempCenterPlaceList[i].hidden != true && this.tempCenterPlaceList[i].Financialyear == this.currentyear) {
            this.centerPlaceList.push(this.tempCenterPlaceList[i])
          }
        }
        this.centerData = this.centerPlaceList

        this.dbservice.GetRowforDB("course").subscribe(data => {
          this.course = data['Data']
          this.dbservice.GetRows("AddEnquiry").subscribe(data => {
            this.enquiryDetails = data['Data']
            for (let i = 0; i < this.enquiryDetails.length; i++) {
              let element = this.enquiryDetails[i]
              if (element != null) {
                let enquiryDetails = new AddEnquiryList()
                let enquiryDetailsObj: AddEnquiry = element
                enquiryDetails.enquiry = enquiryDetailsObj
                let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
                enquiryDetails.course.push(itemData[0])
                this.tempCenterNotAvailableList.push(enquiryDetails)
              }
            }
            if (this.cookieService.cookiecenter != 'undefined') {
              this.centerNotAvailableList = this.tempCenterNotAvailableList.filter(s => s.enquiry.followUpOption == "Enquiry DropOut" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2)
                && s.enquiry.CenterName == this.cookieService.cookiecenter)
            }
            else {
              this.centerNotAvailableList = this.tempCenterNotAvailableList.filter(s => s.enquiry.followUpOption == "Enquiry DropOut" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2))
            }
            this.tempCenterNotAvailableList = this.centerNotAvailableList
            if (this.centerNotAvailableList.length != 0) {
              this.count = this.centerNotAvailableList.length
            }
            else {
              this.count = 0
            }
            this.isAuthenticityChecked = true
            this.centerNotAvailableList.sort((a, b) => a.enquiry.enquirydroouttotalCall - b.enquiry.enquirydroouttotalCall)

          })
        })
      })
    })
  }
  CenterPlaceDeatils() {

    this.centerNotAvailableList = this.tempCenterNotAvailableList.filter(s => s.enquiry.centerPlace == this.centerPlace)
  }
  getCenterPlace() {
    this.centerNotAvailableList = []
    this.centerNotAvailableList = this.tempCenterNotAvailableList.filter(s => s.enquiry.district == this.district)
  }

  GetById() {

    this.centerNotAvailableList = this.tempCenterNotAvailableList.filter(d => {
      var id = d.enquiry.studentId
      return this.idFrom <= id && id <= this.to
    });
  }
  ngOnInit() {
  }

}
