import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from './authentication.service';
import { DbService } from './dbService';
import { CookieserviceService } from './cookieservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ETS';
  date = '28/05/2020'
  version = '1.5'
  expiredDate

  constructor(private cookieService: CookieserviceService, private route: ActivatedRoute,
    private http: HttpClient, public authenticationService: AuthenticationService,
    private router: Router, public dbservice: DbService) { }

  // logOut() {
  //   this.cookieService.delete('userName');
  //   this.cookieService.delete('userType')
  //   this.cookieService.delete('userType');
  //   this.cookieService.delete('userId');
  //   this.cookieService.delete('centerName');
  //   this.cookieService.delete('feature');
  //   this.cookieService.deleteAll()
  //   this.dbservice.isAutherisedUser = false
  //   this.authenticationService.isLoggedIn =false;
  //   this.dbservice.isLogin = false
  //   this.router.navigate(['/login'])
  // }
  ngOnInit() {
  
    
    this.http.get("../assets/DbName.txt", { responseType: 'text' }).subscribe(data => {
      this.dbservice.dbName = data
      this.date = this.date + '-' + this.dbservice.dbName
    },
    err => {
      this.dbservice.LogError(err);
    })
    // this.http.get("../assets/Key.txt", { responseType: 'text' }).subscribe(data => {
    //   this.dbservice.key = data
    // },
    // err => {
    //   this.dbservice.LogError(err);
    // })
    // this.http.get("../assets/Ip.txt", { responseType: 'text' }).subscribe(data => {
    //   this.dbservice.pyUrl = data
    // },
    // err => {
    //   this.dbservice.LogError(err);
    // })
    this.http.get("../assets/Title.txt", { responseType: 'text' }).subscribe(data => {
      this.dbservice.title = data
    },
      err => {
        this.dbservice.LogError(err);
      })
  }
}