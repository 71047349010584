import { Component, OnInit } from '@angular/core';
import { Course } from '../models/Course';
import { AddEnquiry, AddEnquiryList } from '../models/AddEnquiry';
import { DbService } from '../dbService';
import { CenterPlace } from '../models/Center';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { Common } from '../models/common';
import { stdId } from '../models/stdId';
import { CookieserviceService } from '../cookieservice.service';

@Component({
  selector: 'app-fake-call',
  templateUrl: './fake-call.component.html',
  styleUrls: ['./fake-call.component.css']
})
export class FakeCallComponent implements OnInit {
  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  districtList
  centerPlaceList: CenterPlace[] = []
  tempCenterPlaceList: CenterPlace[] = []
  centerData: CenterPlace[] = []
  district
  centerList
  excelData: AddEnquiry[] = []
  prefollowList: AddEnquiryList[] = []
  fakeCallList: AddEnquiryList[] = []
  tempFakeCallList: AddEnquiryList[] = []
  fromDate;
  toDate
  centerPlace
  idFrom
  to
  FilesToUpload
  FileNumber
  isUpload
  filename
  Name
  tempId
  stdIdDetails: stdId[] = []
  tempData = []
  test = []
  enquiryDetails: AddEnquiry[] = []
  course: Course[] = []
  currentyear
  count:number
  previousyear
  nextyear
  financialYear
  constructor(private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieserviceService, private router: Router) {
      let year = new Date().getFullYear()
      let month = new Date().getMonth()
      if(month > 2){
        this.currentyear= year+"-"+(year+1)
        this.previousyear = (year-1) + "-" + year
        this.nextyear = (year+1) + "-" + (year+2)
      }
      if(month <= 2){
        this.currentyear = (year-1) + "-" + (year)
        this.previousyear = (year-1) + "-" + year
        this.nextyear = (year+1) + "-" + (year+2)

      }

    // this.dbservice.getDistrict().subscribe(data => {
    //   this.districtList = data['Data']
      this.dbservice.GetRowforDB("centers").subscribe(data => {
          this.tempCenterPlaceList = data['Data']
          for(let i=0;i<this.tempCenterPlaceList.length;i++){
            if(this.tempCenterPlaceList[i].hidden != true && this.tempCenterPlaceList[i].Financialyear == this.currentyear){
              this.centerPlaceList.push(this.tempCenterPlaceList[i])
            }
          }
          this.centerData = this.centerPlaceList

        this.dbservice.GetRowforDB("course").subscribe(data => {
          this.course = data['Data']
          this.dbservice.GetRows("AddEnquiry").subscribe(data => {
            this.enquiryDetails = data['Data']
            for (let i = 0; i < this.enquiryDetails.length; i++) {
              let element = this.enquiryDetails[i]
              if (element != null) {
                let enquiryDetails = new AddEnquiryList()
                let enquiryDetailsObj: AddEnquiry = element
                enquiryDetails.enquiry = enquiryDetailsObj
                let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
                enquiryDetails.course.push(itemData[0])
                this.tempFakeCallList.push(enquiryDetails)
              }
            }
            if (this.cookieService.cookiecenter != 'undefined') {
              this.fakeCallList = this.tempFakeCallList.filter(s => s.enquiry.followUpOption == "Fake Call" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2)
                && s.enquiry.CenterName == this.cookieService.cookiecenter)
            }
            else {
              this.fakeCallList = this.tempFakeCallList.filter(s => s.enquiry.followUpOption == "Fake Call" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2))
            }
            this.tempFakeCallList = this.fakeCallList
            
            if(this.fakeCallList.length != 0){
            for(let i=0;i<this.fakeCallList.length;i++){
              this.count=i+1
            }
          }
          else{
            this.count=0
          }
          this.isAuthenticityChecked=true
          },

            err => {
              this.dbservice.LogError(err)
            })
        },
          err => {
            this.dbservice.LogError(err)
          })

      },
        err => {
          this.dbservice.LogError(err)
        })
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   })

  }

  getYear() {
    this.fakeCallList=[]
    for (let i = 0; i < this.tempFakeCallList.length; i++) {
      let year:any = this.formatDate(this.tempFakeCallList[i].enquiry.todayDate).slice(6)
      let m = this.formatDate(this.tempFakeCallList[i].enquiry.todayDate)
      let month=m.slice(0,2)
      if (month > '02') {
        let currentyear = year + "-" + (parseInt(year) + 1)
        this.tempFakeCallList[i].enquiry.financialYear = currentyear
      }
      else if (month <= '02') {
        let currentyear = (parseInt(year) - 1) + "-" + (year)
        this.tempFakeCallList[i].enquiry.financialYear = currentyear
      }
    }
    for(let i=0;i<this.tempFakeCallList.length;i++){
      if(this.tempFakeCallList[i].enquiry.financialYear == this.financialYear){
        this.fakeCallList.push(this.tempFakeCallList[i])
      }
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }
  dateFilter() {
    this.fakeCallList = []
    let tempFromDate = this.formatDate(this.fromDate)
    let tempToDate = this.formatDate(this.toDate)
    let fromYear = tempFromDate.slice(6)
    let toYear = tempToDate.slice(6)
    let fromMonth = tempFromDate.slice(3, 5)
    let toMonth = tempToDate.slice(3, 5)
    let fromDay = tempFromDate.slice(0, 2)
    let toDay = tempToDate.slice(0, 2)
    let yearList: AddEnquiryList[] = []
    let monthList: AddEnquiryList[] = []

    for (let i = 0; i < this.tempFakeCallList.length; i++) {
      let year = this.tempFakeCallList[i].enquiry.todayDate.slice(6)
      if (year >= fromYear && year <= toYear) {
        yearList.push(this.tempFakeCallList[i])
      }
    }
    for (let i = 0; i < yearList.length; i++) {
      let month = yearList[i].enquiry.todayDate.slice(3, 5)
      if (month >= fromMonth && month <= toMonth) {
        monthList.push(yearList[i])
      }
    }
    for (let i = 0; i < monthList.length; i++) {
      let day = monthList[i].enquiry.todayDate.slice(0, 2)
      let monthDay = monthList[i].enquiry.todayDate.slice(3, 5)
      if (monthDay == fromMonth) {
        if (day >= fromDay && day <= toDay) {
          this.fakeCallList.push(monthList[i])
        }
      }
      else if (monthDay == toMonth) {
        if (day >= fromDay && day <= toDay) {

          this.fakeCallList.push(monthList[i])
        }
      }
      else {
        this.fakeCallList.push(monthList[i])
      }
    }
  }

  CenterPlaceDeatils() {

    this.fakeCallList = this.tempFakeCallList.filter(s => s.enquiry.centerPlace == this.centerPlace)
  }
  getCenterPlace() {
    this.fakeCallList = []
    this.fakeCallList = this.tempFakeCallList.filter(s => s.enquiry.district == this.district)
  }

  GetById() {

    this.fakeCallList = this.tempFakeCallList.filter(d => {
      var id = d.enquiry.studentId
      return this.idFrom <= id && id <= this.to
    });
  }



  ngOnInit() {

    // this.authenticationService.userName = this.cookieService.get('userName');
    // this.authenticationService.userId = this.cookieService.get('userId');
    // this.authenticationService.userType = this.cookieService.get('userType');
    // this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    // this.authenticationService.password = this.cookieService.get('password');
    // this.authenticationService.centerName = this.cookieService.get('centerName');

    // this.authenticationService.userAuthentication().subscribe(resdata => {
    //   if (resdata.Data.length == 1) {
    //     this.authenticationService.isAutherisedUser = true;
    //     if (this.authenticationService.userType != 'Super Admin') {
    //       console.log('this is not super admin');
    //       let whereBody = {
    //         "featureId": "d7cc70e4-a086-4e13-8013-169597b66979",
    //         "userTypeId": this.authenticationService.userTypeId,
    //       }
    //       this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
    //         console.log(resdata);
    //         if (resdata.Data.length > 0) {
    //           this.isprivilegeChecked = true;
    //           this.isAuthenticityChecked = true;
    //           this.dbservice.isLogin = true
    //         }
    //         else {
    //           this.router.navigate(['/error'])
    //         }



    //       },
    //         err => {
    //           this.dbservice.LogError(err);

    //         },
    //         () => {

    //         }
    //       )

    //     }
    //     else {
    //       this.isAuthenticityChecked = true;
    //       this.dbservice.isLogin = true
    //       this.authenticationService.isLoggedIn = true
    //     }
    //   }
    //   if (this.authenticationService.isAutherisedUser != true) {
    //     this.router.navigate(['login'])
    //   }
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   },
    //   () => {

    //     this.dbservice.getEnquiry().subscribe(data => {
    //       this.test = data['Data']
    //       console.log('completed');
    //       console.log(this.test, 'test')
    //     },
    //       err => {
    //         this.dbservice.LogError(err)
    //       })
    //   }
    // )
  }

}
