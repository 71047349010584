import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { Router, ActivatedRoute } from '@angular/router';
import { Course } from '../models/Course';

@Component({
  selector: 'app-edit-course',
  templateUrl: './edit-course.component.html',
  styleUrls: ['./edit-course.component.css']
})
export class EditCourseComponent implements OnInit {

  courseName;
  id;
  courseList: Course[] = []
  constructor(private dbservice: DbService,
    private route: ActivatedRoute,
    private router:Router) {

    this.id = this.route.snapshot.paramMap.get('UniqueId');
    this.dbservice.GetRows("EtsCourse").subscribe(data => {
      this.courseList = data['Data']
      for (let i = 0; i < this.courseList.length; i++) {
        if (this.courseList[i].UniqueId == this.id) {
          this.courseName = this.courseList[i].Name
        }
      }
    },
      err => {
        this.dbservice.LogError(err)
      })
  }

  update(){
    let whereBody={
      "UniqueId":this.id
    }
    let data={

      "Name":this.courseName
    }
    this.dbservice.UpdateRows("EtsCourse",data,whereBody).subscribe(data => {
      console.log(data)
      this.router.navigate(['/add-course'])    
    },
      err => {
        this.dbservice.LogError(err)
      })
  }

  ngOnInit() {
  }

}
