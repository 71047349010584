export class EnquiryReport{

    district;
    Center=[]
    intialEnq:number=0
    firstCall:number=0
    followUp:number=0
    admitted:number=0
    notInt:number=0
    fakeCall:number=0
    centerNA:number=0
    todaysFol:number=0
    totalEnquiry:number=0
    enqDate:number=0
    todate=[]
  
}

export class JournalReport{

    journalName:string
    intialEnq:number=0
    firstCall:number=0
    followUp:number=0
    admitted:number=0
    notInt:number=0
    fakeCall:number=0
    centerNA:number=0
    todaysFol:number=0
    totalEnquiry:number=0
    enqDate:number=0
    todate=[]
  
}


export class EnquiryCenterReport{
    district:string;
    Center:string
    intialEnq:number=0
    firstCall:number=0
    followUp:number=0
    admitted:number=0
    notInt:number=0
    fakeCall:number=0
    centerNA:number=0
    todaysFol:number=0
    totalEnquiry:number=0
    enqDate:Date

}