import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { AddEnquiry } from '../models/AddEnquiry';
import { Common } from '../models/common';
import { CenterPlace } from '../models/Center';
import { Course } from '../models/Course';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { stdId } from '../models/stdId';
import { Source } from '../models/Source';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  isEnable: boolean
  name
  centerName
  address
  mobile1
  mobile2
  tempId
  district
  centerPlace
  courseNeeded
  tempCourseNeeded
  followUpOption
  uniquId;
  stdIdDetails:stdId[]=[]
  centerList;
  studentId;
  forSms:boolean
  todayDate = new Date()
  centerDetails: CenterPlace[] = []
  tempCenterPlaceList: CenterPlace[] = []
  centerPlaceList: CenterPlace[] = []
  centerData: CenterPlace[] = []
  centers = []
  centerArray: Course[] = []
  courseList: Course[] = []
  courseArray: Course[] = []
  enquiryDetails: AddEnquiry[] = []
  districtList = []
  currentyear
  previousyear
  nextyear
  isHide:boolean
  isButton:boolean
  correspondingDetails:CenterPlace[]=[]
  newAddEnquiry: AddEnquiry = new AddEnquiry()
  isAuthenticityChecked: boolean
  isprivilegeChecked: boolean;
  isChecked
  id
  sourceList: Source[] = []
  sourceId
  constructor(private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieService, private router: Router) {

      let year = new Date().getFullYear()
      let month = new Date().getMonth()
      if(month > 2){
        this.currentyear= year+"-"+(year+1)
        this.previousyear = (year-1) + "-" + year
        this.nextyear = (year+1) + "-" + (year+2)
        console.log(this.currentyear,'-----')
      }
      if(month <= 2){
        this.currentyear = (year-1) + "-" + (year)
        this.previousyear = (year-2) + "-" + (year-1)
        this.nextyear = year + "-" + (year+1)

      }

    this.dbservice.GetRowforDB("centers").subscribe(data => {
      this.tempCenterPlaceList = data['Data']
      for(let i=0;i<this.tempCenterPlaceList.length;i++){
        if(this.tempCenterPlaceList[i].hidden != true && this.tempCenterPlaceList[i].Financialyear == this.currentyear){
          this.centerPlaceList.push(this.tempCenterPlaceList[i])
        }
      }
      this.centerData=this.centerPlaceList
      // this.dbservice.GetRows("AddEnquiry").subscribe(data => {
      //   this.enquiryDetails = data['Data']
      this.dbservice.GetRows('Source').subscribe(data => {
        this.sourceList = data['Data']
        // this.dbservice.getDistrict().subscribe(data => {
        //   this.districtList = data['Data']

          this.dbservice.GetRowforDB("course").subscribe(data => {
            this.courseArray = data['Data']
            this.courseList = this.courseArray
            this.name=this.dbservice.Details.enquiry.name
            this.address =this.dbservice.Details.enquiry.address
            this.mobile1= this.dbservice.Details.enquiry.mobile1
            this.mobile2=this.dbservice.Details.enquiry.mobile2
            this.followUpOption=this.dbservice.Details.enquiry.followUpOption
            this.district=this.dbservice.Details.enquiry.district
            this.centerPlace =this.dbservice.Details.enquiry.centerPlace
            this.id=this.dbservice.Details.enquiry.uniquId
            this.sourceId = this.dbservice.Details.enquiry.sourceId
              this.getCenterPlace()
             
            if(this.followUpOption == "Follow Up"){
              this.isHide=false
              this.isButton=false
            }
            else{
              this.isHide=true
              this.isButton=true
             }
            if(this.centerPlace != null){
            
              this.correspondingCourse() 
              for(let i=0;i<this.centerPlaceList.length;i++){
                if(this.centerPlaceList[i].CenterPlace == this.centerPlace){
                  this.centerName = this.centerPlaceList[i].CenterName
                
                }
              }
          
              this.isButton=true
            }
            else{
              this.isButton=false
            }
          
         },
            err => {
              this.dbservice.LogError(err)
            })
          },
            err => {
              this.dbservice.LogError(err)
            })
        },

          err => {
            this.dbservice.LogError(err)
          })
        // })
      // },
      //   err => {
      //     this.dbservice.LogError(err)
      //   })
    }
  formatDate(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if(month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }
  getCenterPlace() {
    this.centerPlaceList = []
    this.centerPlaceList= this.centerData.filter(s => s.DistrictId == this.district)
  }

  correspondingCourse() {
    this.isButton=true
    let whereBody = {
      "CenterPlace": this.centerPlace,
      "DistrictId": this.district
    }
    this.correspondingDetails=[]
    this.dbservice.GetRowforDB("centers", whereBody).subscribe(data => {
      this.centerDetails = data['Data']
      for(let i=0;i<this.centerDetails.length;i++){
        if(this.centerDetails[i].hidden != true && this.centerDetails[i].Financialyear == this.currentyear){
          this.correspondingDetails.push(this.centerDetails[i])
        }
      }
      this.courseArray = []
      for (let i = 0; i < this.courseList.length; i++) {
        for (let j = 0; j < this.correspondingDetails.length; j++) {
          let element = this.correspondingDetails[j]
          if (element.courseId != null && element.Financialyear == this.currentyear){
          for (let k = 0; k < element.courseId.length; k++) {
            if( this.courseList[i].UniqueId == element.courseId[k]) {
              let c = new Course()
              c.Name = this.courseList[i].Name
              c.UniqueId = element.courseId[k]
              this.courseArray.push(c)
            }
          }
          }

        }
      }
      let item = this.courseArray.filter(s => s.UniqueId == (this.dbservice.Details.enquiry.courseNeeded))
      this.courseNeeded=item[0].Name
      this.tempCourseNeeded=item[0].UniqueId
      // this.dbservice.GetRowforDB("centers").subscribe(data => {
      //   this.centers = data['Data']
      //   for (let i = 0; i < this.centers.length; i++) {
      //     if (this.centers[i].CenterPlace == this.centerPlace) {
      //       this.centerArray.push(this.centers[i])
      //     }
      //   }
      // },
      //   err => {
      //     this.dbservice.LogError(err)
      //   })
    },
      err => {
        this.dbservice.LogError(err)
      })
  }
  courseDetails(){
    let item = this.courseArray.filter(s => s.Name == this.courseNeeded)
    this.tempCourseNeeded=item[0].UniqueId
    console.log(this.tempCourseNeeded,'**')
  }
  showDetails(){

    if(this.followUpOption == "Follow Up"){
      this.isHide=false
      this.isButton=false
    }
    else{
      this.isHide=true
      this.isButton=true
    }
  }
  send(){
    let message="Hai"+ ' ' + this.name + "\your center details :-"+  this.correspondingDetails[0].CenterName + ' ' + this.correspondingDetails[0].Address+ ' ' +  this.correspondingDetails[0].CenterPlace + ' ' +  this.correspondingDetails[0].Mobile + ' ' + this.correspondingDetails[0].LandPhone
    this.dbservice.sendMessage(this.mobile1,message).subscribe(data => {
      console.log(data)
    },
      err => {
        this.dbservice.LogError(err)
      })

  }
  register() {
    this.courseNeeded=this.tempCourseNeeded
    let data={
    "centerPlace":this.centerPlace,
    "CenterName":this.centerName,
    "courseNeeded":this.courseNeeded,
    "sourceId":this.sourceId,
    "name":this.name,
    "followUpOption":this.followUpOption,
    "address":this.address,
    "district":this.district,
    "mobile1":this.mobile1,
    "mobile2":this.mobile2
    }
   let whereBody={
     "uniquId":this.id
   }
    this.dbservice.UpdateRows( 'AddEnquiry',data,whereBody).subscribe(data => {
     alert("updated")
     this.router.navigate(['/follow-up'])
      },
        err => {
          this.dbservice.LogError(err)
        })
  
  }
  checkValue(event: any){
    
    if(event == 'A'){
      this.forSms=true
    }
    else{
      this.forSms=false
    }
 }
  ngOnInit() {
  //     this.authenticationService.userName = this.cookieService.get('userName');
  //     this.authenticationService.userId = this.cookieService.get('userId');
  //     this.authenticationService.userType = this.cookieService.get('userType');
  //     this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
  //     this.authenticationService.password = this.cookieService.get('password');
  //     this.authenticationService.userAuthentication().subscribe(resdata => {
  //       if (resdata.Data.length == 1) {
  //         this.authenticationService.isAutherisedUser = true;
  //         if (this.authenticationService.userType != 'Super Admin') {
  //           console.log('this is not super admin');
  //           let whereBody = {
  //             "featureId": "7abd636a-b90b-476c-9631-b9d5514f1f52",
  //             "userTypeId": this.authenticationService.userTypeId,
  //           }
  //           this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
  //             console.log(resdata);
  //             if (resdata.Data.length > 0) {
  //               this.isprivilegeChecked = true;
  //               this.isAuthenticityChecked = true;
  //               this.dbservice.isLogin = true
  //             }
  //             else {
  //               this.router.navigate(['/error'])
  //             }



  //           },
  //             err => {
  //               this.dbservice.LogError(err);

  //             },
  //             () => {

  //             }
  //           )

  //         }
  //         else {
  //           this.isAuthenticityChecked = true;
  //           this.dbservice.isLogin = true
  //           this.authenticationService.isLoggedIn = true
  //         }
  //       }
  //       if (this.authenticationService.isAutherisedUser != true) {
  //         this.router.navigate(['login'])
  //       }
  //     },
  //       err => {
  //         this.dbservice.LogError(err)
  //       },
  //       () => {
  //         console.log('completed');
  //       }
  //     )
    

  }
}