import { Component, OnInit } from '@angular/core';
import { Feature } from '../models/feature';
import { DbService } from '../../dbService';
import { Common } from '../../models/common';
import { UserType } from '../models/usertype';
import { Previlege, PrevilegeList, PrevilegeData } from '../models/previlege';
import { AuthenticationService } from '../../authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-new-previlege',
  templateUrl: './add-new-previlege.component.html',
  styleUrls: ['./add-new-previlege.component.css']
})
export class AddNewPrevilegeComponent implements OnInit {
  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  featureList: Feature[] = [];
  userTypeList: UserType[] = [];
  previlegeList: PrevilegeList[] = [];
  previlegeData: PrevilegeData
  isEditMode: boolean;
  userTypeName;
  featureName;
  newPrevilege: Previlege = new Previlege;
  selectedUserType: string;
  selectedFeature: string

  constructor(private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieService, private router: Router) {

    this.dbservice.GetRows('Feature').subscribe(resdata => {
      this.featureList = new Array<Feature>();
      for (let i = 0; i <= resdata.Data.length; i++) {
        let c = new Feature();
        if (resdata.Data[i] != null) {

          c = resdata.Data[i];

          this.featureList.push(c);
        }
      }
      console.log(this.featureList, 'feature lIST');
      this.dbservice.GetRows('UserType').subscribe(resdata => {
        this.userTypeList = new Array<UserType>();
        for (let i = 0; i <= resdata.Data.length; i++) {
          let c = new UserType();
          if (resdata.Data[i] != null) {

            c = resdata.Data[i];

            this.userTypeList.push(c);
          }
        }
        console.log(this.userTypeList, 'user type lIST');

        this.dbservice.GetRows('privilege').subscribe(resdata => {
          this.previlegeData = resdata;
          for (let i = 0; i <= this.previlegeData.Data.length; i++) {
            let element = this.previlegeData.Data[i];
            if (element != null) {
              let quotationListItem = new PrevilegeList();
              let prvObj: Previlege = element;
              if (prvObj.previlegeId != undefined) {
                prvObj.previlegeId = prvObj.previlegeId.replace("/", "");
              };
              quotationListItem.previlege = prvObj;
              let feature = this.featureList.filter(f => f.featureId == prvObj.featureId);
              if (feature.length > 0) {
                quotationListItem.feature = feature[0];
              }
              let usertype = this.userTypeList.filter(f => f.userTypeId == prvObj.userTypeId);
              if (usertype.length > 0) {
                quotationListItem.usertype = usertype[0];
              }
              this.previlegeList.push(quotationListItem);
              console.log(this.previlegeList, 'previlege list')

            }
          }

        })
      },
        err => {
          this.dbservice.LogError(err)
        })
    },
      err => {
        this.dbservice.LogError(err)
      })
  }


  ngOnInit() {
    this.authenticationService.userName = this.cookieService.get('userName');
    this.authenticationService.userId = this.cookieService.get('userId');
    this.authenticationService.userType = this.cookieService.get('userType');
    this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    this.authenticationService.password = this.cookieService.get('password');
    this.authenticationService.userAuthentication().subscribe(resdata => {
      if (resdata.Data.length == 1) {
        this.authenticationService.isAutherisedUser = true;
        if (this.authenticationService.userType != 'Super Admin') {
          console.log('this is not super admin');
          let whereBody = {
            "featureId": "6cec3ad5-2228-44e5-a162-6abf485dcbe3",
            "userTypeId": this.authenticationService.userTypeId,
          }
          this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
            console.log(resdata);
            if (resdata.Data.length > 0) {
              this.isprivilegeChecked = true;
              this.isAuthenticityChecked = true;
              this.dbservice.isLogin = true
            }
            else {
              this.router.navigate(['/error'])
            }



          },
            err => {
              this.dbservice.LogError(err);

            },
            () => {

            }
          )

        }
        else {
          this.isAuthenticityChecked = true;
          this.dbservice.isLogin = true
          this.authenticationService.isLoggedIn=true
        }
      }
      if (this.authenticationService.isAutherisedUser != true) {
        this.router.navigate(['login'])
      }
    },
      err => {
        this.dbservice.LogError(err)
      },
      () => {
        console.log('completed');
      }
    )
  }
  register() {
    let uniqueId = Common.newGuid();
    this.newPrevilege.previlegeId = uniqueId;
    this.newPrevilege.userTypeId = this.selectedUserType;
    this.newPrevilege.featureId = this.selectedFeature

    this.dbservice.AddRow(this.newPrevilege, 'privilege').subscribe(
      resdata => {
        console.log(resdata);
      },
      err => { this.dbservice.LogError(err) },
      () => {
        alert('previlege added successfully');

        this.dbservice.GetRows('Feature').subscribe(resdata => {
          this.featureList = new Array<Feature>();
          for (let i = 0; i <= resdata.Data.length; i++) {
            let c = new Feature();
            if (resdata.Data[i] != null) {

              c = resdata.Data[i];

              this.featureList.push(c);
            }
          }
          this.dbservice.GetRows('UserType').subscribe(resdata => {
            this.userTypeList = new Array<UserType>();
            for (let i = 0; i <= resdata.Data.length; i++) {
              let c = new UserType();
              if (resdata.Data[i] != null) {

                c = resdata.Data[i];

                this.userTypeList.push(c);
              }
            }
            this.previlegeList = [];

            this.dbservice.GetRows('Privilege').subscribe(resdata => {
              this.previlegeData = resdata;
              for (let i = 0; i <= this.previlegeData.Data.length; i++) {
                let element = this.previlegeData.Data[i];
                if (element != null) {
                  let quotationListItem = new PrevilegeList();
                  let prvObj: Previlege = element;
                  if (prvObj.previlegeId != undefined) {
                    prvObj.previlegeId = prvObj.previlegeId.replace("/", "");
                  };
                  quotationListItem.previlege = prvObj;
                  let feature = this.featureList.filter(f => f.featureId == prvObj.featureId);
                  if (feature.length > 0) {
                    quotationListItem.feature = feature[0];
                  }
                  let usertype = this.userTypeList.filter(f => f.userTypeId == prvObj.userTypeId);
                  if (usertype.length > 0) {
                    quotationListItem.usertype = usertype[0];
                  }
                  this.previlegeList.push(quotationListItem);

                }
              }

            })
          },
            err => {
              this.dbservice.LogError(err)
            })
        },
          err => {
            this.dbservice.LogError(err)
          })
      }
    )
  }

  deletePrivilege(privilege: Previlege) {
    if (confirm('are you sure want to delete this privilege')) {
      let deleteData = {
        "previlegeId": privilege.previlegeId
      };
      this.dbservice.DeleteRow('Privilege', deleteData).subscribe(resdata => {
      },
        err => {
          this.dbservice.LogError(err)
        },
        () => { alert('privilege deleted successfully') })
    }
  }
}
