import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { CenterPlace } from '../models/Center';
import { AddEnquiryList, AddEnquiry } from '../models/AddEnquiry';
import { Course } from '../models/Course';
import { AuthenticationService } from '../authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { CookieserviceService } from '../cookieservice.service';

@Component({
  selector: 'app-not-interested',
  templateUrl: './not-interested.component.html',
  styleUrls: ['./not-interested.component.css']
})
export class NotInterestedComponent implements OnInit {
  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  districtList
  centerPlaceList: CenterPlace[] = []
  tempCenterPlaceList: CenterPlace[] = []
  centerData: CenterPlace[] = []
  district
  centerList
  prefollowList: AddEnquiryList[] = []
  notInterestedDetailsList: AddEnquiryList[] = []
  tempNotInterestedDetailsList: AddEnquiryList[] = []
  fromDate;
  toDate
  centerPlace
  idFrom
  to
  Name
  enquiryDetails: AddEnquiry[] = []
  course: Course[] = []
  currentyear
  count: number
  previousyear
  nextyear
  financialYear
  constructor(private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieserviceService, private router: Router) {

    let year = new Date().getFullYear()
    let month = new Date().getMonth()
    if (month > 2) {
      this.currentyear = year + "-" + (year + 1)
      this.previousyear = (year-1) + "-" + year
      this.nextyear = (year+1) + "-" + (year+2)
    }
    if (month <= 2) {
      this.currentyear = (year - 1) + "-" + (year)
      this.previousyear = (year-1) + "-" + year
      this.nextyear = (year+1) + "-" + (year+2)

    }

    // this.dbservice.getDistrict().subscribe(data => {
    //   this.districtList = data['Data']


      this.dbservice.GetRowforDB("course").subscribe(data => {
        this.course = data['Data']
        this.dbservice.GetRowforDB("centers").subscribe(data => {
          this.tempCenterPlaceList = data['Data']
          for (let i = 0; i < this.tempCenterPlaceList.length; i++) {
            if (this.tempCenterPlaceList[i].hidden != true && this.tempCenterPlaceList[i].Financialyear == this.currentyear) {
              this.centerPlaceList.push(this.tempCenterPlaceList[i])
            }
          }
          this.centerData = this.centerPlaceList
          this.dbservice.GetRows("AddEnquiry").subscribe(data => {
            this.enquiryDetails = data['Data']
            for (let i = 0; i < this.enquiryDetails.length; i++) {
              let element = this.enquiryDetails[i]
              if (element != null) {
                let enquiryDetails = new AddEnquiryList()
                let enquiryDetailsObj: AddEnquiry = element
                enquiryDetails.enquiry = enquiryDetailsObj
                let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
                enquiryDetails.course.push(itemData[0])
                this.tempNotInterestedDetailsList.push(enquiryDetails)
              }
            }
            if (this.cookieService.cookiecenter != 'undefined') {
              this.notInterestedDetailsList = this.tempNotInterestedDetailsList.filter(s => s.enquiry.followUpOption == "Not Interested" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2)
                && s.enquiry.CenterName == this.cookieService.cookiecenter)
            }
            else {
              this.notInterestedDetailsList = this.tempNotInterestedDetailsList.filter(s => s.enquiry.followUpOption == "Not Interested" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2))
            }
            this.tempNotInterestedDetailsList = this.notInterestedDetailsList
            if (this.notInterestedDetailsList.length != 0) {
              // for (let i = 0; i < this.notInterestedDetailsList.length; i++) {
              //   this.count = i + 1
              // }
              this.count = this.notInterestedDetailsList.length
            }
            else {
              this.count = 0
            }
            
            this.isAuthenticityChecked=true
          },
            err => {
              this.dbservice.LogError(err)
            })


        },
          err => {
            this.dbservice.LogError(err)
          })

      },
        err => {
          this.dbservice.LogError(err)
        })
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   })
  }

  getYear() {
    this.notInterestedDetailsList=[]
    for (let i = 0; i < this.tempNotInterestedDetailsList.length; i++) {
      let year:any = this.formatDate(this.tempNotInterestedDetailsList[i].enquiry.todayDate).slice(6)
      let m = this.formatDate(this.tempNotInterestedDetailsList[i].enquiry.todayDate)
      let month=m.slice(0,2)
      if (month > '02') {
        let currentyear = year + "-" + (parseInt(year) + 1)
        this.tempNotInterestedDetailsList[i].enquiry.financialYear = currentyear
      }
      else if (month <= '02') {
        let currentyear = (parseInt(year) - 1) + "-" + (year)
        this.tempNotInterestedDetailsList[i].enquiry.financialYear = currentyear
      }
    }
    for(let i=0;i<this.tempNotInterestedDetailsList.length;i++){
      if(this.tempNotInterestedDetailsList[i].enquiry.financialYear == this.financialYear){
        this.notInterestedDetailsList.push(this.tempNotInterestedDetailsList[i])
      }
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }
  dateFilter() {
    this.notInterestedDetailsList = this.tempNotInterestedDetailsList.filter(d => {
      var time = this.formatDate(d.enquiry.todayDate.toString())
      return (this.formatDate(this.fromDate) <= time && time <= this.formatDate(this.toDate))
    });
  }

  CenterPlaceDeatils() {

    this.notInterestedDetailsList = this.tempNotInterestedDetailsList.filter(s => s.enquiry.centerPlace == this.centerPlace)
  }
  getCenterPlace() {
    this.notInterestedDetailsList = []
    this.notInterestedDetailsList = this.tempNotInterestedDetailsList.filter(s => s.enquiry.district == this.district)
  }

  GetById() {

    this.notInterestedDetailsList = this.tempNotInterestedDetailsList.filter(d => {
      var id = d.enquiry.studentId
      return this.idFrom <= id && id <= this.to
    });
  }
  ngOnInit() {
    // this.authenticationService.userName = this.cookieService.get('userName');
    // this.authenticationService.userId = this.cookieService.get('userId');
    // this.authenticationService.userType = this.cookieService.get('userType');
    // this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    // this.authenticationService.password = this.cookieService.get('password');
    // this.authenticationService.centerName = this.cookieService.get('centerName');
    // this.authenticationService.userAuthentication().subscribe(resdata => {
    //   if (resdata.Data.length == 1) {
    //     this.authenticationService.isAutherisedUser = true;
    //     if (this.authenticationService.userType != 'Super Admin') {
    //       console.log('this is not super admin');
    //       let whereBody = {
    //         "featureId": "4c70df00-f43b-45d7-a2be-2bbbc0e3e86e",
    //         "userTypeId": this.authenticationService.userTypeId,
    //       }
    //       this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
    //         console.log(resdata);
    //         if (resdata.Data.length > 0) {
    //           this.isprivilegeChecked = true;
    //           this.isAuthenticityChecked = true;
    //           this.dbservice.isLogin = true
    //         }
    //         else {
    //           this.router.navigate(['/error'])
    //         }



    //       },
    //         err => {
    //           this.dbservice.LogError(err);

    //         },
    //         () => {

    //         }
    //       )

    //     }
    //     else {
    //       this.isAuthenticityChecked = true;
    //       this.dbservice.isLogin = true
    //       this.authenticationService.isLoggedIn = true
    //     }
    //   }
    //   if (this.authenticationService.isAutherisedUser != true) {
    //     this.router.navigate(['login'])
    //   }
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   },
    //   () => {
    //     console.log('completed');
    //   }
    // )
  }

}
