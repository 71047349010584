import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { SourceReport } from '../models/sourcereport';
import { Source } from '../models/Source';
import { AddEnquiry } from '../models/AddEnquiry';

@Component({
  selector: 'app-sourcereport',
  templateUrl: './sourcereport.component.html',
  styleUrls: ['./sourcereport.component.css']
})
export class SourcereportComponent implements OnInit {
  sourcelist: Source[] = []
  enquirylist: AddEnquiry[] = []
  sourcereportlist: SourceReport[] = []
  isAuthenticityChecked: boolean
  constructor(
    private dbService: DbService
  ) {
    this.dbService.GetRows('Source').subscribe(data => {
      this.sourcelist = data['Data']
      this.dbService.GetRows('AddEnquiry').subscribe(data => {
        this.enquirylist = data['Data']
        console.log(this.enquirylist, 'list')
        for (let i = 0; i < this.sourcelist.length; i++) {
          let sourcereportdata = new SourceReport()
          sourcereportdata.source = this.sourcelist[i].sourcename
          let noofenquiries = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId)
          if(i == 4){
            let data = this.enquirylist.filter(s => s.sourceId == "s4fa4c0a4-43cb-4bb5-ac6b-ae3d85e2bfd7")
            console.log(data,'data')
            let fol1 = data.filter(s => s.followUpOption == "Will Come" && (s.followStatus == 1 || s.followStatus == 2))
            console.log(fol1,'fol1')
            let fol2 = data.filter(s => s.followUpOption == "Follow Up" && s.followStatus == 1)
            console.log(fol2,'fol2')
            let fol3 = data.filter(s => s.followUpOption == "Follow Up Needed" && s.followStatus == 2)
            console.log(fol3,'fol3')
          }
          if (i == 4) {
            console.log(this.sourcelist[i].uniqueId, 'id')
            console.log(noofenquiries, 'enquiry')
            let temp = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && x.followUpOption != "Not Interested" && x.followUpOption != "Fake Call" && x.followUpOption != "Center Not Available" && x.followUpOption != "Will Come" && x.followUpOption != "Follow Up Needed" && x.followUpOption != "Pre Follow" && x.followUpOption != "Joined" && x.followUpOption != "DD Received" && x.followUpOption != "Enquiry DropOut" && x.followUpOption != "Follow Up")
            console.log(temp, 'null data')
            let tempstatus = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && x.followStatus != 1 && x.followStatus != 2)
            console.log(tempstatus,'null status')
            let notinterested = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && (x.followStatus == 1 || x.followStatus == 2) && x.followUpOption == "Not Interested")
            console.log(notinterested, 'notinterested')
            let fakecall = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && (x.followStatus == 1 || x.followStatus == 2) && x.followUpOption == "Fake Call")
            console.log(fakecall, 'fakecall')
            let centerna = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && (x.followStatus == 1 || x.followStatus == 2) && x.followUpOption == "Center Not Available")
            console.log(centerna, 'centerna')
            let followup = this.enquirylist.filter(s => (s.followUpOption == "Will Come" && (s.followStatus == 1 || s.followStatus == 2))
              || (s.followUpOption == "Follow Up" && s.followStatus == 1) || (s.followUpOption == "Follow Up Needed" && s.followStatus == 2))
            let sourcefollowUp = followup.filter(x => x.sourceId == this.sourcelist[i].uniqueId)
            console.log(sourcefollowUp, 'followup')
            let prefollowup = this.enquirylist.filter(s => (s.followUpOption == "Follow Up Needed" || s.followUpOption == "Pre Follow") && (s.followStatus == 1) && (s.sourceId == this.sourcelist[i].uniqueId))
            console.log(prefollowup, 'prefoolow')
            let joined = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && x.followUpOption == "Joined")
            console.log(joined, 'joined')
            let dd = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && x.followUpOption == "DD Received" && x.followStatus == 2)
            console.log(dd, 'dd')
            let enquirydropout = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && x.followUpOption == "Enquiry DropOut" && (x.followStatus == 1 || x.followStatus == 2))
            console.log(enquirydropout, 'enquirydropout')
            let admsnconfrmtn = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && x.followUpOption == "Joined" && x.joiningstatus == 2)
            console.log(admsnconfrmtn, 'admsnconfrmtn')
            // let tempfol = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && x.followStatus != 1 && x.followStatus != 2)
            // console.log(tempfol,'status null')
          }
          sourcereportdata.noOfEnquiry = noofenquiries.length
          let notinterested = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && (x.followStatus == 1 || x.followStatus == 2) && x.followUpOption == "Not Interested")
          sourcereportdata.notInterested = notinterested.length
          let fakecall = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && (x.followStatus == 1 || x.followStatus == 2) && x.followUpOption == "Fake Call")
          sourcereportdata.fakeCall = fakecall.length
          let centerna = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && (x.followStatus == 1 || x.followStatus == 2) && x.followUpOption == "Center Not Available")
          sourcereportdata.centerNA = centerna.length
          let followup = this.enquirylist.filter(s => (s.followUpOption == "Will Come" && (s.followStatus == 1 || s.followStatus == 2))
            || (s.followUpOption == "Follow Up" && s.followStatus == 1) || (s.followUpOption == "Follow Up Needed" && s.followStatus == 2)
            || (s.followUpOption == "Get Admission"))
          let sourcefollowUp = followup.filter(x => x.sourceId == this.sourcelist[i].uniqueId)
          sourcereportdata.followUp = sourcefollowUp.length
          let prefollowup = this.enquirylist.filter(s => (s.followUpOption == "Follow Up Needed" || s.followUpOption == "Pre Follow") && (s.followStatus == 1) && (s.sourceId == this.sourcelist[i].uniqueId))
          sourcereportdata.prefollowup = prefollowup.length
          let joined = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && x.followUpOption == "Joined")
          sourcereportdata.joined = joined.length
          let dd = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && x.followUpOption == "DD Received" && x.followStatus == 2)
          sourcereportdata.dd = dd.length
          let enquirydropout = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && x.followUpOption == "Enquiry DropOut" && (x.followStatus == 1 || x.followStatus == 2))
          sourcereportdata.enquirydropout = enquirydropout.length
          let admsnconfrmtn = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && x.followUpOption == "Joined" && x.joiningstatus == 2)
          sourcereportdata.admissionconfirmation = admsnconfrmtn.length
          let percent = (sourcereportdata.admissionconfirmation / sourcereportdata.noOfEnquiry) * 100
          sourcereportdata.admissionconfirmationpercent = percent + ' %'
          let nextyear = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && x.followUpOption == "Next Year")
          sourcereportdata.nextyear = nextyear.length
          let getadmission = this.enquirylist.filter(x => x.sourceId == this.sourcelist[i].uniqueId && x.followUpOption == "Get Admission")
          sourcereportdata.getadmission = getadmission.length
          this.sourcereportlist.push(sourcereportdata)
        }
        this.isAuthenticityChecked = true
      })
    })
  }

  ngOnInit() {
  }

}
