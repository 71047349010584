import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserRoutingModule } from './user-routing.module';
import { AddFeatureComponent } from './add-feature/add-feature.component';
import { AddNewUsertypeComponent } from './add-new-usertype/add-new-usertype.component';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { AddNewPrevilegeComponent } from './add-new-previlege/add-new-previlege.component';
import { UsersListComponent } from './users-list/users-list.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { ErrorComponent } from './error/error.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';

@NgModule({
  imports: [
    CommonModule,
    UserRoutingModule,
    FormsModule,ReactiveFormsModule,
  ],
  declarations: [AddFeatureComponent,ErrorComponent,UserDetailsComponent,UsersListComponent, AddNewPrevilegeComponent, AddNewUsertypeComponent, AddNewUserComponent, UserLoginComponent, EditProfileComponent]
})
export class UserModule { }
