import { Component, OnInit } from '@angular/core';
import { UserList, UserData, User } from '../models/user';
import { UserType } from '../models/usertype';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../authentication.service';
import { DbService } from '../../dbService';
import { CookieService } from 'ngx-cookie-service';
import { Common } from '../../models/common';
import { Previlege } from '../models/previlege';
import { Feature } from '../models/feature';
import { Login } from '../models/login';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {

  todayDate=new Date()
  model: any = {};
  loading = false;
  newUser: UserList = new UserList();
  newUserfordb: User = new User();
  newUsertypefordb: UserType = new UserType();
  isAutherisedUser: boolean = false;
  isLogin: boolean = false
  returnUrl: string;
  userList: UserList[] = [];
  userTypeList: UserType[] = [];
  usersData: UserData;
  previlegeList: Previlege[] = []
  featureList: Feature[] = []
  userListdata
  featureItemList: Feature[] = [];
  featureCookieList
  newLogin:Login = new Login();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dbservice: DbService,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService

  ) {

    this.previlegeList = [];
    this.dbservice.GetRows('Privilege').subscribe(resdata => {
      this.previlegeList = resdata['Data']
    })
    this.featureList = []
    this.dbservice.GetRows('Feature').subscribe(resdata => {
      this.featureList = resdata['Data']
    })

    this.dbservice.GetRows('User').subscribe(data => {
      let cdata = data['Data']
      console.log(cdata,'center data') 
    })
  }


  ngOnInit() {

  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }

  login() {

 

    this.dbservice.GetRows('UserType').subscribe(resdata => {
      this.userTypeList = new Array<UserType>();
      for (let i = 0; i <= resdata.Data.length; i++) {
        let c = new UserType();
        if (resdata.Data[i] != null) {
          c = resdata.Data[i];
          this.userTypeList.push(c);
        }
      }
      this.dbservice.GetRows('User').subscribe(resdata => {
        this.usersData = resdata;
        this.userList = [];
        for (let i = 0; i <= this.usersData.Data.length; i++) {
          let element = this.usersData.Data[i]
          if (element != null) {
            let quotationListItem = new UserList();
            let qobj: User = element;
            if (qobj.userTypeId != undefined) {
              qobj.userTypeId = qobj.userTypeId.replace("/", "");
            }
            quotationListItem.user = qobj;
            let usertype = this.userTypeList.filter(s => s.userTypeId == (qobj.userTypeId));
            if (usertype.length > 0) {
              quotationListItem.usertype = usertype[0];
            }
            this.userList.push(quotationListItem);
            for (let i = 0; i <= this.userList.length; i++) {
              let userObj = this.userList[i];
              if (userObj != null) {
                if (userObj.user.username == this.model.username &&
                  userObj.user.password == this.model.password) {
                  this.newUser = userObj;
                  this.dbservice.isAutherisedUser = true;
                  this.dbservice.isLogin = true
                  break;
                }
                else {
                }
              }
            }
          }
        }
        if (!this.dbservice.isAutherisedUser) {
          alert('incorrect passwod or username')
        }
        else {
          let loginWhereBody =
          {
            "username": this.model.username,
            "password": this.model.password
          }
          this.dbservice.GetRows('User', loginWhereBody)
            .subscribe(resdata => {
              this.userListdata = resdata['Data'];
              console.log(this.userListdata, 'userdata')
            },
              err => {
                this.dbservice.LogError(err)
                  ;
              },
              () => {
                if (this.userListdata.length != 0) {
                  // console.log('PLIST', this.userList[0].userTypeId)
                  let usertype = this.userTypeList.filter(x => x.userTypeId == this.userListdata[0].userTypeId);

                  let previlege = this.previlegeList.filter(x => x.userTypeId == this.userListdata[0].userTypeId);

                  if (previlege.length > 0) {
                    let feature;
                    this.featureItemList = [];

                    for (let i = 0; i < previlege.length; i++) {
                      var privilegeList = previlege[i];
                      // console.log('FORPRI', privilegeList)
                      if (privilegeList != null) {
                        for (let j = 0; j < this.featureList.length; j++) {
                          var list = this.featureList[j];
                          // console.log('FORlist', list)

                          if (list != null) {
                            if (privilegeList.featureId == list.featureId) {
                              console.log('Success')
                              this.featureItemList.push(list)
                            }
                          }
                        }
                        // feature = this.featureList.filter(x => x.featureId == previlege[i].featureId);
                      }
                    }
                    // console.log('FeatureItemList', this.featureItemList)
                  }
                  console.log(this.featureItemList, 'very')
                  this.featureCookieList = []
                  if (this.featureItemList.length > 0) {
                    for (let i = 0; i < this.featureItemList.length; i++) {

                      this.featureCookieList.push(this.featureItemList[i].featureId)
                    }
                    this.cookieService.set('feature', this.featureCookieList);
                    console.log('feature', this.featureCookieList);

                  }
                  this.dbservice.isAutherisedUser = true
                  // alert('logged in succesffuly');
                  this.authenticationService.isLoggedIn = true;
                  // this.authenticationService.isProjectOpen = true;
                  this.authenticationService.userName = this.model.username;
                  this.authenticationService.userType = this.model.usertype
                  console.log(this.newUser,'urgent data')

                  this.cookieService.set('userName', this.newUser.user.username);
                  this.cookieService.set('userId', this.newUser.user.userId);
                  this.cookieService.set('userType', this.newUser.usertype.userTypeName)
                  this.cookieService.set('centerName', this.newUser.user.centerName)
                  this.cookieService.set('userTypeId', this.newUser.usertype.userTypeId);
                  this.cookieService.set('password', this.newUser.user.password);
                 console.log(this.newUser.user.centerName,'center')
                 this.dbservice.isLogin = true
                 
                 this.newLogin.userName=this.newUser.user.username
                 this.newLogin.group=this.newUser.usertype.userTypeName
                 this.newLogin.uniqueId=this.newUser.user.userId
                 this.newLogin.loginTime=this.formatDate(this.todayDate)
                 this.newLogin.centerName=this.newUser.user.centerName
                //  this.dbservice.AddRow(this.newLogin, 'LoginDetails').subscribe(
                //   resdata => {
                //     console.log(resdata);
                
                  this.router.navigate(['/dash-board'])
                // },
                // err => { this.dbservice.LogError(err)
                //  })
              
                }
              });
            }
          })
        })
      }
    }


