import { Component, OnInit } from '@angular/core';
import { CenterPlace } from '../models/Center';
import { AddEnquiryList, AddEnquiry } from '../models/AddEnquiry';
import { Course } from '../models/Course';
import { DbService } from '../dbService';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../authentication.service';
import { CookieserviceService } from '../cookieservice.service';
import { Source } from '../models/Source';

@Component({
  selector: 'app-admission-details',
  templateUrl: './admission-details.component.html',
  styleUrls: ['./admission-details.component.css']
})
export class AdmissionDetailsComponent implements OnInit {

  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  districtList
  centerPlaceList: CenterPlace[] = []
  tempCenterPlaceList: CenterPlace[] = []
  district
  centerList
  joinedList: AddEnquiryList[] = []
  enquiryDetailsList: AddEnquiryList[] = []
  fromDate;
  toDate
  centerPlace
  idFrom
  to
  Name
  centerData: CenterPlace[] = []
  enquiryDetails: AddEnquiry[] = []
  course: Course[] = []
  currentyear
  count:number
  previousyear
  nextyear
  financialYear
  sourcelist: Source[] = []

  constructor(private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieserviceService, private router: Router) {

      let year = new Date().getFullYear()
      let month = new Date().getMonth()
      if(month > 2){
        this.currentyear= year+"-"+(year+1)
        this.previousyear = (year-1) + "-" + year
        this.nextyear = (year+1) + "-" + (year+2)
      }
      if(month <= 2){
        this.currentyear = (year-1) + "-" + (year)
        this.previousyear = (year-1) + "-" + year
        this.nextyear = (year+1) + "-" + (year+2)

      }
    this.dbservice.GetRowforDB("course").subscribe(data => {
      this.course = data['Data']
      this.dbservice.GetRows("AddEnquiry").subscribe(data => {
        this.enquiryDetails = data['Data']
        this.dbservice.GetRowforDB("centers").subscribe(data => {
          this.tempCenterPlaceList = data['Data']
          this.centerPlaceList = this.tempCenterPlaceList.filter(x => x.hidden != true && x.Financialyear == this.currentyear)
          this.centerData = this.centerPlaceList
          this.dbservice.GetRows('Source').subscribe(data => {
            this.sourcelist = data['Data']
            for (let i = 0; i < this.enquiryDetails.length; i++) {
              let element = this.enquiryDetails[i]
              if (element != null) {
                let enquiryDetails = new AddEnquiryList()
                let enquiryDetailsObj: AddEnquiry = element
                enquiryDetails.enquiry = enquiryDetailsObj
                let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
                enquiryDetails.course.push(itemData[0])
                let sourcedata = this.sourcelist.filter(s => s.uniqueId == (element.sourceId))
                if (sourcedata[0] != undefined) {
                  enquiryDetails.source.push(sourcedata[0])
                }
                this.enquiryDetailsList.push(enquiryDetails)
              }
            }
            if (this.cookieService.cookiecenter != 'undefined') {
            this.joinedList = this.enquiryDetailsList.filter(s => s.enquiry.followUpOption == "Joined" && s.enquiry.CenterName == this.cookieService.cookiecenter)
            }
            else {
              this.joinedList = this.enquiryDetailsList.filter(s => s.enquiry.followUpOption == "Joined")

            }
            this.enquiryDetailsList = this.joinedList
            
            if(this.joinedList.length !=0) {
            this.count = this.joinedList.length
          }
          else{
            this.count=0
          }
          this.isAuthenticityChecked=true
          console.log(this.joinedList,'list')
        },
            err => {
              this.dbservice.LogError(err)
            })
        },
          err => {
            this.dbservice.LogError(err)
          })
      },
        err => {
          this.dbservice.LogError(err)
        })
      })
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   })
  }

  getYear() {
    this.joinedList=[]
    for (let i = 0; i < this.enquiryDetailsList.length; i++) {
      let year:any = this.formatDate(this.enquiryDetailsList[i].enquiry.todayDate).slice(6)
      let m = this.formatDate(this.enquiryDetailsList[i].enquiry.todayDate)
      let month=m.slice(0,2)
      if (month > '02') {
        let currentyear = year + "-" + (parseInt(year) + 1)
        this.enquiryDetailsList[i].enquiry.financialYear = currentyear
      }
      else if (month <= '02') {
        let currentyear = (parseInt(year) - 1) + "-" + (year)
        this.enquiryDetailsList[i].enquiry.financialYear = currentyear
      }
    }
    for(let i=0;i<this.enquiryDetailsList.length;i++){
      if(this.enquiryDetailsList[i].enquiry.financialYear == this.financialYear){
        this.joinedList.push(this.enquiryDetailsList[i])
      }
    }
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }
  dateFilter() {
    this.joinedList = []
    let tempFromDate = this.formatDate(this.fromDate)
    let tempToDate = this.formatDate(this.toDate)
    let fromYear = tempFromDate.slice(6)
    let toYear = tempToDate.slice(6)
    let fromMonth = tempFromDate.slice(3, 5)
    let toMonth = tempToDate.slice(3, 5)
    let fromDay = tempFromDate.slice(0, 2)
    let toDay = tempToDate.slice(0, 2)
    let yearList: AddEnquiryList[] = []
    let monthList: AddEnquiryList[] = []

    for (let i = 0; i < this.enquiryDetailsList.length; i++) {
      let year = this.enquiryDetailsList[i].enquiry.todayDate.slice(6)
      if (year >= fromYear && year <= toYear) {
        yearList.push(this.enquiryDetailsList[i])
      }
    }
    for (let i = 0; i < yearList.length; i++) {
      let month = yearList[i].enquiry.todayDate.slice(3, 5)
      if (month >= fromMonth && month <= toMonth) {
        monthList.push(yearList[i])
      }
    }
    for (let i = 0; i < monthList.length; i++) {
      let day = monthList[i].enquiry.todayDate.slice(0, 2)
      let monthDay = monthList[i].enquiry.todayDate.slice(3, 5)
      if (monthDay == fromMonth) {
        if (day >= fromDay && day <= toDay) {
          this.joinedList.push(monthList[i])
        }
      }
      else if (monthDay == toMonth) {
        if (day >= fromDay && day <= toDay) {

          this.joinedList.push(monthList[i])
        }
      }
      else {
        this.joinedList.push(monthList[i])
      }
    }
  }

  CenterPlaceDeatils() {

    this.joinedList = this.enquiryDetailsList.filter(s => s.enquiry.centerPlace == this.centerPlace)
  }
  getCenterPlace() {
    this.joinedList=[]
    this.joinedList= this.enquiryDetailsList.filter(s => s.enquiry.district == this.district)
    this.centerPlaceList = this.centerData.filter(s => s.DistrictId == this.district)
  }

  GetById() {

    this.joinedList = this.enquiryDetailsList.filter(d => {
      var id = d.enquiry.studentId
      return this.idFrom <= id && id <= this.to
    });
  }

  baktoFollow(id) {
    let whereBody = {
      "uniquId": id
    }
    let data = {
      "followUpOption": "Follow Up Needed",
      "followStatus": 2
    }
    this.dbservice.UpdateRows("AddEnquiry", data, whereBody).subscribe(data => {
      this.dbservice.GetRows("AddEnquiry").subscribe(data => {
        this.enquiryDetails = data['Data']
        this.enquiryDetailsList = []
        for (let i = 0; i < this.enquiryDetails.length; i++) {
          let element = this.enquiryDetails[i]
          if (element != null) {
            let enquiryDetails = new AddEnquiryList()
            let enquiryDetailsObj: AddEnquiry = element
            enquiryDetails.enquiry = enquiryDetailsObj
            let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
            enquiryDetails.course.push(itemData[0])
            this.enquiryDetailsList.push(enquiryDetails)
          }
        }

        if (this.authenticationService.centerName != 'undefined') {
          this.joinedList = this.enquiryDetailsList.filter(s => s.enquiry.followUpOption == "Joined" && s.enquiry.CenterName == this.authenticationService.centerName)
        }
        else {
          this.joinedList = this.enquiryDetailsList.filter(s => s.enquiry.followUpOption == "Joined")

        }
        this.enquiryDetailsList = this.joinedList
      },
        err => {
          this.dbservice.LogError(err)
        })
    },
      err => {
        this.dbservice.LogError(err)
      })
  }

  ngOnInit() {
    // this.authenticationService.userName = this.cookieService.get('userName');
    // this.authenticationService.userId = this.cookieService.get('userId');
    // this.authenticationService.userType = this.cookieService.get('userType');
    // this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    // this.authenticationService.password = this.cookieService.get('password');
    // this.authenticationService.centerName = this.cookieService.get('centerName');
    // this.authenticationService.userAuthentication().subscribe(resdata => {
    //   if (resdata.Data.length == 1) {
    //     this.authenticationService.isAutherisedUser = true;
    //     if (this.authenticationService.userType != 'Super Admin') {
    //       console.log('this is not super admin');
    //       let whereBody = {
    //         "featureId": "05fd2bcc-9bc9-4880-8a24-be6e435ffbcf",
    //         "userTypeId": this.authenticationService.userTypeId,
    //       }
    //       this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
    //         console.log(resdata);
    //         if (resdata.Data.length > 0) {
    //           this.isprivilegeChecked = true;
    //           this.isAuthenticityChecked = true;
    //           this.dbservice.isLogin = true
    //         }
    //         else {
    //           this.router.navigate(['/error'])
    //         }



    //       },
    //         err => {
    //           this.dbservice.LogError(err);

    //         },
    //         () => {

    //         }
    //       )

    //     }
    //     else {
    //       this.isAuthenticityChecked = true;
    //       this.dbservice.isLogin = true
    //       this.authenticationService.isLoggedIn = true
    //     }
    //   }
    //   if (this.authenticationService.isAutherisedUser != true) {
    //     this.router.navigate(['login'])
    //   }
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   },
    //   () => {
    //     console.log('completed');
    //   }
    // )
  }

}
