import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class CookieserviceService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  public cookiename: string;
  public cookievalue: string;
  public cookiecenter: string;
  public cookieuser: string;
  public password:string;
  public feature:string;
  public cookieusertype:string;
  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }
  getCookie() {
    return this.cookieService.getAll();


  }
  // setCookie(cookiename, cookievalue, setcenter, expirydate, cookieuser,feature) {
  //   return this.cookieService.set(cookiename, cookievalue, setcenter, expirydate, cookieuser,feature)

  // }
  setCookie(name, value, ) {
    return this.cookieService.set(name, value)

  }
}
