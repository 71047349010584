import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { Common } from '../models/common';
import { Source } from '../models/Source';
import { Router } from '@angular/router';

@Component({
  selector: 'app-source',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.css']
})
export class SourceComponent implements OnInit {
  source;
  sources:Source = new Source()
  sourcelist:Source [] = []
  constructor(
    private dbService:DbService,
    private router:Router
  ) { 
     this.dbService.GetRows('Source').subscribe(data => {
      this.sourcelist = data['Data']
     })
  }

  edit(value){
    this.router.navigate(['editsource/'+value.uniqueId])
  }
  delete(value){
    let wherebody = {
      "uniqueId":value.uniqueId
    }
    this.dbService.DeleteRow('Source',wherebody).subscribe(data => {
      this.sourcelist = []
      this.dbService.GetRows('Source').subscribe(data => {
        this.sourcelist = data['Data']
      })
    })
  }
  register(){
    let uniqueId = 's' + Common.newGuid()
    this.sources.uniqueId = uniqueId
    this.sources.sourcename = this.source
    this.dbService.AddRow(this.sources,'Source').subscribe(data => {
      alert('Source added successfully')
      this.dbService.GetRows('Source').subscribe(data => {
        this.sourcelist = data['Data']
        console.log(this.sourcelist,'list')
      })
    })
  }
  ngOnInit() {
  }

}
