import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { AddEnquiry } from '../models/AddEnquiry';
import { Common } from '../models/common';
import { CenterPlace } from '../models/Center';
import { Course } from '../models/Course';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { stdId } from '../models/stdId';
import { CookieserviceService } from '../cookieservice.service';
import { Source } from '../models/Source';
import { HttpClient } from '@angular/common/http';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
@Component({
  selector: 'app-add-enquiry',
  templateUrl: './add-enquiry.component.html',
  styleUrls: ['./add-enquiry.component.css']
})
// export class AppComponent {
//   isDisabled = true;
//   triggerSomeEvent() {
//       this.isDisabled = !this.isDisabled;
//       return;
//   }
// }
export class AddEnquiryComponent implements OnInit {
  isEnable: boolean
  name
  centerName
  address
  mobile1
  mobile2
  mobile3
  tempId
  district
  centerPlace
  courseNeeded
  followUpOption
  uniquId;
  stdIdDetails: stdId[] = []
  centerList;
  studentId;
  forSms: boolean
  todayDate = new Date()
  centerDetails: CenterPlace[] = []
  tempCenterPlaceList: CenterPlace[] = []
  centerPlaceList: CenterPlace[] = []
  centerData: CenterPlace[] = []
  centers = []
  tempCenter
  centerArray: Course[] = []
  courseList: Course[] = []
  courseArray: Course[] = []
  enquiryDetails: AddEnquiry[] = []
  districtList = []
  currentyear
  previousyear
  nextyear
  isHide: boolean
  isButton: boolean
  correspondingDetails: CenterPlace[] = []
  newAddEnquiry: AddEnquiry = new AddEnquiry()
  isAuthenticityChecked: boolean
  isprivilegeChecked: boolean;
  isChecked
  logincenter
  sourceList: Source[] = []
  sourceId
  msg
  phone
  message
  body 
  filename
  caption
  Hide: boolean
  constructor(private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieService, public cookie: CookieserviceService, private router: Router, 
    private http: HttpClient) {
    let year = new Date().getFullYear()
    let month = new Date().getMonth()
    if (month > 2) {
      this.currentyear = year + "-" + (year + 1)
      this.previousyear = (year - 1) + "-" + year
      this.nextyear = (year + 1) + "-" + (year + 2)
      console.log(this.currentyear, '-----')
    }
    if (month <= 2) {
      this.currentyear = (year - 1) + "-" + (year)
      this.previousyear = (year - 2) + "-" + (year - 1)
      this.nextyear = year + "-" + (year + 1)
    }

    this.dbservice.GetRowforDB("centers").subscribe(data => {
      this.tempCenterPlaceList = data['Data']
      for (let i = 0; i < this.tempCenterPlaceList.length; i++) {
        if (this.tempCenterPlaceList[i].hidden != true && this.tempCenterPlaceList[i].Financialyear == this.currentyear) {
          this.centerPlaceList.push(this.tempCenterPlaceList[i])
        }
      }
      this.centerData = this.centerPlaceList
      this.dbservice.GetRowforDB("course").subscribe(data => {
        this.courseArray = data['Data']
        this.courseList = this.courseArray
        this.dbservice.GetRows('Source').subscribe(data => {
          this.sourceList = data['Data']
          if (this.cookie.cookiecenter != 'undefined') {
            this.sourceId = this.sourceList[5].uniqueId
            let logincenter = this.centerData.filter(x => x.CenterName == this.cookie.cookiecenter)
            this.logincenter = logincenter[0]
            this.followUpOption = "Follow Up"
            this.district = this.logincenter.DistrictId
            this.centerPlace = this.logincenter.CenterPlace
            this.correspondingCourse()
          }
        })
      })
    })
  }
  triggerSomeEvent(value){
    if(value.target.checked == true){
      this.Hide= true
      
    }
    else{
      this.Hide= false
    }
    
    
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }
  getCenterPlace() {
    this.centerPlaceList = []
    this.centerPlaceList = this.centerData.filter(s => s.DistrictId == this.district)
  }

  correspondingCourse() {
    this.isButton = true
    let whereBody = {
      "CenterPlace": this.centerPlace,
      "DistrictId": this.district
    }
    this.correspondingDetails = []
    this.dbservice.GetRowforDB("centers", whereBody).subscribe(data => {
      this.centerDetails = data['Data']
      for (let i = 0; i < this.centerDetails.length; i++) {
        if (this.centerDetails[i].hidden != true && this.centerDetails[i].Financialyear == this.currentyear) {
          this.correspondingDetails.push(this.centerDetails[i])
        }
      }
      this.courseArray = []
      for (let i = 0; i < this.courseList.length; i++) {
        for (let j = 0; j < this.correspondingDetails.length; j++) {
          let element = this.correspondingDetails[j]
          if (element.courseId != null && element.Financialyear == this.currentyear) {
            for (let k = 0; k < element.courseId.length; k++) {
              if (this.courseList[i].UniqueId == element.courseId[k]) {
                let c = new Course()
                c.Name = this.courseList[i].Name
                c.UniqueId = element.courseId[k]
                this.courseArray.push(c)
              }
            }
          }

        }
      }
      
      // this.dbservice.GetRowforDB("centers").subscribe(data => {
      //   this.centers = data['Data']
      //   for (let i = 0; i < this.centers.length; i++) {
      //     if (this.centers[i].CenterPlace == this.centerPlace) {
      //       this.centerArray.push(this.centers[i])
      //     }
      //   }
      // },
      //   err => {
      //     this.dbservice.LogError(err)
      //   })
    },
      err => {
        this.dbservice.LogError(err)
      })
  }
  showDetails() {

    if (this.followUpOption == "Follow Up") {
      this.isHide = false
      this.isButton = false
    }
    else {
      this.isHide = true
      this.isButton = true
    }
  }
  
  
  sendwatsapp(){
    this.msg= 'hello'
    let m= this.msg  +  ", " + this.name  +  ", "  + this.correspondingDetails[0].CenterName  +  ", "  + this.correspondingDetails[0].CenterPlace  +  ", " + this.correspondingDetails[0].Address  +  ", "  + this.correspondingDetails[0].Address2 +  ", "  + this.correspondingDetails[0].Mobile
       this.dbservice.messagesent(this.mobile3,m).subscribe(data => {
     })
  }
  sendfile(){
    this.body="https://iantz.in/downloads/iAntz-Brochure.pdf"
    this.filename="broucher.pdf"
    this.caption="Broucher"
    this.dbservice.filesent(this.mobile3,this.body,this.filename,this.caption).subscribe(data =>{
    })

  }
  sendimage(){
    this.body= "https://scontent.ftrv1-1.fna.fbcdn.net/v/t1.0-9/67403983_2384272525183511_3859498345655435264_n.jpg?_nc_cat=101&_nc_oc=AQkxWjO_zZP2P54Nb19OAXNtxFofeX2BYCZ9HM8G_JfI8SBOpfVIqh87cuRncK5SLos&_nc_ht=scontent.ftrv1-1.fna&oh=cd0b5e8452641e9ad33477c6d4edd92f&oe=5DD25075",
    this.filename="logo.jpg"
    this.caption="poster"
    this.dbservice.imagesent(this.mobile3,this.body,this.filename,this.caption).subscribe(data =>{
    })

  }
  send() {

    if (this.correspondingDetails[0].CenterName != "U-TEK EDUCATION-PD,DM") {
      this.tempCenter = (this.correspondingDetails[0].CenterName.split(' ').join('_').split("-")[0]).trim()
    }
    else {
      this.tempCenter = "U-TEK_EDUCATION"
    }


    let message = "Hello," + this.name.split(' ').join('_') + "," + "%20" + "Centre%20Details" + "," + this.tempCenter

    if (this.correspondingDetails[0].BuildingName != null) {
      message = message + "," + this.correspondingDetails[0].BuildingName.split(' ').join('_')
    }
    if (this.correspondingDetails[0].Address != null) {
      message = message + "," + this.correspondingDetails[0].Address.split(' ').join('_')
    }
    if (this.correspondingDetails[0].CenterPlace != null) {
      message = message + "," + this.correspondingDetails[0].CenterPlace.split(' ').join('_')
    }
    if (this.correspondingDetails[0].Mobile != null) {
      message = message + "," + (this.correspondingDetails[0].Mobile.toString()).split(' ').join('')
    }
    if (this.correspondingDetails[0].LandPhone != null) {
      message = message + "," + (this.correspondingDetails[0].LandPhone.toString()).split(' ').join('')
    }
    

    this.dbservice.sendMessage(this.mobile1, message).subscribe(data => {
    },
      err => {
        this.dbservice.LogError(err)
      })

  }
  register() {
    this.isButton = false
    if(this.followUpOption != "Pre Follow"){
      this.sendwatsapp()
      this.sendfile()
      this.sendimage()
      this.send()
    }
    if (this.forSms == true) {
      this.send()
    }
    this.dbservice.GetRows("EnquiryStdId").subscribe(data => {
      this.stdIdDetails = data['Data']
      for (let i = 0; i < this.stdIdDetails.length; i++) {
        this.tempId = this.stdIdDetails[i].stdId
      }
      this.tempId = parseInt(this.tempId) + 1
      this.newAddEnquiry.studentId = this.tempId
      this.newAddEnquiry.lastFollowUp = this.formatDate(this.todayDate)
      this.newAddEnquiry.todayDate = this.formatDate(this.todayDate)
      this.newAddEnquiry.address = this.address
      this.newAddEnquiry.centerPlace = this.centerPlace
      if (this.centerPlace != null) {
        for (let i = 0; i < this.centerData.length; i++) {
          if (this.centerData[i].CenterPlace == this.centerPlace) {
            this.centerName = this.centerData[i].CenterName
          }
        }
      }
      this.newAddEnquiry.CenterName = this.centerName
      this.newAddEnquiry.courseNeeded = this.courseNeeded
      this.newAddEnquiry.district = this.district
      this.newAddEnquiry.followUpOption = this.followUpOption
      this.newAddEnquiry.mobile1 = this.mobile1
      this.newAddEnquiry.mobile2 = this.mobile2
      this.newAddEnquiry.name = this.name
      this.newAddEnquiry.followStatus = 1
      this.newAddEnquiry.sourceId = this.sourceId
      let uniqueId = Common.newGuid();
      this.newAddEnquiry.followupnotAttended = 0
      this.newAddEnquiry.followupnotConnected = 0
      this.newAddEnquiry.followuptotalCall = 0
      this.newAddEnquiry.uniquId = uniqueId

      this.dbservice.AddRow(this.newAddEnquiry, 'AddEnquiry').subscribe(data => {
        alert("Added Successfully ")
        // this.dbservice.GetRows("AddEnquiry").subscribe(data => {
        // this.enquiryDetails = data['Data']
        let whereBoby = {
          "uniquId": "as23d-43dfj-sfj22-sdn3n"
        }
        let updateData = {
          "stdId": this.tempId
        }
        this.dbservice.UpdateRows("EnquiryStdId", updateData, whereBoby).subscribe(data => {
          this.name = null
          this.address = null
          this.mobile1 = null
          this.mobile2 = null
          this.district = null
          this.courseNeeded = null
          this.centerPlace = null
          this.followUpOption = null
          this.isChecked = null
          this.sourceId = null
        })
      })
    })
    // },
    // err => {
    //   this.dbservice.LogError(err)
    // })
  }
  checkValue(event: any) {

    if (event == 'A') {
      this.forSms = true
    }
    else {
      this.forSms = false
    }
  }
 
 
  ngOnInit() {
    //     this.authenticationService.userName = this.cookieService.get('userName');
    //     this.authenticationService.userId = this.cookieService.get('userId');
    //     this.authenticationService.userType = this.cookieService.get('userType');
    //     this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    //     this.authenticationService.password = this.cookieService.get('password');
    //     this.authenticationService.userAuthentication().subscribe(resdata => {
    //       if (resdata.Data.length == 1) {
    //         this.authenticationService.isAutherisedUser = true;
    //         if (this.authenticationService.userType != 'Super Admin') {
    //           console.log('this is not super admin');
    //           let whereBody = {
    //             "featureId": "7abd636a-b90b-476c-9631-b9d5514f1f52",
    //             "userTypeId": this.authenticationService.userTypeId,
    //           }
    //           this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
    //             console.log(resdata);
    //             if (resdata.Data.length > 0) {
    //               this.isprivilegeChecked = true;
    //               this.isAuthenticityChecked = true;
    //               this.dbservice.isLogin = true
    //             }
    //             else {
    //               this.router.navigate(['/error'])
    //             }



    //           },
    //             err => {
    //               this.dbservice.LogError(err);

    //             },
    //             () => {

    //             }
    //           )

    //         }
    //         else {
    //           this.isAuthenticityChecked = true;
    //           this.dbservice.isLogin = true
    //           this.authenticationService.isLoggedIn = true
    //         }
    //       }
    //       if (this.authenticationService.isAutherisedUser != true) {
    //         this.router.navigate(['login'])
    //       }
    //     },
    //       err => {
    //         this.dbservice.LogError(err)
    //       },
    //       () => {
    //         console.log('completed');
    //       }
    //     )


  }
}
