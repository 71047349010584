import { Component, OnInit } from '@angular/core';
import { Feature } from '../models/feature';
import { DbService } from '../../dbService';
import { Common } from '../../models/common';
import { AuthenticationService } from '../../authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-feature',
  templateUrl: './add-feature.component.html',
  styleUrls: ['./add-feature.component.css']
})
export class AddFeatureComponent implements OnInit {
  isAuthenticityChecked : boolean
  isEditMode: boolean;
  newFeature: Feature = new Feature();
  featureList: Feature[] = [];

  constructor(private dbservice: DbService, private authenticationService:AuthenticationService,
  private cookieService : CookieService ,private router : Router) {
    this.dbservice.GetRows('Feature').subscribe(resdata => {
      this.featureList = new Array<Feature>();
      for (let i = 0; i <= resdata.Data.length; i++) {
        let c = new Feature();
        if (resdata.Data[i] != null) {

          c = resdata.Data[i];

          this.featureList.push(c);
        }
      }
      console.log(this.featureList, 'feature lIST')
    },
      err => {
        this.dbservice.LogError(err)
      })
  }


  ngOnInit() {
    this.authenticationService.userName = this.cookieService.get('userName');
    this.authenticationService.userId = this.cookieService.get('userId');
    this.authenticationService.userType = this.cookieService.get('userType');
    this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    this.authenticationService.password = this.cookieService.get('password');
    console.log('userName :', this.authenticationService.userName, 'userId :', this.authenticationService.userId, 'userType :',
    this.authenticationService.userType, 'userTypeId :', this.authenticationService.userTypeId, 'password', this.authenticationService.password);
    this.authenticationService.userAuthentication().subscribe(resdata => {
      console.log(resdata);
      console.log(resdata.Data.length)
      if (resdata.Data.length == 1) {
        this.authenticationService.isAutherisedUser = true;
        this.authenticationService.isLoggedIn=true
      }
      if (this.authenticationService.isAutherisedUser != true) {
        this.router.navigate(['login'])
      }
    },
      err => {
        this.dbservice.LogError(err)
      },
      () => {
        console.log('completed');
        this.isAuthenticityChecked = true;
      }
    )
   }
  register() {
    let uniqueId = Common.newGuid();
    this.newFeature.featureId = uniqueId
    console.log(this.newFeature);

    this.dbservice.AddRow(this.newFeature, 'Feature').subscribe(
      resdata => {
        console.log(resdata);
      },
      err => { this.dbservice.LogError(err) },
      () => {
        alert('new feature added successfully');
        this.dbservice.GetRows('Feature').subscribe(resdata => {
          this.featureList = new Array<Feature>();
          for (let i = 0; i <= resdata.Data.length; i++) {
            let c = new Feature();
            if (resdata.Data[i] != null) {

              c = resdata.Data[i];

              this.featureList.push(c);
            }
          }
          console.log(this.featureList, 'feature lIST')
        },
          err => {
            this.dbservice.LogError(err)
          })
        //  this.userPrevilegeList.push(this.newUserPrevilege)
      }

    )
  }
}

