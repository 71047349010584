import { Component, OnInit } from '@angular/core';
import { User, UserData } from '../models/user';
import { UserType } from '../models/usertype';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../../authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { DbService } from '../../dbService';
import { Common } from '../../models/common';
import { Center } from 'src/app/models/Center';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.css']
})
export class AddNewUserComponent implements OnInit {
  isprivilegeChecked : boolean;
  isAuthenticityChecked : boolean
  newUser: User = new User();
  userData: UserData
  isEditMode: Boolean = false;
  userTypeList: UserType[] = [];
  sIdEditMode: string = "";
  UserType;
  centerList:Center[]=[]
  constructor(public dbservice: DbService, private router: Router,
    private route: ActivatedRoute, private fb: FormBuilder,
     private cookieService : CookieService,
     private authenticationService:AuthenticationService ) {

     

    let id = this.route.snapshot.paramMap.get('userId');
    console.log(id)

    if (id != undefined) {
      this.sIdEditMode = id;
      this.isEditMode = true;
      this.dbservice.GetRows('User').subscribe(data => {
        this.userData = data
        for (let i = 0; i < this.userData.Data.length; i++) {
          let userObj = this.userData.Data[i];
          if (userObj.userId == id) {
            this.newUser = userObj
          }
        }

      })

      console.log('here')
    }

  this.dbservice.GetRowforDB('centers').subscribe(data =>{
    console.log('in')
   this.centerList=data['Data']
   console.log(this.centerList)
  },
   err => {
    this.dbservice.LogError(err)
  })

    this.dbservice.GetRows('UserType').subscribe(resdata => {
      this.userTypeList = new Array<UserType>();
      for (let i = 0; i <= resdata.Data.length; i++) {
        let c = new UserType();
        if (resdata.Data[i] != null) {

          c = resdata.Data[i];

          this.userTypeList.push(c);
        }
      }
      console.log(this.userTypeList)
    })
  }

  ngOnInit() {
    this.authenticationService.userName = this.cookieService.get('userName');
    this.authenticationService.userId = this.cookieService.get('userId');
    this.authenticationService.userType = this.cookieService.get('userType');
    this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    this.authenticationService.password = this.cookieService.get('password');
    console.log('userName :', this.authenticationService.userName, 'userId :', this.authenticationService.userId, 'userType :',
      this.authenticationService.userType, 'userTypeId :', this.authenticationService.userTypeId, 'password', this.authenticationService.password);
    this.authenticationService.userAuthentication().subscribe(resdata => {
      console.log(resdata);
      console.log(resdata.Data.length)
      if (resdata.Data.length == 1) {
        this.authenticationService.isAutherisedUser = true;
        if (this.authenticationService.userType != 'Super Admin') {
          console.log('this is not super admin');
          let whereBody = {
            "featureId": "75daf988-20b2-4e9f-afa3-b405fbe39242",
            "userTypeId": this.authenticationService.userTypeId,
          }
          this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
            console.log(resdata);
            if (resdata.Data.length > 0) {
              console.log('have the permission');
              this.isprivilegeChecked = true;
              this.isAuthenticityChecked = true;
              this.dbservice.isLogin = true
            }
            else {
              this.router.navigate(['/error'])
            }



          },
            err => {
              this.dbservice.LogError(err);

            },
            () => {

            }
          )

        }
        else {
          this.isAuthenticityChecked = true;
          this.dbservice.isLogin = true
          this.authenticationService.isLoggedIn=true
        }
      }
      if (this.authenticationService.isAutherisedUser != true) {
        this.router.navigate(['login'])
      }
    },
      err => {
        this.dbservice.LogError(err)
      },
      () => {
        console.log('completed');
      }
    )
   }
  cancel() {
    this.router.navigate(['/listuser'])
  }
  register() {

    if (this.isEditMode) {

      console.log(this.newUser);
      let whereBody = {
       "userId":this.newUser.userId
      }
      let updateData = {
       "username": this.newUser.username,
       "password": this.newUser.password,
       "firstName": this.newUser.firstName,
       "lastName": this.newUser.lastName,
       "centerName": this.newUser.centerName,
       "userTypeId" :this.newUser.userTypeId,
       "remarks":this.newUser.remarks
      };
      this.dbservice.UpdateRows('User', updateData, whereBody).subscribe(resdata => {
       console.log(resdata);
   
     },
       err => { this.dbservice.LogError(err) },
       () => {
         alert('updated successfully');
         this.router.navigate(['user-list'])
       }
     )


    }
    else {
      let uniqueId = "U" + Common.newGuid();
      this.newUser.userId = uniqueId;
      this.newUser.password = "welcome";
      this.newUser.isActive = true;
      let newUserJson = JSON.stringify(this.newUser);
      console.log(this.newUser,'adding data')
      this.dbservice.AddRow(this.newUser, 'User').subscribe(
        resdata => {
          console.log(resdata);
        },
        err => { this.dbservice.LogError(err) },
        () => {
          alert('user added successfully')
        }

      )


    }
  }

}

