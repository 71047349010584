import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { CenterPlace } from '../models/Center';
import { AddEnquiry, AddEnquiryList } from '../models/AddEnquiry';
import { asEnumerable } from 'linq-es2015';
import { Course } from '../models/Course';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../authentication.service';
import { IfStmt } from '@angular/compiler';
import { HttpClient } from '@angular/common/http';
import { CookieserviceService } from '../cookieservice.service';
import { ExcelService } from '../service/excel.service';
import * as XLSX from 'xlsx';
import { Source } from '../models/Source';

@Component({
  selector: 'app-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.css']
})
export class FollowUpComponent implements OnInit {

  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  districtList
  centerList
  idFrom;
  to;
  Name
  district
  fromDate: Date
  toDate: Date
  centerPlace
  List: AddEnquiryList[] = []
  enquiryDetailsList: AddEnquiryList[] = []
  tempEnq: AddEnquiryList[] = []
  enqDetailsList = []
  centerPlaceList: CenterPlace[] = []
  tempCenterPlaceList: CenterPlace[] = []
  centerData: CenterPlace[] = []
  folUpList: AddEnquiryList[] = []
  course: Course[] = []
  enquiryDetails: AddEnquiry[] = []
  currentyear
  correspondingDetails: CenterPlace[] = []
  count: number
  previousyear
  nextyear
  forCenterWise: boolean
  financialYear
  notConnected;
  notAttended;
  totalCall;
  searchmobile
  sourcelist: Source[] = []

  constructor(private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieserviceService, private router: Router, private http: HttpClient
    , private excelservice: ExcelService) {
    let year = new Date().getFullYear()
    let month = new Date().getMonth()
    if (month > 2) {
      this.currentyear = year + "-" + (year + 1)
      this.previousyear = (year - 1) + "-" + year
      this.nextyear = (year + 1) + "-" + (year + 2)
    }
    if (month <= 2) {
      this.currentyear = (year - 1) + "-" + (year)
      this.previousyear = (year - 1) + "-" + year
      this.nextyear = (year + 1) + "-" + (year + 2)

    }
    this.dbservice.GetRowforDB("course").subscribe(data => {
      this.course = data['Data']
      this.dbservice.GetRows('Source').subscribe(data => {
        this.sourcelist = data['Data']
        this.dbservice.GetRows("AddEnquiry").subscribe(data => {
          this.enquiryDetails = data['Data']
          this.dbservice.GetRowforDB("centers").subscribe(data => {
            this.tempCenterPlaceList = data['Data']
            for (let i = 0; i < this.tempCenterPlaceList.length; i++) {
              if (this.tempCenterPlaceList[i].hidden != true && this.tempCenterPlaceList[i].Financialyear == this.currentyear) {
                this.centerPlaceList.push(this.tempCenterPlaceList[i])
              }
            }
            this.centerData = this.centerPlaceList

            for (let i = 0; i < this.enquiryDetails.length; i++) {
              let element = this.enquiryDetails[i]
              if (element != null) {
                let enquiryDetails = new AddEnquiryList()
                let enquiryDetailsObj: AddEnquiry = element
                enquiryDetails.enquiry = enquiryDetailsObj
                let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
                enquiryDetails.course.push(itemData[0])
                let sourcedata = this.sourcelist.filter(s => s.uniqueId == (element.sourceId))
                if (sourcedata[0] != undefined) {
                  enquiryDetails.source.push(sourcedata[0])
                }
                this.enquiryDetailsList.push(enquiryDetails)
              }
            }
            this.cookieService.cookieusertype
            if (this.cookieService.cookiecenter != 'undefined') {
              this.tempEnq = this.enquiryDetailsList.filter(s => (s.enquiry.followUpOption == "Will Come" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2))
                || (s.enquiry.followUpOption == "Follow Up" && s.enquiry.followStatus == 1) || (s.enquiry.followUpOption == "Follow Up Needed" && s.enquiry.followStatus == 2) 
                || (s.enquiry.followUpOption == "Get Admission"))
              this.folUpList = this.tempEnq.filter(s => s.enquiry.CenterName == this.cookieService.cookiecenter)
              this.forCenterWise = true
              let centerWiseData = this.centerPlaceList.filter(s => s.CenterName == this.cookieService.cookiecenter)
              this.district = centerWiseData[0].DistrictId
              this.centerPlace = centerWiseData[0].CenterPlace
            }
            else {
              this.forCenterWise = false
              this.folUpList = this.enquiryDetailsList.filter(s => (s.enquiry.followUpOption == "Will Come" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2))
                || (s.enquiry.followUpOption == "Follow Up" && s.enquiry.followStatus == 1) || (s.enquiry.followUpOption == "Follow Up Needed" && s.enquiry.followStatus == 2) 
                || (s.enquiry.followUpOption == "Get Admission"))
            }
            this.enquiryDetailsList = this.folUpList
            if (this.folUpList.length != 0) {
              this.count = this.folUpList.length
            }
            else {
              this.count = 0
            }
            if (this.folUpList.length != 0) {
              this.List = []
              let length = this.folUpList.length
              this.List.push(this.folUpList[length - 1])
              let counter: number
              for (let i = this.folUpList.length - 1; i >= 0; i--) {
                let temp = this.folUpList[i]
                counter = 0
                for (let j = 0; j < this.List.length; j++) {
                  let templist = this.List[j]
                  if (templist != null) {
                    if (temp.enquiry.name == templist.enquiry.name) {
                      counter = 2
                      break;
                    }
                  }
                }
                if (counter != 2) {
                  this.List.push(temp)
                }
              }
              this.folUpList = this.List
              this.folUpList.sort((a, b) => a.enquiry.followuptotalCall - b.enquiry.followuptotalCall)
            }
          })
          this.isAuthenticityChecked = true
        })
      })
    })
  }

  edit(s) {
    let id = s.enquiry.uniquId
    this.dbservice.Details = s
    this.router.navigate(['/edit', id])
  }
  delete(s) {
    let uniqueId = s.enquiry.uniquId
    let whereBody = {
      "uniquId": uniqueId
    }
    this.dbservice.DeleteRow("AddEnquiry", whereBody).subscribe(data => {
      alert("One Item Deleted")
      this.isAuthenticityChecked = false
      this.folUpList = []
      this.enquiryDetails = []
      this.enquiryDetailsList = []
      this.dbservice.GetRows("AddEnquiry").subscribe(data => {
        this.enquiryDetails = data['Data']
        for (let i = 0; i < this.enquiryDetails.length; i++) {
          let element = this.enquiryDetails[i]
          if (element != null) {
            let enquiryDetails = new AddEnquiryList()
            let enquiryDetailsObj: AddEnquiry = element
            enquiryDetails.enquiry = enquiryDetailsObj
            let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
            enquiryDetails.course.push(itemData[0])
            this.enquiryDetailsList.push(enquiryDetails)
          }
        }

        if (this.cookieService.cookiecenter != 'undefined') {
          this.tempEnq = this.enquiryDetailsList.filter(s => (s.enquiry.followUpOption == "Will Come" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2))
            || (s.enquiry.followUpOption == "Follow Up" && s.enquiry.followStatus == 1) || (s.enquiry.followUpOption == "Follow Up Needed" && s.enquiry.followStatus == 2)
            || (s.enquiry.followUpOption == "Get Admission"))
          this.folUpList = this.tempEnq.filter(s => s.enquiry.CenterName == this.cookieService.cookiecenter)

        }
        else {
          this.folUpList = this.enquiryDetailsList.filter(s => (s.enquiry.followUpOption == "Will Come" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2))
            || (s.enquiry.followUpOption == "Follow Up" && s.enquiry.followStatus == 1) || (s.enquiry.followUpOption == "Follow Up Needed" && s.enquiry.followStatus == 2)
            || (s.enquiry.followUpOption == "Get Admission"))
        }
        this.enquiryDetailsList = this.folUpList
        if (this.folUpList.length != 0) {
          for (let i = 0; i < this.folUpList.length; i++) {
            this.count = i + 1
          }
        }
        else {
          this.count = 0
        }

        if (this.folUpList.length != 0) {
          this.List = []
          let length = this.folUpList.length
          this.List.push(this.folUpList[length - 1])
          let counter: number
          for (let i = this.folUpList.length - 1; i >= 0; i--) {
            let temp = this.folUpList[i]
            counter = 0
            for (let j = 0; j < this.List.length; j++) {
              let templist = this.List[j]
              if (templist != null) {
                if (temp.enquiry.name == templist.enquiry.name) {
                  counter = 2
                  break;
                }
              }
            }
            if (counter != 2) {
              this.List.push(temp)
            }
          }
          this.folUpList = this.List
        }
        this.isAuthenticityChecked = true
      },
        err => {
          this.dbservice.LogError(err)
        })
    },
      err => {
        this.dbservice.LogError(err)
      })
  }
  search() {
    this.folUpList = this.enquiryDetailsList.filter(x => x.enquiry.mobile1 == this.searchmobile || x.enquiry.mobile2 == this.searchmobile)
  }
  getYear() {
    this.folUpList = []
    for (let i = 0; i < this.enquiryDetailsList.length; i++) {
      let year: any = this.formatDate(this.enquiryDetailsList[i].enquiry.todayDate).slice(6)
      let m = this.formatDate(this.enquiryDetailsList[i].enquiry.todayDate)
      let month = m.slice(0, 2)
      if (month > '02') {
        let currentyear = year + "-" + (parseInt(year) + 1)
        this.enquiryDetailsList[i].enquiry.financialYear = currentyear
      }
      else if (month <= '02') {
        let currentyear = (parseInt(year) - 1) + "-" + (year)
        this.enquiryDetailsList[i].enquiry.financialYear = currentyear
      }
    }
    for (let i = 0; i < this.enquiryDetailsList.length; i++) {
      if (this.enquiryDetailsList[i].enquiry.financialYear == this.financialYear) {
        this.folUpList.push(this.enquiryDetailsList[i])
      }
    }
    this.count = this.folUpList.length
  }
  FollowUp(UniqueId) {
    this.router.navigate(['save-Information', UniqueId])
  }
  joinInfo(UniqueId) {
    this.router.navigate(['join-info', UniqueId])
  }
  nextYear(s){
    let wherebody = {
      "uniquId":s.uniquId
    }
    let data = {
      "followUpOption":"Next Year"
    }
    this.dbservice.UpdateRows('AddEnquiry',data,wherebody).subscribe(data => {
      this.router.navigate(['nextyear'])
    })
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }
  formatMonth(month) {

    if (month.length < 2) month = '0' + month;
    return month
  }
  notConnectedCount(s) {
    if (s.followupnotConnected != 0) {
      this.notConnected = s.followupnotConnected + 1
      this.totalCall = s.followupnotAttended + this.notConnected
    }
    else if (s.followupnotAttended == 0 && s.followupnotConnected == 0) {
      this.notConnected = 1
      this.totalCall = 1
    }
    else {
      this.notConnected = 1
      this.totalCall = s.followupnotAttended + this.notConnected
    }
    let whereBody = {
      "uniquId": s.uniquId
    }
    let data = {
      "followupnotConnected": this.notConnected,
      "followuptotalCall": this.totalCall
    }
    this.dbservice.UpdateRows("AddEnquiry", data, whereBody).subscribe(data => {
      this.folUpList = []
      this.enqDetailsList = []
      this.enquiryDetailsList = []
      this.dbservice.GetRowforDB("course").subscribe(data => {
        this.course = data['Data']
        this.dbservice.GetRows("AddEnquiry").subscribe(data => {
          this.enquiryDetails = data['Data']
          for (let i = 0; i < this.enquiryDetails.length; i++) {
            let element = this.enquiryDetails[i]
            if (element != null) {
              let enquiryDetails: AddEnquiryList = new AddEnquiryList()
              let enquiryDetailsObj: AddEnquiry = element
              enquiryDetails.enquiry = enquiryDetailsObj
              let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
              enquiryDetails.course.push(itemData[0])
              this.enquiryDetailsList.push(enquiryDetails)
            }
          }
          if (this.cookieService.cookiecenter != 'undefined') {
            this.tempEnq = this.enquiryDetailsList.filter(s => (s.enquiry.followUpOption == "Will Come" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2))
              || (s.enquiry.followUpOption == "Follow Up" && s.enquiry.followStatus == 1) || (s.enquiry.followUpOption == "Follow Up Needed" && s.enquiry.followStatus == 2)
              || (s.enquiry.followUpOption == "Get Admission"))
            this.folUpList = this.tempEnq.filter(s => s.enquiry.CenterName == this.cookieService.cookiecenter)
            this.forCenterWise = true
            let centerWiseData = this.centerPlaceList.filter(s => s.CenterName == this.cookieService.cookiecenter)
            this.district = centerWiseData[0].DistrictId
            this.centerPlace = centerWiseData[0].CenterPlace
          }
          else {
            this.forCenterWise = false
            this.folUpList = this.enquiryDetailsList.filter(s => (s.enquiry.followUpOption == "Will Come" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2))
              || (s.enquiry.followUpOption == "Follow Up" && s.enquiry.followStatus == 1) || (s.enquiry.followUpOption == "Follow Up Needed" && s.enquiry.followStatus == 2)
              || (s.enquiry.followUpOption == "Get Admission"))
          }
          this.enquiryDetailsList = this.folUpList

          if (this.folUpList.length != 0) {
            this.count = this.folUpList.length
          }
          else {
            this.count = 0
          }

          if (this.folUpList.length != 0) {
            this.List = []
            let length = this.folUpList.length
            this.List.push(this.folUpList[length - 1])
            let counter: number
            for (let i = this.folUpList.length - 1; i >= 0; i--) {
              let temp = this.folUpList[i]
              counter = 0
              for (let j = 0; j < this.List.length; j++) {
                let templist = this.List[j]
                if (templist != null) {
                  if (temp.enquiry.name == templist.enquiry.name) {
                    counter = 2
                    break;
                  }
                }
              }
              if (counter != 2) {
                this.List.push(temp)
              }
            }
            this.folUpList = this.List
            this.folUpList.sort((a, b) => a.enquiry.followuptotalCall - b.enquiry.followuptotalCall)
          }
        })
      })
    })
  }
  notAttendedCount(s) {
    if (s.followupnotAttended != 0) {
      this.notAttended = s.followupnotAttended + 1
      this.totalCall = s.followupnotConnected + this.notAttended
    }
    else if (s.followupnotAttended == 0 && s.followupnotConnected == 0) {
      this.notAttended = 1
      this.totalCall = 1
    }
    else {
      this.notAttended = 1
      this.totalCall = s.followupnotAttended + this.notConnected
    }
    let whereBody = {
      "uniquId": s.uniquId
    }
    let data = {
      "followupnotAttended": this.notAttended,
      "followuptotalCall": this.totalCall
    }
    this.dbservice.UpdateRows("AddEnquiry", data, whereBody).subscribe(data => {
      this.folUpList = []
      this.enqDetailsList = []
      this.enquiryDetailsList = []
      this.dbservice.GetRowforDB("course").subscribe(data => {
        this.course = data['Data']
        this.dbservice.GetRows("AddEnquiry").subscribe(data => {
          this.enquiryDetails = data['Data']
          for (let i = 0; i < this.enquiryDetails.length; i++) {
            let element = this.enquiryDetails[i]
            if (element != null) {
              let enquiryDetails: AddEnquiryList = new AddEnquiryList()
              let enquiryDetailsObj: AddEnquiry = element
              enquiryDetails.enquiry = enquiryDetailsObj
              let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
              enquiryDetails.course.push(itemData[0])
              this.enquiryDetailsList.push(enquiryDetails)
            }
          }
          if (this.cookieService.cookiecenter != 'undefined') {
            this.tempEnq = this.enquiryDetailsList.filter(s => (s.enquiry.followUpOption == "Will Come" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2))
              || (s.enquiry.followUpOption == "Follow Up" && s.enquiry.followStatus == 1) || (s.enquiry.followUpOption == "Follow Up Needed" && s.enquiry.followStatus == 2)
              || (s.enquiry.followUpOption == "Get Admission"))
            this.folUpList = this.tempEnq.filter(s => s.enquiry.CenterName == this.cookieService.cookiecenter)
            this.forCenterWise = true
            let centerWiseData = this.centerPlaceList.filter(s => s.CenterName == this.cookieService.cookiecenter)
            this.district = centerWiseData[0].DistrictId
            this.centerPlace = centerWiseData[0].CenterPlace
          }
          else {
            this.forCenterWise = false
            this.folUpList = this.enquiryDetailsList.filter(s => (s.enquiry.followUpOption == "Will Come" && (s.enquiry.followStatus == 1 || s.enquiry.followStatus == 2))
              || (s.enquiry.followUpOption == "Follow Up" && s.enquiry.followStatus == 1) || (s.enquiry.followUpOption == "Follow Up Needed" && s.enquiry.followStatus == 2)
              || (s.enquiry.followUpOption == "Get Admission"))
          }
          this.enquiryDetailsList = this.folUpList

          if (this.folUpList.length != 0) {
            this.count = this.folUpList.length
          }
          else {
            this.count = 0
          }

          if (this.folUpList.length != 0) {
            this.List = []
            let length = this.folUpList.length
            this.List.push(this.folUpList[length - 1])
            let counter: number
            for (let i = this.folUpList.length - 1; i >= 0; i--) {
              let temp = this.folUpList[i]
              counter = 0
              for (let j = 0; j < this.List.length; j++) {
                let templist = this.List[j]
                if (templist != null) {
                  if (temp.enquiry.name == templist.enquiry.name) {
                    counter = 2
                    break;
                  }
                }
              }
              if (counter != 2) {
                this.List.push(temp)
              }
            }
            this.folUpList = this.List
            this.folUpList.sort((a, b) => a.enquiry.followuptotalCall - b.enquiry.followuptotalCall)
          }
        })
      })
    })
  }
  dateFilter() {
    this.folUpList = []
    let tempFromDate = this.formatDate(this.fromDate)
    let tempToDate = this.formatDate(this.toDate)
    let fromYear = tempFromDate.slice(6)
    let toYear = tempToDate.slice(6)
    let fromMonth = tempFromDate.slice(3, 5)
    let toMonth = tempToDate.slice(3, 5)
    let fromDay = tempFromDate.slice(0, 2)
    let toDay = tempToDate.slice(0, 2)
    let yearList: AddEnquiryList[] = []
    let monthList: AddEnquiryList[] = []

    for (let i = 0; i < this.enquiryDetailsList.length; i++) {
      let year = this.enquiryDetailsList[i].enquiry.todayDate.slice(6)
      if (year >= fromYear && year <= toYear) {
        yearList.push(this.enquiryDetailsList[i])
      }
    }
    for (let i = 0; i < yearList.length; i++) {
      let month = yearList[i].enquiry.todayDate.slice(3, 5)
      if (month >= fromMonth && month <= toMonth) {
        monthList.push(yearList[i])
      }
    }
    for (let i = 0; i < monthList.length; i++) {
      let day = monthList[i].enquiry.todayDate.slice(0, 2)
      let monthDay = monthList[i].enquiry.todayDate.slice(3, 5)
      if (monthDay == fromMonth) {
        if (day >= fromDay && day <= toDay) {
          this.folUpList.push(monthList[i])
        }
      }
      else if (monthDay == toMonth) {
        if (day >= fromDay && day <= toDay) {

          this.folUpList.push(monthList[i])
        }
      }
      else {
        this.folUpList.push(monthList[i])
      }
    }
    this.count = this.folUpList.length
  }

  CenterPlaceDeatils() {

    let whereBody = {
      "CenterPlace": this.centerPlace,
      "DistrictId": this.district
    }
    this.folUpList = []
    this.correspondingDetails = []
    this.dbservice.GetRowforDB("centers", whereBody).subscribe(data => {
      this.tempCenterPlaceList = data['Data']
      for (let i = 0; i < this.tempCenterPlaceList.length; i++) {
        if (this.tempCenterPlaceList[i].hidden != true && this.tempCenterPlaceList[i].Financialyear == this.currentyear) {
          this.correspondingDetails.push(this.tempCenterPlaceList[i])
        }

      }
      for (let i = 0; i < this.enquiryDetailsList.length; i++) {
        if (this.enquiryDetailsList[i].enquiry.CenterName == this.correspondingDetails[0].CenterName) {
          this.folUpList.push(this.enquiryDetailsList[i])
        }
      }
      this.count = this.folUpList.length
    })
  }
  getCenterPlace() {
    this.folUpList = []
    this.centerPlaceList = []
    this.folUpList = this.enquiryDetailsList.filter(s => s.enquiry.district == this.district)
    this.count = this.folUpList.length
    for (let i = 0; i < this.tempCenterPlaceList.length; i++) {
      if (this.tempCenterPlaceList[i].hidden != true && this.tempCenterPlaceList[i].Financialyear == this.currentyear && this.tempCenterPlaceList[i].DistrictId == this.district) {
        this.centerPlaceList.push(this.tempCenterPlaceList[i])
      }

    }
  }

  GetById() {

    this.folUpList = this.enquiryDetailsList.filter(d => {
      var id = d.enquiry.studentId
      return this.idFrom <= id && id <= this.to
    });
    this.count = this.folUpList.length
  }
  ngOnInit() {
  }


  exportExcel() {

    var newList = []
    var mergeinfo = [];
    var row1 = []
    var row2 = []
    var rown = []
    var rown1 = []
    row1.push('Flollowup List')
    mergeinfo.push({ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } })
    row1.push('')
    row1.push('')
    newList.push(row1)
    row2.push('Id')
    mergeinfo.push({ s: { r: 1, c: 0 }, e: { r: 1, c: 0 } })
    row2.push('Mobile')
    mergeinfo.push({ s: { r: 1, c: 1 }, e: { r: 1, c: 1 } })
    row2.push('Name')
    mergeinfo.push({ s: { r: 1, c: 2 }, e: { r: 1, c: 2 } })
    row2.push('Message Text')
    mergeinfo.push({ s: { r: 1, c: 3 }, e: { r: 1, c: 3 } })
    row2.push('District')
    mergeinfo.push({ s: { r: 1, c: 4 }, e: { r: 1, c: 4 } })
    row2.push('Center Name')
    mergeinfo.push({ s: { r: 1, c: 5 }, e: { r: 1, c: 5 } })
    row2.push('Comment')
    mergeinfo.push({ s: { r: 1, c: 6 }, e: { r: 1, c: 6 } })
    row2.push('Course')
    mergeinfo.push({ s: { r: 1, c: 7 }, e: { r: 1, c: 7 } })
    newList.push(row2)

    for (let i = 0; i < this.folUpList.length; i++) {
      let data: AddEnquiry = this.folUpList[i].enquiry
      let temp = []
      if (data != null) {
        if (data.studentId != null)
          temp.push(data.studentId)
        else
          temp.push('')
        if (data.mobile1 != null)
          temp.push(data.mobile1)
        else
          temp.push('')
        if (data.name != null)
          temp.push(data.name)
        else
          temp.push('')
        if (data.message != undefined)
          temp.push(data.message)
        else
          temp.push('')
        if (data.district != null)
          temp.push(data.district)
        else
          temp.push('')
        if (data.CenterName != null)
          temp.push(data.CenterName)
        else
          temp.push('')
        if (data.comment != null)
          temp.push(data.comment)
        else
          temp.push('')
        if (data.courseNeeded != null)
          temp.push(data.courseNeeded)
        else
          temp.push('')
        newList.push(temp)
      }
    }

    this.exportAsExcelFile(newList, 'Followup', mergeinfo)
  }
  public exportAsExcelFile(json: any[], excelFileName: string, mergeinfo: any[]): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    var wscols = [
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },

    ];

    worksheet['!cols'] = wscols;
    worksheet['!merges'] = mergeinfo;
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.excelservice.saveAsExcelFile(excelBuffer, excelFileName);
  }



}
