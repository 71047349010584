import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { tblCenter } from '../models/Center';
import { AuthenticationService } from '../authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-center-details',
  templateUrl: './center-details.component.html',
  styleUrls: ['./center-details.component.css']
})
export class CenterDetailsComponent implements OnInit {

  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  centerList
  allCenter: tblCenter[] = []
  constructor(private dbservice: DbService,private authenticationService:AuthenticationService,
    private cookieService : CookieService ,private router : Router) {

    this.dbservice.GetRowforDB("centers").subscribe(data => {
      this.allCenter=data['Data']
      console.log(this.allCenter)
      this.isAuthenticityChecked=true
    },
      err => {
        this.dbservice.LogError(err)
      })

    // this.dbservice.getAllCenter().subscribe(data => {
    //   this.centerList = data['Data']
    //   for (let i = 0; i < this.centerList.length; i++) {
    //     let c = new tblCenter()
    //     let temp = this.centerList[i]
    //     c.Id = temp[0]
    //     c.CenterName = temp[1]
    //     c.CenterPlace = temp[3]
    //     c.CenterCode = temp[4]
    //     c.Mobile1 = temp[5]
    //     c.Mobile2 = temp[6]
    //     c.LandPhone = temp[7]
    //     if (temp[19] != null) {
    //       c.ExternalId = temp[19]
    //     }
    //     else{
    //       c.ExternalId=0
    //     }
    //     this.allCenter.push(c)
    //   }
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   })
  }

  ngOnInit() {
    // this.authenticationService.userName = this.cookieService.get('userName');
    // this.authenticationService.userId = this.cookieService.get('userId');
    // this.authenticationService.userType = this.cookieService.get('userType');
    // this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    // this.authenticationService.password = this.cookieService.get('password');
    // this.authenticationService.userAuthentication().subscribe(resdata => {
    //   if (resdata.Data.length == 1) {
    //     this.authenticationService.isAutherisedUser = true;
    //     if (this.authenticationService.userType != 'Super Admin') {
    //       console.log('this is not super admin');
    //       let whereBody = {
    //         "featureId": "e2c69e91-5b9b-4bb6-9426-c71af01560f1",
    //         "userTypeId": this.authenticationService.userTypeId,
    //       }
    //       this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
    //         console.log(resdata);
    //         if (resdata.Data.length > 0) {
    //           this.isprivilegeChecked = true;
    //           this.isAuthenticityChecked = true;
    //           this.dbservice.isLogin = true
    //         }
    //         else {
    //           this.router.navigate(['/error'])
    //         }



    //       },
    //         err => {
    //           this.dbservice.LogError(err);

    //         },
    //         () => {

    //         }
    //       )

    //     }
    //     else {
    //       this.isAuthenticityChecked = true;
    //       this.dbservice.isLogin = true
    //       this.authenticationService.isLoggedIn=true
    //     }
    //   }
    //   if (this.authenticationService.isAutherisedUser != true) {
    //     this.router.navigate(['login'])
    //   }
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   },
    //   () => {
    //     console.log('completed');
    //   }
    // )
  }

}
