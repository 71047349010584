import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatepickerModule, BsDatepickerModule,} from 'ngx-bootstrap/datepicker';
import {HttpClient,HttpClientModule} from '@angular/common/http'
import { RouterModule, Routes } from '@angular/router';
import {FormsModule,ReactiveFormsModule} from '@angular/forms'
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AddEnquiryComponent } from './add-enquiry/add-enquiry.component';
import { ViewEnquiryComponent } from './view-enquiry/view-enquiry.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PreFolComponent } from './pre-fol/pre-fol.component';
import { FollowUpComponent } from './follow-up/follow-up.component';
import { SaveInformationComponent } from './save-information/save-information.component';
import { FakeCallComponent } from './fake-call/fake-call.component';
import { NotInterestedComponent } from './not-interested/not-interested.component';
import { CenterNotAvailableComponent } from './center-not-available/center-not-available.component';
import { DdReceivedComponent } from './dd-received/dd-received.component';
import { SmsComponent } from './sms/sms.component';
import { CenterDetailsComponent } from './center-details/center-details.component';
import { AddCourseComponent } from './add-course/add-course.component';
import { EditCourseComponent } from './edit-course/edit-course.component';
import { AddCenterComponent } from './add-center/add-center.component';
import { LinkCourseComponent } from './link-course/link-course.component';
import {MatSelectModule} from '@angular/material/select';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule, MatCheckboxModule} from '@angular/material';
import { JoinedInfoComponent } from './joined-info/joined-info.component';
import { AdmissionDetailsComponent } from './admission-details/admission-details.component';
import { EnquiryReportComponent } from './enquiry-report/enquiry-report.component';
import { JournalComponent } from './journal/journal.component';
import { UserModule } from './user/user.module';
import { CookieService } from 'ngx-cookie-service';
import { LoginHistoryComponent } from './login-history/login-history.component';
import { JournalReportComponent } from './journal-report/journal-report.component';
import { EditComponent } from './edit/edit.component';
import { SourceComponent } from './source/source.component';
import { EditsourceComponent } from './editsource/editsource.component';
import { AdmconfirmedComponent } from './admconfirmed/admconfirmed.component';
import { EnquirydropoutComponent } from './enquirydropout/enquirydropout.component';
import { SourcereportComponent } from './sourcereport/sourcereport.component';
import { NextyearComponent } from './nextyear/nextyear.component';
import { GetadmissionComponent } from './getadmission/getadmission.component';
import { AllComponent } from './all/all.component';
const appRoutes:Routes = [
  {
    path:'dash-board',
    component:DashboardComponent
  },
  {
    path:'home',
    component:HomeComponent
  },
  {
    path:'add-enquiry',
    component:AddEnquiryComponent
  },
  {
    path:'view-enquiry',
    component:ViewEnquiryComponent
  },
  {
    path:'prefol-enquiry',
    component:PreFolComponent
  },
  {
    path:'follow-up',
    component:FollowUpComponent
  },
  {
    path:'save-Information/:uniquId',
    component:SaveInformationComponent
  },

  {
    path:'fake-call',
    component:FakeCallComponent
  },
  {
    path:'center-not-available',
    component:CenterNotAvailableComponent
  },
  {
    path:'not-interested',
    component:NotInterestedComponent
  },
  {
    path:'dd',
    component:DdReceivedComponent
  },
  {
    path:'sms',
    component:SmsComponent
  },
  {
    path:'center-details',
    component:CenterDetailsComponent
  },
  {
    path:'add-course',
    component:AddCourseComponent
  },
  {
    path:'edit-course/:UniqueId',
    component:EditCourseComponent
  },
  {
    path:'add-center',
    component:AddCenterComponent
  },
  {
    path:'journal-report',
    component:JournalReportComponent
  },
  {
    path:'link-course',
    component:LinkCourseComponent
  },
  {
    path:'join-info/:UniqueId',
    component:JoinedInfoComponent
  },
  {
    path:'admission',
    component:AdmissionDetailsComponent
  },
  {
    path:'enquirydropout',
    component:EnquirydropoutComponent
  },
  {
    path:'source',
    component:SourceComponent
  },
  {
    path:'sourcereport',
    component:SourcereportComponent
  },
  {
    path:'editsource/:uniqueId',
    component:EditsourceComponent
  },
  {
    path:'admconfirmed',
    component:AdmconfirmedComponent
  },
  {
    path:'all',
    component:AllComponent
  },
  { path:'enquiry-report',  component:EnquiryReportComponent},
  { path:'journal',  component:JournalComponent},
  { path:'login-history',  component:LoginHistoryComponent},
  {
    path:'nextyear',
    component:NextyearComponent
  },
  {
    path:'getadmission',
    component:GetadmissionComponent
  },
  {
    path: 'add-feature',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'add-privilege',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'add-usertype',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'add-user',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: '',
    component:DashboardComponent
  },
  {
    path: 'edit/:id',
    component:EditComponent
  },
  {
    path: 'login',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'userlist',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'userdetails',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'userdetails/:userId',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'add-user/:userId',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'error',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'editprofile',
    loadChildren: './user/user.module#UserModule'
  },
]
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AddEnquiryComponent,
    ViewEnquiryComponent,
    DashboardComponent,
    PreFolComponent,
    FollowUpComponent,
    SaveInformationComponent,
    FakeCallComponent,
    NotInterestedComponent,
    CenterNotAvailableComponent,
    DdReceivedComponent,
    SmsComponent,
    CenterDetailsComponent,
    AddCourseComponent,
    EditCourseComponent,
    AddCenterComponent,
    LinkCourseComponent,
    JoinedInfoComponent,
    AdmissionDetailsComponent,
    EnquiryReportComponent,
    JournalComponent,
    LoginHistoryComponent,
    JournalReportComponent,
    EditComponent,
    SourceComponent,
    EditsourceComponent,
    AdmconfirmedComponent,
    EnquirydropoutComponent,
    SourcereportComponent,
    NextyearComponent,
    GetadmissionComponent,
    AllComponent
  ],
  imports: [
    UserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    MatSelectModule,
    MatButtonModule, 
    MatCheckboxModule,
    HttpClientModule,
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
