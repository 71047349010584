import { Component, OnInit } from '@angular/core';
import { tblCenter } from '../models/Center';
import { DbService } from '../dbService';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { Login } from '../user/models/login';

@Component({
  selector: 'app-login-history',
  templateUrl: './login-history.component.html',
  styleUrls: ['./login-history.component.css']
})
export class LoginHistoryComponent implements OnInit {

  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  centerList;
  centerName;
  isCenter: boolean = false;
  allCenter: tblCenter[] = []
  tempLoginList: Login[] = []
  loginList: Login[] = []
  constructor(private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieService, private router: Router) {
    this.dbservice.GetRowforDB('centers').subscribe(data => {
      this.centerList = data['Data']
      this.dbservice.GetRows('LoginDetails').subscribe(data => {
        this.tempLoginList = data['Data']
        console.log(this.authenticationService.centerName, 'in')
        if (this.authenticationService.centerName == "undefined") {
          for (let i = this.tempLoginList.length - 1; i >= 0; i--) {
            this.loginList.push(this.tempLoginList[i])
          }
        }
        else {
          this.isCenter = true
          this.centerName = this.authenticationService.centerName
          this.tempLoginList = this.tempLoginList.filter(s => s.centerName == this.authenticationService.centerName)
          for (let i = this.tempLoginList.length - 1; i >= 0; i--) {
            let temp = this.tempLoginList[i]
            this.loginList.push(temp)
          }
        }

      },
        err => {
          this.dbservice.LogError(err)
        })

    },
      err => {
        this.dbservice.LogError(err)
      })

  }

  getCenter() {
    this.loginList = []
    for (let i = 0; i < this.tempLoginList.length; i++) {
      if (this.centerName == this.tempLoginList[i].centerName) {
        let temp = this.tempLoginList[i]
        this.loginList.push(temp)
      }
    }
  }

  ngOnInit() {
    // this.authenticationService.userName = this.cookieService.get('userName');
    // this.authenticationService.userId = this.cookieService.get('userId');
    // this.authenticationService.userType = this.cookieService.get('userType');
    // this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    // this.authenticationService.password = this.cookieService.get('password');
    // this.authenticationService.centerName = this.cookieService.get('centerName')
    // this.authenticationService.userAuthentication().subscribe(resdata => {
    //   if (resdata.Data.length == 1) {
    //     this.authenticationService.isAutherisedUser = true;
    //     if (this.authenticationService.userType != 'Super Admin') {
    //       console.log('this is not super admin');
    //       let whereBody = {
    //         "featureId": "e2c69e91-5b9b-4bb6-9426-c71af01560f1",
    //         "userTypeId": this.authenticationService.userTypeId,
    //       }
    //       this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
    //         console.log(resdata);
    //         if (resdata.Data.length > 0) {
    //           this.isprivilegeChecked = true;
    //           this.isAuthenticityChecked = true;
    //           this.dbservice.isLogin = true
    //         }
    //         else {
    //           this.router.navigate(['/error'])
    //         }



    //       },
    //         err => {
    //           this.dbservice.LogError(err);

    //         },
    //         () => {

    //         }
    //       )

    //     }
    //     else {
    //       this.isAuthenticityChecked = true;
    //       this.dbservice.isLogin = true
    //       this.authenticationService.isLoggedIn = true
    //     }
    //   }
    //   if (this.authenticationService.isAutherisedUser != true) {
    //     this.router.navigate(['login'])
    //   }
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   },
    //   () => {
    //     console.log('completed');
    //   }
    // )
  }


}


