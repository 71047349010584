import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { Course } from '../models/Course';
import { Common } from '../models/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-course',
  templateUrl: './add-course.component.html',
  styleUrls: ['./add-course.component.css']
})
export class AddCourseComponent implements OnInit {

  courseList: Course[] = []
  Name
  courseName;
  newCourse: Course = new Course()
  constructor(private dbservice: DbService,
    private router:Router) {

    this.dbservice.GetRows("EtsCourse").subscribe(data => {
      this.courseList = data['Data']
      console.log(this.courseList)
    },
      err => {
        this.dbservice.LogError(err)
      })
  }

  register() {

    this.newCourse.Name = this.courseName
    let uniqueId = Common.newGuid();
    this.newCourse.UniqueId = uniqueId
    this.dbservice.AddRow(this.newCourse, "EtsCourse").subscribe(data => {
      console.log(data)
      this.dbservice.GetRows("EtsCourse").subscribe(data => {
        this.courseList = data['Data']
      },
        err => {
          this.dbservice.LogError(err)
        })
    },
      err => {
        this.dbservice.LogError(err)
      })
  }
  deleteCourse(UniqueId) {

    let whereBody = {
      "UniqueId": UniqueId
    }
    this.dbservice.DeleteRow("EtsCourse", whereBody).subscribe(data => {
      console.log(data)
      this.dbservice.GetRows("EtsCourse").subscribe(data => {
        this.courseList = data['Data']
      },
        err => {
          this.dbservice.LogError(err)
        })
    },
      err => {
        this.dbservice.LogError(err)
      })
  }

  editCourse(UniqueId){

    this.router.navigate(['/edit-course',UniqueId])
  }
 
  ngOnInit() {
  }

}
