import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserList, UserData, User } from '../models/user';
import { UserType } from '../models/usertype';
import { AuthenticationService } from '../../authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { DbService } from '../../dbService';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  userList: UserList[] = [];
  UserType;
  newUser: User = new User();
  isAuthenticityChecked : boolean;
  userTypeList: UserType[] = [];
  usersData: UserData
  constructor(private route: ActivatedRoute, private dbservice: DbService,
  private router : Router , private authenticationService:AuthenticationService,
  private cookieService : CookieService) {

    let id = this.route.snapshot.paramMap.get('userId');
    console.log(id);
    this.dbservice.GetRows('UserType').subscribe(resdata => {
      this.userTypeList = new Array<UserType>();
      for (let i = 0; i <= resdata.Data.length; i++) {
        let c = new UserType();
        if (resdata.Data[i] != null) {

          c = resdata.Data[i];

          this.userTypeList.push(c);
        }
      }
      console.log(this.userTypeList, 'user type lIST');


      this.dbservice.GetRows('User').subscribe(resdata => {
        this.usersData = resdata;
        this.userList = [];

        for (let i = 0; i <= this.usersData.Data.length; i++) {
          let element = this.usersData.Data[i]

          if (element != null) {


            let quotationListItem = new UserList();
            let qobj: User = element;
            console.log("****" + element);
            if (qobj.userTypeId != undefined) {
              qobj.userTypeId = qobj.userTypeId.replace("/", "");
            }
            quotationListItem.user = qobj;
            let usertype = this.userTypeList.filter(s => s.userTypeId == (qobj.userTypeId));
            if (usertype.length > 0) {
              quotationListItem.usertype = usertype[0];
            }


            this.userList.push(quotationListItem);
            console.log(this.userList);
            for(let i = 0 ; i<= this.userList.length ; i++){
              let tempObj = this.userList[i];
              if(tempObj != null && tempObj.user.userId == id){
                this.newUser = tempObj.user;

              }
            }


          }
        }
      },

        err => {
          this.dbservice.LogError(err)
        }
      )



    },
      err => {
        this.dbservice.LogError(err);
      }
    )

  }

  ngOnInit() {
    this.authenticationService.userName = this.cookieService.get('userName');
    this.authenticationService.userId = this.cookieService.get('userId');
    this.authenticationService.userType = this.cookieService.get('userType');
    this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    this.authenticationService.password = this.cookieService.get('password');
    console.log('userName :', this.authenticationService.userName, 'userId :', this.authenticationService.userId, 'userType :',
    this.authenticationService.userType, 'userTypeId :', this.authenticationService.userTypeId, 'password', this.authenticationService.password);
    this.authenticationService.userAuthentication().subscribe(resdata => {
      console.log(resdata);
      console.log(resdata.Data.length)
      if (resdata.Data.length == 1) {
        this.authenticationService.isAutherisedUser = true;
        this.dbservice.isLogin = true
        this.authenticationService.isLoggedIn=true

      }
      if (this.authenticationService.isAutherisedUser != true) {
        this.router.navigate(['login'])
      }
    },
      err => {
        this.dbservice.LogError(err)
      },
      () => {
        console.log('completed');
        this.isAuthenticityChecked = true;
      }
    )
  }

  updateUser(user : User){
   


  }
  deactivate(user : User) {
    if(confirm('are you sure want to de activate this user')){
    let updateData = {
      "isActive": false
    }
    let whereBody = {
      "userId": user.userId
    };
    this.dbservice.UpdateRows('User', updateData, whereBody).subscribe(resdata => {
      console.log(resdata);
  
    },
      err => { this.dbservice.LogError(err) },
      () => {
        alert('user deactivated successfully');
        this.router.navigate(['userlist'])
      }
    )
    }}
}
