import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddFeatureComponent } from './add-feature/add-feature.component';
import { AddNewUsertypeComponent } from './add-new-usertype/add-new-usertype.component';
import { AddNewUserComponent } from './add-new-user/add-new-user.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { AddNewPrevilegeComponent } from './add-new-previlege/add-new-previlege.component';
import { UsersListComponent } from './users-list/users-list.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { ErrorComponent } from './error/error.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';

const routes: Routes = [
  {
    path: 'add-feature',
    component: AddFeatureComponent
  },
  {
    path: 'add-privilege',
    component: AddNewPrevilegeComponent
  },
  {
    path: 'add-usertype',
    component: AddNewUsertypeComponent
  },
  {
    path: 'add-user',
    component: AddNewUserComponent
  },
  {
    path: 'add-user/:userId',
    component: AddNewUserComponent
  },
  // {
  //   path: '',
  //   component: UserLoginComponent
  // },
  {
    path: 'login',
    component: UserLoginComponent
  },
  {
    path: 'userlist',
    component: UsersListComponent
  },
  {
    path: 'userdetails',
    component: UserDetailsComponent
  },
  {
    path: 'userdetails/:userId',
    component: UserDetailsComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'editprofile',
    component: EditProfileComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
