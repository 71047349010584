import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { CenterPlace } from '../models/Center';
import { AddEnquiryList, AddEnquiry } from '../models/AddEnquiry';
import { formatDate } from 'ngx-bootstrap/chronos/format';
import { Course } from '../models/Course';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../authentication.service';
import { stdId } from '../models/stdId';
import { Common } from '../models/common';
import { CookieserviceService } from '../cookieservice.service';
import { Source } from '../models/Source';

@Component({
  selector: 'app-pre-fol',
  templateUrl: './pre-fol.component.html',
  styleUrls: ['./pre-fol.component.css']
})
export class PreFolComponent implements OnInit {
  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  districtList
  centerPlaceList: CenterPlace[] = []
  tempCenterPlaceList: CenterPlace[] = []
  centerData: CenterPlace[] = []
  district
  temp
  centerList
  prefollowList: AddEnquiryList[] = []
  enquiryDetailsList: AddEnquiryList[] = []
  fromDate;
  totalCall;
  notConnected;
  notAttended;
  toDate
  centerPlace
  idFrom
  to
  Name
  enquiryDetails: AddEnquiry[] = []
  course: Course[] = []
  FilesToUpload
  FileNumber
  isUpload
  filename
  excelData: AddEnquiry[] = []
  stdIdDetails: stdId[] = []
  tempId;
  financialYear;
  enquiryList: AddEnquiryList[] = []
  tempenquiryDetailsList: AddEnquiryList[] = []
  districtName
  currentyear
  count: number
  previousyear
  nextyear
  sourcelist:Source [] = []
  todayDate = new Date()
  constructor(private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieserviceService, private router: Router) {
    let year = new Date().getFullYear()
    let month = new Date().getMonth()
    if (month > 2) {
      this.currentyear = year + "-" + (year + 1)
      this.previousyear = (year - 1) + "-" + year
      this.nextyear = (year + 1) + "-" + (year + 2)
    }
    if (month <= 2) {
      this.currentyear = (year - 1) + "-" + (year)
      this.previousyear = (year - 1) + "-" + year
      this.nextyear = (year + 1) + "-" + (year + 2)

    }

    this.dbservice.GetRowforDB("course").subscribe(data => {
      this.course = data['Data']
      this.dbservice.GetRowforDB("centers").subscribe(data => {
        this.tempCenterPlaceList = data['Data']
        for (let i = 0; i < this.tempCenterPlaceList.length; i++) {
          if (this.tempCenterPlaceList[i].hidden != true && this.tempCenterPlaceList[i].Financialyear == this.currentyear) {
            this.centerPlaceList.push(this.tempCenterPlaceList[i])
          }
        }
        this.centerData = this.centerPlaceList
        // this.dbservice.getDistrict().subscribe(data => {
        //   this.districtList = data['Data']
          this.dbservice.GetRows('Source').subscribe(data => {
            this.sourcelist = data['Data']
            this.getRowFromDB()
          })
        })
      })
    // })
  }
  getYear() {
    this.prefollowList = []
    for (let i = 0; i < this.enquiryDetailsList.length; i++) {
      let year: any = this.formatDate(this.enquiryDetailsList[i].enquiry.todayDate).slice(6)
      let m = this.formatDate(this.enquiryDetailsList[i].enquiry.todayDate)
      let month = m.slice(0, 2)
      if (month > '02') {
        let currentyear = year + "-" + (parseInt(year) + 1)
        this.enquiryDetailsList[i].enquiry.financialYear = currentyear
      }
      else if (month <= '02') {
        let currentyear = (parseInt(year) - 1) + "-" + (year)
        this.enquiryDetailsList[i].enquiry.financialYear = currentyear
      }
    }
    for (let i = 0; i < this.enquiryDetailsList.length; i++) {
      if (this.enquiryDetailsList[i].enquiry.financialYear == this.financialYear) {
        this.prefollowList.push(this.enquiryDetailsList[i])
      }
    }
  }
  getRowFromDB() {
    this.dbservice.GetRows("AddEnquiry").subscribe(data => {
      this.enquiryDetails = data['Data']
      for (let i = 0; i < this.enquiryDetails.length; i++) {
        let element = this.enquiryDetails[i]
        if (element != null) {
          let enquiryDetails = new AddEnquiryList()
          let enquiryDetailsObj: AddEnquiry = element
          enquiryDetails.enquiry = enquiryDetailsObj
          let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
          enquiryDetails.course.push(itemData[0])
          let sourcedata = this.sourcelist.filter(s => s.uniqueId == (element.sourceId))
          if(sourcedata[0] != undefined){
          enquiryDetails.source.push(sourcedata[0])}
          this.enquiryDetailsList.push(enquiryDetails)
        }
      }
      if (this.cookieService.cookiecenter != 'undefined') {
        this.prefollowList = this.enquiryDetailsList.filter(s => ((s.enquiry.followUpOption == "Follow Up Needed" && s.enquiry.followStatus == 1) || (s.enquiry.followUpOption == "Pre Follow" && s.enquiry.followStatus == 1))
          && s.enquiry.CenterName == this.cookieService.cookiecenter)
        this.prefollowList.sort((a, b) => a.enquiry.totalCall - b.enquiry.totalCall);
      }
      else {
        this.prefollowList = this.enquiryDetailsList.filter(s => (s.enquiry.followUpOption == "Follow Up Needed" || s.enquiry.followUpOption == "Pre Follow") && (s.enquiry.followStatus == 1))
        this.prefollowList.sort((a, b) => a.enquiry.totalCall - b.enquiry.totalCall);
      }
      this.enquiryDetailsList = this.prefollowList
      this.prefollowList[0].enquiry.todayDate
      if (this.prefollowList.length != 0) {
        // for (let i = 0; i < this.prefollowList.length; i++) {
        //   this.count = i + 1
        // }
        this.count = this.prefollowList.length
      }
      else {
        this.count = 0
      }
      this.isAuthenticityChecked = true
    },
      err => {
        this.dbservice.LogError(err)
      })

  }

  delete(s) {
    let uniqueId = s.enquiry.uniquId
    let whereBody = {
      "uniquId": uniqueId
    }
    this.dbservice.DeleteRow("AddEnquiry", whereBody).subscribe(data => {
      alert("One Item Deleted")
      this.isAuthenticityChecked = false
      this.enquiryDetails = []
      this.prefollowList = []
      this.enquiryDetailsList = []

      // this.dbservice.getDistrict().subscribe(data => {
      //   this.districtList = data['Data']
        this.getRowFromDB()
        this.isAuthenticityChecked = true
      },
        err => {
          this.dbservice.LogError(err)
        })
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   })


  }

  preFollow(UniqueId) {
    this.router.navigate(['save-Information', UniqueId])
  }

  notConnectedCount(s) {

    if (s.enquiry.notConnected != null) {
      this.notConnected = s.enquiry.notConnected + 1
      this.totalCall = s.enquiry.notAttended + this.notConnected
    }
    else {
      this.notConnected = 1
      this.totalCall = 1
    }
    let whereBody = {
      "uniquId": s.enquiry.uniquId
    }
    let data = {
      "notConnected": this.notConnected,
      "totalCall": this.totalCall,
      "prefollowlastfollowupdate":this.formatDate(this.todayDate)
    }
    this.dbservice.UpdateRows("AddEnquiry", data, whereBody).subscribe(data => {
      this.prefollowList = []
      this.enquiryDetailsList = []
      this.getRowFromDB()
    },
      err => {
        this.dbservice.LogError(err)
      })
  }

  notAttendedCount(s) {

    if (s.enquiry.notAttended != null) {
      this.notAttended = s.enquiry.notAttended + 1
      this.totalCall = s.enquiry.notConnected + this.notAttended
    }
    else {
      this.notAttended = 1
      this.totalCall = 1
    }
    let whereBody = {
      "uniquId": s.enquiry.uniquId
    }
    let data = {
      "notAttended": this.notAttended,
      "totalCall": this.totalCall,
      "prefollowlastfollowupdate":this.formatDate(this.todayDate)
    }
    this.dbservice.UpdateRows("AddEnquiry", data, whereBody).subscribe(data => {
      this.prefollowList = []
      this.enquiryDetailsList = []
      this.getRowFromDB()
    },
      err => {
        this.dbservice.LogError(err)
      })
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }
  dateFilter() {
    this.prefollowList = []
    let tempFromDate = this.formatDate(this.fromDate)
    let tempToDate = this.formatDate(this.toDate)
    let fromYear = tempFromDate.slice(6)
    let toYear = tempToDate.slice(6)
    let fromMonth = tempFromDate.slice(3, 5)
    let toMonth = tempToDate.slice(3, 5)
    let fromDay = tempFromDate.slice(0, 2)
    let toDay = tempToDate.slice(0, 2)
    let yearList: AddEnquiryList[] = []
    let monthList: AddEnquiryList[] = []

    for (let i = 0; i < this.enquiryDetailsList.length; i++) {
      let year = this.enquiryDetailsList[i].enquiry.todayDate.slice(6)
      if (year >= fromYear && year <= toYear) {
        yearList.push(this.enquiryDetailsList[i])
      }
    }
    for (let i = 0; i < yearList.length; i++) {
      let month = yearList[i].enquiry.todayDate.slice(3, 5)
      if (month >= fromMonth && month <= toMonth) {
        monthList.push(yearList[i])
      }
    }
    for (let i = 0; i < monthList.length; i++) {
      let day = monthList[i].enquiry.todayDate.slice(0, 2)
      let monthDay = monthList[i].enquiry.todayDate.slice(3, 5)
      if (monthDay == fromMonth) {
        if (day >= fromDay && day <= toDay) {
          this.prefollowList.push(monthList[i])
        }
      }
      else if (monthDay == toMonth) {
        if (day >= fromDay && day <= toDay) {

          this.prefollowList.push(monthList[i])
        }
      }
      else {
        this.prefollowList.push(monthList[i])
      }
    }
  }

  CenterPlaceDeatils() {

    this.prefollowList = this.enquiryDetailsList.filter(s => s.enquiry.centerPlace == this.centerPlace)
  }
  getCenterPlace() {
    this.prefollowList = []
    this.prefollowList = this.enquiryDetailsList.filter(s => s.enquiry.district == this.district)
  }

  GetById() {

    this.prefollowList = this.enquiryDetailsList.filter(d => {
      var id = d.enquiry.studentId
      return this.idFrom <= id && id <= this.to
    });
  }


  fileChange(event) {
    this.FilesToUpload = event.target.files
  }

  upload() {
    const files: FileList = this.FilesToUpload;
    for (let i = 0; i < this.FilesToUpload.length; i++) {
      var temp = this.FilesToUpload[i]

    }
    this.filename = temp.name
    if (files.length === 0) {
      return;
    };
    // this.dbservice.UploadFiles(files).subscribe((data: any) => {
    //   this.FileNumber = data.FileNumber
    //   console.log(this.FileNumber, 'upload')
    //   this.isUpload = true
    //   files

    this.dbservice.getExcel(this.filename).subscribe(data => {
      this.excelData = data['Data'][0]
      this.dbservice.GetRows("EnquiryStdId").subscribe(data => {
        this.stdIdDetails = data['Data']
        for (let i = 0; i < this.stdIdDetails.length; i++) {
          this.tempId = this.stdIdDetails[i].stdId
        }
        for (let i = 0; i < this.excelData.length; i++) {
          let temp = this.excelData[i]
          let c: AddEnquiry = new AddEnquiry()
          this.dbservice.getCorrespondingDistrict(temp.district).subscribe(data => {
            let districtId = data['Data']
            this.dbservice.getDistrictId(districtId).subscribe(data => {
              this.districtName = data['Data']
              let wherebody = {
                "sourcename":temp.sourceId
              }
              this.dbservice.GetRows('Source',wherebody).subscribe(data => {
                let source = data['Data']
              this.tempId = parseInt(this.tempId) + 1
              c.sourceId = source[0].uniqueId
              c.message = temp.message
              c.mobile1 = temp.mobile1
              c.todayDate = temp.todayDate
              c.studentId = this.tempId
              c.name = temp.name
              c.district = this.districtName
              c.followUpOption = "Follow Up Needed"
              c.followStatus = 1
              c.journalName = temp.journalName
              let uniqueId = Common.newGuid();
              c.uniquId = uniqueId
              c.followuptotalCall = 0
              c.followupnotAttended = 0
              c.followupnotConnected = 0
              this.updateRows()
              this.dbservice.AddRow(c, 'AddEnquiry').subscribe(data => {
                // this.dbservice.GetRows("AddEnquiry").subscribe(data => {
                //   this.enquiryDetails = data['Data']
                //   this.tempenquiryDetailsList=[]
                //   for (let i = 0; i < this.enquiryDetails.length; i++) {
                //     let element = this.enquiryDetails[i]
                //     if (element != null) {
                //       let enquiryDetails = new AddEnquiryList()
                //       let enquiryDetailsObj: AddEnquiry = element
                //       enquiryDetails.enquiry = enquiryDetailsObj
                //       let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
                //       enquiryDetails.course.push(itemData[0])
                //       this.tempenquiryDetailsList.push(enquiryDetails)

                //     }
                //   }
                //   this.enquiryList = this.tempenquiryDetailsList.filter(s => s.enquiry.followUpOption == "Follow Up Needed" && s.enquiry.followStatus == 1)
                //   this.enquiryDetailsList = this.enquiryList
                // },
                //   err => {
                //     this.dbservice.LogError(err)
                //   })
              })
              },
                err => {
                  this.dbservice.LogError(err)
                })
            },
              err => {
                this.dbservice.LogError(err)
              })
          },
            err => {
              this.dbservice.LogError(err)
            })
        }
      },
        err => {
          this.dbservice.LogError(err)
        })
      // this.getRowFromDB()

    },
      err => {
        this.dbservice.LogError(err)
      });


  }
  updateRows() {
    let whereBoby = {
      "uniquId": "as23d-43dfj-sfj22-sdn3n"
    }
    let updateData = {
      "stdId": this.tempId
    }
    this.dbservice.UpdateRows("EnquiryStdId", updateData, whereBoby).subscribe(data => {

      // },
      //   err => {
      //     this.dbservice.LogError(err)
      //   })
    },
      err => {
        this.dbservice.LogError(err)
      })

  }

  ngOnInit() {
    // this.authenticationService.userName = this.cookieService.get('userName');
    // this.authenticationService.userId = this.cookieService.get('userId');
    // this.authenticationService.userType = this.cookieService.get('userType');
    // this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    // this.authenticationService.password = this.cookieService.get('password');
    // this.authenticationService.centerName = this.cookieService.get('centerName');
    // this.authenticationService.userAuthentication().subscribe(resdata => {
    //   if (resdata.Data.length == 1) {
    //     this.authenticationService.isAutherisedUser = true;
    //     if (this.authenticationService.userType != 'Super Admin') {
    //       console.log('this is not super admin');
    //       let whereBody = {
    //         "featureId": "c34039d5-2594-44a2-bcb3-6e72d5acbda8",
    //         "userTypeId": this.authenticationService.userTypeId,
    //       }
    //       this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
    //         if (resdata.Data.length > 0) {
    //           this.isprivilegeChecked = true;
    //           this.isAuthenticityChecked = true;
    //           this.dbservice.isLogin = true
    //         }
    //         else {
    //           this.router.navigate(['/error'])
    //         }



    //       },
    //         err => {
    //           this.dbservice.LogError(err);

    //         },
    //         () => {

    //         }
    //       )

    //     }
    //     else {
    //       this.isAuthenticityChecked = true;
    //       this.dbservice.isLogin = true
    //       this.authenticationService.isLoggedIn = true
    //     }
    //   }
    //   if (this.authenticationService.isAutherisedUser != true) {
    //     this.router.navigate(['login'])
    //   }
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   },
    //   () => {
    //     console.log('completed');
    //   }
    // )
  }

}
