import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { tblCenter } from '../models/Center';
import { Common } from '../models/common';

@Component({
  selector: 'app-add-center',
  templateUrl: './add-center.component.html',
  styleUrls: ['./add-center.component.css']
})
export class AddCenterComponent implements OnInit {

  Id
  CenterName
  CenterPlace
  CenterCode
  Mobile1
  Mobile2
  LandPhone
  ExternalId
  CenterCategory
  BuildingNo
  BuildingName
  HeadName
  Address
  PostOffice
  Pin
  District
  Taluk
  Email
  districtList
  centerList: tblCenter[] = []
  newtblCenter: tblCenter = new tblCenter()
  constructor(private dbservice: DbService) {

    this.dbservice.GetRows("Centers").subscribe(data => {
      this.centerList = data['Data']

      // this.dbservice.getDistrict().subscribe(data => {
      //   this.districtList = data['Data']
      // },
      //   err => {
      //     this.dbservice.LogError(err)
      //   })

    },
      err => {
        this.dbservice.LogError(err)
      })
  }

  save() {

    this.newtblCenter.Address = this.Address
    this.newtblCenter.BuildingName = this.BuildingName
    this.newtblCenter.BuildingNo = this.BuildingNo
    this.newtblCenter.CenterCategory = this.CenterCategory
    this.newtblCenter.CenterCode = this.CenterCode
    this.newtblCenter.CenterPlace = this.CenterPlace
    this.newtblCenter.District = this.District
    this.newtblCenter.Email = this.Email
    this.newtblCenter.LandPhone = this.LandPhone
    this.newtblCenter.Pin = this.Pin
    this.newtblCenter.PostOffice = this.PostOffice
    this.newtblCenter.Taluk = this.Taluk
    this.newtblCenter.CenterName = this.CenterName
    this.newtblCenter.HeadName = this.HeadName
    this.newtblCenter.Mobile1 = this.Mobile1
    this.newtblCenter.Mobile2 = this.Mobile2
    let uniqueId = Common.newGuid();
    this.newtblCenter.UniqueId = uniqueId

    this.dbservice.AddRow(this.newtblCenter, "Centers").subscribe(data => {
      console.log(data)
      this.dbservice.GetRows("Centers").subscribe(data => {
        this.centerList = data['Data']
      },
        err => {
          this.dbservice.LogError(err)
        })
    },
      err => {
        this.dbservice.LogError(err)
      })
  }

  ngOnInit() {
  }

}
