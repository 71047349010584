import { Component, OnInit } from '@angular/core';
import { CookieserviceService } from '../cookieservice.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { DbService } from '../dbService';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  feature;
  userType
  username
  centername
  isloggedin
  u
  t
  f:string
  c
  constructor(
    public cookie: CookieserviceService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private dbService: DbService,
    private http: HttpClient,
  ) {
    this.http.get("../assets/DbName.txt", { responseType: 'text' }).subscribe(data => {
      this.dbService.dbName = data
    })
   }

  ngOnInit() {
    this.Setup()
  }
  ngAfterViewInit(){
    this.Setup()
  }
Setup(){
  this.route.queryParams.subscribe(params => {
    var userId = params['u'];
    let password = params['p'];
    let privilege = params['q']
    let centerName=params['c']
    let userType=params['t']
    let feature = params['f']

    try {
      var u = window.atob(userId);
      var c = window.atob(centerName);
      var t = window.atob(userType);
      var f = window.atob(feature)
      this.cookie.setCookie('User',u)
      this.cookie.setCookie('center',c)
      this.cookie.setCookie('feature',f)
      this.cookie.setCookie('Usertype',t)
      this.cookie.cookieuser = u
      this.cookie.cookiecenter = c
      this.cookie.cookieusertype = t
      this.cookie.feature = f
  this.feature = this.cookieService.get('feature')
  this.userType = this.cookie.cookieusertype
  this.username = this.cookie.cookieuser
  this.centername = this.cookie.cookiecenter
    }
    catch(e){
      console.log(e)
    }
  })
}
}
