import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { Journal } from '../models/Journal';
import { Common } from '../models/common';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.css']
})
export class JournalComponent implements OnInit {

  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  newJournal:Journal=new Journal()
  journalName;
  keyword;
  journalList:Journal[]=[]
  constructor(private dbservice:DbService,private authenticationService:AuthenticationService,
    private cookieService : CookieService ,private router : Router) {


   }
   register() {

    this.newJournal.journalName = this.journalName
    this.newJournal.keyword = this.keyword
    let uniqueId = Common.newGuid();
    this.newJournal.uniqueId = uniqueId
    this.dbservice.AddRow(this.newJournal, "Journal").subscribe(data => {
      console.log(data)
      // this.dbservice.GetRows("Journal").subscribe(data => {
      //   this.journalList = data['Data']
      // },
      //   err => {
      //     this.dbservice.LogError(err)
      //   })
    },
      err => {
        this.dbservice.LogError(err)
      })
  }

  ngOnInit() {
    // this.authenticationService.userName = this.cookieService.get('userName');
    // this.authenticationService.userId = this.cookieService.get('userId');
    // this.authenticationService.userType = this.cookieService.get('userType');
    // this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    // this.authenticationService.password = this.cookieService.get('password');
    // this.authenticationService.userAuthentication().subscribe(resdata => {
    //   if (resdata.Data.length == 1) {
    //     this.authenticationService.isAutherisedUser = true;
    //     if (this.authenticationService.userType != 'Super Admin') {
    //       console.log('this is not super admin');
    //       let whereBody = {
    //         "featureId": "96ceb8d9-38c7-4e84-9794-2f7c94ba9eeb",
    //         "userTypeId": this.authenticationService.userTypeId,
    //       }
    //       this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
    //         console.log(resdata);
    //         if (resdata.Data.length > 0) {
    //           this.isprivilegeChecked = true;
    //           this.isAuthenticityChecked = true;
    //           this.dbservice.isLogin = true
    //         }
    //         else {
    //           this.router.navigate(['/error'])
    //         }



    //       },
    //         err => {
    //           this.dbservice.LogError(err);

    //         },
    //         () => {

    //         }
    //       )

    //     }
    //     else {
    //       this.isAuthenticityChecked = true;
    //       this.dbservice.isLogin = true
    //       this.authenticationService.isLoggedIn=true
    //     }
    //   }
    //   if (this.authenticationService.isAutherisedUser != true) {
    //     this.router.navigate(['login'])
    //   }
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   },
    //   () => {
    //     console.log('completed');
    //   }
    // )
  }

}
