import { UserType } from './usertype';

export class User {
    userId: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    userType: UserType;
    userTypeId :string;
    remarks:string;
    centerName:string

    isActive : boolean
}

export class UserData {


    public Data = new Array<User>();
}


export class UserList {
    user : User = new User ();
    usertype :  UserType = new UserType();
    
}