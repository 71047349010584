import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { AuthenticationService } from '../authentication.service';
import { CookieserviceService } from '../cookieservice.service';
import { Router } from '@angular/router';
import { CenterPlace } from '../models/Center';
import { AddEnquiryList, AddEnquiry } from '../models/AddEnquiry';
import { Course } from '../models/Course';
import { Source } from '../models/Source';

@Component({
  selector: 'app-admconfirmed',
  templateUrl: './admconfirmed.component.html',
  styleUrls: ['./admconfirmed.component.css']
})
export class AdmconfirmedComponent implements OnInit {
  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  districtList
  centerPlaceList: CenterPlace[] = []
  tempCenterPlaceList: CenterPlace[] = []
  district
  centerList
  joinedList: AddEnquiryList[] = []
  enquiryDetailsList: AddEnquiryList[] = []
  fromDate;
  toDate
  centerPlace
  idFrom
  to
  Name
  centerData: CenterPlace[] = []
  enquiryDetails: AddEnquiry[] = []
  course: Course[] = []
  currentyear
  count:number
  previousyear
  nextyear
  financialYear
  sourcelist: Source[] = []

  constructor(
    private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieserviceService, private router: Router
  ) {
    // let year = new Date().getFullYear()
    //   let month = new Date().getMonth()
    //   if(month > 2){
    //     this.currentyear= year+"-"+(year+1)
    //     this.previousyear = (year-1) + "-" + year
    //     this.nextyear = (year+1) + "-" + (year+2)
    //   }
    //   if(month <= 2){
    //     this.currentyear = (year-1) + "-" + (year)
    //     this.previousyear = (year-1) + "-" + year
    //     this.nextyear = (year+1) + "-" + (year+2)

    //   }
    this.dbservice.GetRowforDB("course").subscribe(data => {
      this.course = data['Data']
      this.dbservice.GetRows("AddEnquiry").subscribe(data => {
        this.enquiryDetails = data['Data']
        this.dbservice.GetRowforDB("centers").subscribe(data => {
          this.tempCenterPlaceList = data['Data']
          this.dbservice.GetRows('Source').subscribe(data => {
            this.sourcelist = data['Data']
          for(let i=0;i<this.tempCenterPlaceList.length;i++){
            if(this.tempCenterPlaceList[i].hidden != true  && this.tempCenterPlaceList[i].Financialyear == this.currentyear){
              this.centerPlaceList.push(this.tempCenterPlaceList[i])
            }
          }
          this.centerData = this.centerPlaceList
          // this.dbservice.getDistrict().subscribe(data => {
          //   this.districtList = data['Data']
            for (let i = 0; i < this.enquiryDetails.length; i++) {
              let element = this.enquiryDetails[i]
              if (element != null) {
                let enquiryDetails = new AddEnquiryList()
                let enquiryDetailsObj: AddEnquiry = element
                enquiryDetails.enquiry = enquiryDetailsObj
                let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
                enquiryDetails.course.push(itemData[0])
                let sourcedata = this.sourcelist.filter(s => s.uniqueId == (element.sourceId))
                if (sourcedata[0] != undefined) {
                  enquiryDetails.source.push(sourcedata[0])
                }
                this.enquiryDetailsList.push(enquiryDetails)
              }
            }
            if (this.cookieService.cookiecenter != 'undefined') {
            this.joinedList = this.enquiryDetailsList.filter(s => s.enquiry.followUpOption == "Joined" && s.enquiry.CenterName == this.cookieService.cookiecenter && s.enquiry.joiningstatus == 2)
            }
            else {
              this.joinedList = this.enquiryDetailsList.filter(s => s.enquiry.followUpOption == "Joined" && s.enquiry.joiningstatus == 2)
            }
            this.enquiryDetailsList = this.joinedList
            
            if(this.joinedList.length !=0) {
            for(let i=0;i<this.joinedList.length;i++){
              this.count=i+1
            }
          }
          else{
            this.count=0
          }
          this.isAuthenticityChecked=true
          console.log(this.joinedList,'List')
        })
          })
        })
      })
    // })
   }

  ngOnInit() {
  }

}
