import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { tblCenter } from '../models/Center';
import { Course } from '../models/Course';
import { FormControl } from '@angular/forms';
import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';
import { AuthenticationService } from '../authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-link-course',
  templateUrl: './link-course.component.html',
  styleUrls: ['./link-course.component.css']
})
export class LinkCourseComponent implements OnInit {

  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  districtList
  centerList: tblCenter[] = []
  allCenter: tblCenter[] = []
  courseList: Course[] = []
  Course
  Center
  District;
  DyistrictId;
  list = []
  constructor(private dbservice: DbService,private authenticationService:AuthenticationService,
    private cookieService : CookieService ,private router : Router) {

    // this.dbservice.getDistrict().subscribe(data => {
    //   this.districtList = data['Data']
      this.dbservice.GetRowforDB("centers").subscribe(data => {
        this.allCenter = data['Data']
        this.dbservice.GetRowforDB("course").subscribe(data => {
          this.courseList = data['Data']
        },
          err => {
            this.dbservice.LogError(err)
          })
      },
        err => {
          this.dbservice.LogError(err)
        })
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   })



  }
  getCenters() {
    this.allCenter = []
    let whereBody = {
      "DistrictId": this.District
    }
    this.dbservice.GetRowforDB("centers", whereBody).subscribe(data => {
      this.allCenter = data['Data']
    },
      err => {
        this.dbservice.LogError(err)
      })
  }
  addCourse() {

    this.list.push(this.Course)
    alert('Course Added')
  }
  linkCourse(District, Center) {

    let whereBody = {
      "CenterName": Center,
      "District": District
    }
    let data = {
      "Course": this.list
    }
    this.dbservice.UpdateRows("Centers", data, whereBody).subscribe(data => {
      alert("Course Linked")
      this.list = []
    },
      err => {
        this.dbservice.LogError(err)
      })

  }

  ngOnInit() {
    // this.authenticationService.userName = this.cookieService.get('userName');
    // this.authenticationService.userId = this.cookieService.get('userId');
    // this.authenticationService.userType = this.cookieService.get('userType');
    // this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    // this.authenticationService.password = this.cookieService.get('password');
    // this.authenticationService.userAuthentication().subscribe(resdata => {
    //   if (resdata.Data.length == 1) {
    //     this.authenticationService.isAutherisedUser = true;
    //     if (this.authenticationService.userType != 'Super Admin') {
    //       console.log('this is not super admin');
    //       let whereBody = {
    //         "featureId": "261bbe3f-1bb4-47cd-96bb-1f7fd5688dd7",
    //         "userTypeId": this.authenticationService.userTypeId,
    //       }
    //       this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
    //         console.log(resdata);
    //         if (resdata.Data.length > 0) {
    //           this.isprivilegeChecked = true;
    //           this.isAuthenticityChecked = true;
    //           this.dbservice.isLogin = true
    //         }
    //         else {
    //           this.router.navigate(['/error'])
    //         }



    //       },
    //         err => {
    //           this.dbservice.LogError(err);

    //         },
    //         () => {

    //         }
    //       )

    //     }
    //     else {
    //       this.isAuthenticityChecked = true;
    //       this.dbservice.isLogin = true
    //       this.authenticationService.isLoggedIn=true
    //     }
    //   }
    //   if (this.authenticationService.isAutherisedUser != true) {
    //     this.router.navigate(['login'])
    //   }
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   },
    //   () => {
    //     console.log('completed');
    //   }
    // )
  }

}
