import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { AuthenticationService } from '../authentication.service';
import { CookieserviceService } from '../cookieservice.service';
import { Router } from '@angular/router';
import { CenterPlace } from '../models/Center';
import { AddEnquiryList, AddEnquiry } from '../models/AddEnquiry';
import { Course } from '../models/Course';
import { Source } from '../models/Source';
import * as XLSX from 'xlsx';
import { ExcelService } from '../service/excel.service';

@Component({
  selector: 'app-all',
  templateUrl: './all.component.html',
  styleUrls: ['./all.component.css']
})
export class AllComponent implements OnInit {
  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  districtList
  centerPlaceList: CenterPlace[] = []
  tempCenterPlaceList: CenterPlace[] = []
  district
  centerList
  joinedList: AddEnquiryList[] = []
  newJoinedList: AddEnquiryList[] = []
  enquiryDetailsList: AddEnquiryList[] = []
  fromDate;
  toDate
  centerPlace
  idFrom
  to
  Name
  year
  centerData: CenterPlace[] = []
  enquiryDetails: AddEnquiry[] = []
  course: Course[] = []
  currentyear
  count:number
  previousyear
  nextyear
  financialYear
  sourcelist: Source[] = []
  filteryear
  constructor(
    private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieserviceService, private router: Router, private excelservice: ExcelService
  ) {
    // let year = new Date().getFullYear()
    //   let month = new Date().getMonth()
    //   if(month > 2){
    //     this.currentyear= year+"-"+(year+1)
    //     this.previousyear = (year-1) + "-" + year
    //     this.nextyear = (year+1) + "-" + (year+2)
    //   }
    //   if(month <= 2){
    //     this.currentyear = (year-1) + "-" + (year)
    //     this.previousyear = (year-1) + "-" + year
    //     this.nextyear = (year+1) + "-" + (year+2)

    //   }
    this.year = 'All'
    this.dbservice.GetRowforDB("course").subscribe(data => {
      this.course = data['Data']
      this.dbservice.GetRows("AddEnquiry").subscribe(data => {
        this.enquiryDetails = data['Data']
        this.dbservice.GetRowforDB("centers").subscribe(data => {
          this.tempCenterPlaceList = data['Data']
          this.dbservice.GetRows('Source').subscribe(data => {
            this.sourcelist = data['Data']
          for(let i=0;i<this.tempCenterPlaceList.length;i++){
            if(this.tempCenterPlaceList[i].hidden != true  && this.tempCenterPlaceList[i].Financialyear == this.currentyear){
              this.centerPlaceList.push(this.tempCenterPlaceList[i])
            }
          }
          this.centerData = this.centerPlaceList
          // this.dbservice.getDistrict().subscribe(data => {
          //   this.districtList = data['Data']
            for (let i = 0; i < this.enquiryDetails.length; i++) {
              let element = this.enquiryDetails[i]
              if (element != null) {
                let enquiryDetails = new AddEnquiryList()
                let enquiryDetailsObj: AddEnquiry = element
                enquiryDetails.enquiry = enquiryDetailsObj
                let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
                enquiryDetails.course.push(itemData[0])
                let sourcedata = this.sourcelist.filter(s => s.uniqueId == (element.sourceId))
                if (sourcedata[0] != undefined) {
                  enquiryDetails.source.push(sourcedata[0])
                }
                this.enquiryDetailsList.push(enquiryDetails)
              }
            }
            if (this.cookieService.cookiecenter != 'undefined') {
            this.joinedList = this.enquiryDetailsList
            }
            else {
              this.joinedList = this.enquiryDetailsList
            }
            this.enquiryDetailsList = this.joinedList
            
            if(this.joinedList.length !=0) {
            for(let i=0;i<this.joinedList.length;i++){
              this.count=i+1
            }
          }
          else{
            this.count=0
          }
          this.isAuthenticityChecked=true
          this.newJoinedList = this.joinedList
        })
          })
        })
      })
    // })
   }

   getYearWiseList(){
   if(this.year=='All'){
     this.newJoinedList = this.joinedList
   }
   else if(this.year!='All'){
     this.newJoinedList = []
     for(let i=0;i<this.joinedList.length;i++){
       this.filteryear = this.joinedList[i].enquiry.todayDate.slice(6)
       if(this.year==this.filteryear){
         this.newJoinedList.push(this.joinedList[i])
       }
     }
   }
   }
   exportExcel() {

    var newList = []
    var mergeinfo = [];
    var row1 = []
    var row2 = []
    var rown = []
    var rown1 = []
    row1.push('All List')
    mergeinfo.push({ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } })
    row1.push('')
    row1.push('')
    newList.push(row1)
    row2.push('Date')
    mergeinfo.push({ s: { r: 1, c: 0 }, e: { r: 1, c: 0 } })
    row2.push('Id')
    mergeinfo.push({ s: { r: 1, c: 1 }, e: { r: 1, c: 1 } })
    row2.push('Name')
    mergeinfo.push({ s: { r: 1, c: 2 }, e: { r: 1, c: 2 } })
    row2.push('Application No')
    mergeinfo.push({ s: { r: 1, c: 3 }, e: { r: 1, c: 3 } })
    row2.push('Amount')
    mergeinfo.push({ s: { r: 1, c: 4 }, e: { r: 1, c: 4 } })
    row2.push('Status')
    mergeinfo.push({ s: { r: 1, c: 5 }, e: { r: 1, c: 5 } })
    row2.push('District')
    mergeinfo.push({ s: { r: 1, c: 6 }, e: { r: 1, c: 6 } })
    row2.push('Source')
    mergeinfo.push({ s: { r: 1, c: 7 }, e: { r: 1, c: 7 } })
    row2.push('Center Name')
    mergeinfo.push({ s: { r: 1, c: 7 }, e: { r: 1, c: 7 } })
    row2.push('Course')
    mergeinfo.push({ s: { r: 1, c: 7 }, e: { r: 1, c: 7 } })
    row2.push('Mobile')
    mergeinfo.push({ s: { r: 1, c: 7 }, e: { r: 1, c: 7 } })
    newList.push(row2)

    for (let i = 0; i < this.newJoinedList.length; i++) {
      let data: AddEnquiryList = this.newJoinedList[i]
      let temp = []
      if (data != null) {
        if (data.enquiry.todayDate != null)
          temp.push(data.enquiry.todayDate)
        else
          temp.push('')
        if (data.enquiry.studentId != null)
          temp.push(data.enquiry.studentId)
        else
          temp.push('')
        if (data.enquiry.name != null)
          temp.push(data.enquiry.name)
        else
          temp.push('')
        if (data.enquiry.applicationNo != undefined)
          temp.push(data.enquiry.applicationNo)
        else
          temp.push('')
        if (data.enquiry.AmounntPaid != null)
          temp.push(data.enquiry.AmounntPaid)
        else
          temp.push('')
        if (data.enquiry.followUpOption != null)
          temp.push(data.enquiry.followUpOption)
        else
          temp.push('')
        if (data.enquiry.district != null)
          temp.push(data.enquiry.district)
        else
          temp.push('')
        if (data.source[0] != undefined)
          temp.push(data.source[0].sourcename)
        else
          temp.push('')
        if (data.enquiry.CenterName != null)
        temp.push(data.enquiry.CenterName)
       else
        temp.push('')
       if (data.course[0] != undefined)
       temp.push(data.course[0].Name)
       else
        temp.push('')
       if (data.enquiry.mobile1 != null)
       temp.push(data.enquiry.mobile1)
       else
          temp.push('')
        newList.push(temp)
      }
    }

    this.exportAsExcelFile(newList, 'All', mergeinfo)
  }
  public exportAsExcelFile(json: any[], excelFileName: string, mergeinfo: any[]): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    var wscols = [
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },

    ];

    worksheet['!cols'] = wscols;
    worksheet['!merges'] = mergeinfo;
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.excelservice.saveAsExcelFile(excelBuffer, excelFileName);
  }
  ngOnInit() {
  }

}
