import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { CenterPlace } from '../models/Center';
import { AddEnquiryList, AddEnquiry } from '../models/AddEnquiry';
import { Course } from '../models/Course';
import { EnquiryReport, EnquiryCenterReport } from '../models/EnquiryReport';
import { AuthenticationService } from '../authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-enquiry-report',
  templateUrl: './enquiry-report.component.html',
  styleUrls: ['./enquiry-report.component.css']
})
export class EnquiryReportComponent implements OnInit {

  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  districtList
  centers
  district
  centerList
  totalList: AddEnquiryList[] = []
  tempTotalList: AddEnquiryList[] = []
  fromDate;
  toDate
  centerPlace
  idFrom
  to
  tempList = []
  Name
  forDistrict: boolean = false
  centerReportList: EnquiryCenterReport[] = []
  reportList: EnquiryReport[] = []
  tempReportList: EnquiryReport[] = []
  tempReport: EnquiryReport[] = []
  enquiryDetails: AddEnquiry[] = []
  course: Course[] = []
  ToDate = new Date
  constructor(private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieService, private router: Router) {

    // this.dbservice.getDistrict().subscribe(data => {
    //   this.districtList = data['Data']


      this.dbservice.GetRowforDB("course").subscribe(data => {
        this.course = data['Data']
        this.dbservice.GetRows("AddEnquiry").subscribe(data => {
          this.enquiryDetails = data['Data']
          for (let i = 0; i < this.enquiryDetails.length; i++) {
            let element = this.enquiryDetails[i]
            if (element != null) {
              let enquiryDetails = new AddEnquiryList()
              let enquiryDetailsObj: AddEnquiry = element
              enquiryDetails.enquiry = enquiryDetailsObj
              let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
              enquiryDetails.course.push(itemData[0])
              this.totalList.push(enquiryDetails)
            }
          }
          let counter: number
          this.reportList = []
          for (let i = 0; i < this.totalList.length; i++) {
            let temp = this.totalList[i]
            counter = 0
            let c = new EnquiryReport()
            if (this.reportList.length == 0) {
              c.district = temp.enquiry.district
              this.reportList.push(c)
            }
            else {
              for (let j = 0; j < this.reportList.length; j++) {
                let Obj = this.reportList[j]
                if (temp.enquiry.district == Obj.district) {
                  counter = 2
                  break
                }
              }
              if (counter != 2) {
                c.district = temp.enquiry.district
                this.reportList.push(c)
              }
            }
          }
          for (let i = 0; i < this.reportList.length; i++) {
            let temp = this.reportList[i]
            let c = new EnquiryReport()
            for (let j = 0; j < this.totalList.length; j++) {
              let tempObj = this.totalList[j]
              if (temp.district == tempObj.enquiry.district) {

                if (tempObj.enquiry.followUpOption == "Joined" && tempObj.enquiry.followStatus == 2) {
                  c.admitted = c.admitted + 1
                  if(tempObj.enquiry.todayDate == this.formatDate(this.ToDate)){
                    c.enqDate = c.enqDate + 1
                  }
                  this.reportList[i].todate.push(tempObj.enquiry.todayDate)
                }
                else if ((tempObj.enquiry.followUpOption == "Follow Up Needed" && tempObj.enquiry.followStatus == 1) || (tempObj.enquiry.followUpOption == "Pre Follow" && tempObj.enquiry.followStatus == 1)) {
                  c.firstCall = c.firstCall + 1
                  if(tempObj.enquiry.todayDate == this.formatDate(this.ToDate)){
                    c.enqDate = c.enqDate + 1
                  }
                  this.reportList[i].todate.push(tempObj.enquiry.todayDate)

                }
                else if ((tempObj.enquiry.followUpOption == "Follow Up Needed" && tempObj.enquiry.followStatus == 2) ||  (tempObj.enquiry.followUpOption == "Follow Up" && tempObj.enquiry.followStatus == 2) ||
                  (tempObj.enquiry.followUpOption == "Will Come" && (tempObj.enquiry.followStatus == 1 || tempObj.enquiry.followStatus == 2))) {
                  c.followUp = c.followUp + 1
                  if(tempObj.enquiry.todayDate == this.formatDate(this.ToDate)){
                    c.enqDate = c.enqDate + 1
                  }
                  this.reportList[i].todate.push(tempObj.enquiry.todayDate)
                }
                else if (tempObj.enquiry.followUpOption == "Not Interested" && (tempObj.enquiry.followStatus == 1 || tempObj.enquiry.followStatus == 2)) {
                  c.notInt = c.notInt + 1
                  if(tempObj.enquiry.todayDate == this.formatDate(this.ToDate)){
                    c.enqDate = c.enqDate + 1
                  }
                  this.reportList[i].todate.push(tempObj.enquiry.todayDate)

                }
                else if (tempObj.enquiry.followUpOption == "Center Not Available" && (tempObj.enquiry.followStatus == 1 || tempObj.enquiry.followStatus == 2)) {
                  c.centerNA = c.centerNA + 1
                  if(tempObj.enquiry.todayDate == this.formatDate(this.ToDate)){
                    c.enqDate = c.enqDate + 1
                  }
                  this.reportList[i].todate.push(tempObj.enquiry.todayDate)

                }
                else if (tempObj.enquiry.followUpOption == "Fake Call" && (tempObj.enquiry.followStatus == 1 || tempObj.enquiry.followStatus == 2)) {
                  c.fakeCall = c.fakeCall + 1
                  if(tempObj.enquiry.todayDate == this.formatDate(this.ToDate)){
                    c.enqDate = c.enqDate + 1
                  }
                  this.reportList[i].todate.push(tempObj.enquiry.todayDate)

                }

              }
              this.reportList[i].admitted = c.admitted
              this.reportList[i].enqDate = c.enqDate
              this.reportList[i].firstCall = c.firstCall
              this.reportList[i].followUp = c.followUp
              this.reportList[i].notInt = c.notInt
              this.reportList[i].centerNA = c.centerNA
              this.reportList[i].fakeCall = c.fakeCall
              this.reportList[i].totalEnquiry = c.admitted + c.firstCall + c.followUp + c.notInt + c.centerNA + c.fakeCall

            }
            this.tempReportList.push(this.reportList[i])

          }
        },
          err => {
            this.dbservice.LogError(err)
          })
      },
        err => {
          this.dbservice.LogError(err)
        })
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   })
  }
  dateFilter() {
    this.tempReportList = []
    this.tempTotalList=[]
    let tempFromDate = this.formatDate(this.fromDate)
    let tempToDate = this.formatDate(this.toDate)
    let fromYear = tempFromDate.slice(6)
    let toYear = tempToDate.slice(6)
    let fromMonth = tempFromDate.slice(3, 5)
    let toMonth = tempToDate.slice(3, 5)
    let fromDay = tempFromDate.slice(0, 2)
    let toDay = tempToDate.slice(0, 2)
    let yearList: AddEnquiryList[] = []
    let monthList: AddEnquiryList[] = []

    for (let i = 0; i < this.totalList.length; i++) {
      let year = this.totalList[i].enquiry.todayDate.slice(6)
      if (year >= fromYear && year <= toYear) {
        yearList.push(this.totalList[i])
      }
    }
    for (let i = 0; i < yearList.length; i++) {
      let month = yearList[i].enquiry.todayDate.slice(3, 5)
      if (month >= fromMonth && month <= toMonth) {
        monthList.push(yearList[i])
      }
    }
    for (let i = 0; i < monthList.length; i++) {
      let day = monthList[i].enquiry.todayDate.slice(0, 2)
      let monthDay = monthList[i].enquiry.todayDate.slice(3, 5)
      if (monthDay == fromMonth) {
        if (day >= fromDay && day <= toDay) {
          this.tempTotalList.push(monthList[i])
        }
      }
      else if (monthDay == toMonth) {
        if (day >= fromDay && day <= toDay) {

          this.tempTotalList.push(monthList[i])
        }
      }
      else {
        this.tempTotalList.push(monthList[i])
      }
    }
    let counter: number
    this.reportList = []
    for (let i = 0; i < this.tempTotalList.length; i++) {
      let temp = this.tempTotalList[i]
      counter = 0
      let c = new EnquiryReport()
      if (this.reportList.length == 0) {
        c.district = temp.enquiry.district
        this.reportList.push(c)
      }
      else {
        for (let j = 0; j < this.reportList.length; j++) {
          let Obj = this.reportList[j]
          if (temp.enquiry.district == Obj.district) {
            counter = 2
            break
          }
        }
        if (counter != 2) {
          c.district = temp.enquiry.district
          this.reportList.push(c)
        }
      }
    }
    for (let i = 0; i < this.reportList.length; i++) {
      let temp = this.reportList[i]
      let c = new EnquiryReport()
      for (let j = 0; j < this.tempTotalList.length; j++) {
        let tempObj = this.tempTotalList[j]
        if (temp.district == tempObj.enquiry.district) {

          if (tempObj.enquiry.followUpOption == "Joined" && tempObj.enquiry.followStatus == 2) {
            c.admitted = c.admitted + 1
            if(tempObj.enquiry.todayDate == this.formatDate(this.ToDate)){
              c.enqDate = c.enqDate + 1
            }
            this.reportList[i].todate.push(tempObj.enquiry.todayDate)
          }
          else if ((tempObj.enquiry.followUpOption == "Follow Up Needed" && tempObj.enquiry.followStatus == 1) || (tempObj.enquiry.followUpOption == "Pre Follow" && tempObj.enquiry.followStatus == 1)) {
            c.firstCall = c.firstCall + 1
            if(tempObj.enquiry.todayDate == this.formatDate(this.ToDate)){
              c.enqDate = c.enqDate + 1
            }
            this.reportList[i].todate.push(tempObj.enquiry.todayDate)

          }
          else if ((tempObj.enquiry.followUpOption == "Follow Up Needed" && tempObj.enquiry.followStatus == 2) ||  (tempObj.enquiry.followUpOption == "Follow Up" && tempObj.enquiry.followStatus == 2) ||
            (tempObj.enquiry.followUpOption == "Will Come" && (tempObj.enquiry.followStatus == 1 || tempObj.enquiry.followStatus == 2))) {
            c.followUp = c.followUp + 1
            if(tempObj.enquiry.todayDate == this.formatDate(this.ToDate)){
              c.enqDate = c.enqDate + 1
            }
            this.reportList[i].todate.push(tempObj.enquiry.todayDate)
          }
          else if (tempObj.enquiry.followUpOption == "Not Interested" && (tempObj.enquiry.followStatus == 1 || tempObj.enquiry.followStatus == 2)) {
            c.notInt = c.notInt + 1
            if(tempObj.enquiry.todayDate == this.formatDate(this.ToDate)){
              c.enqDate = c.enqDate + 1
            }
            this.reportList[i].todate.push(tempObj.enquiry.todayDate)

          }
          else if (tempObj.enquiry.followUpOption == "Center Not Available" && (tempObj.enquiry.followStatus == 1 || tempObj.enquiry.followStatus == 2)) {
            c.centerNA = c.centerNA + 1
            if(tempObj.enquiry.todayDate == this.formatDate(this.ToDate)){
              c.enqDate = c.enqDate + 1
            }
            this.reportList[i].todate.push(tempObj.enquiry.todayDate)

          }
          else if (tempObj.enquiry.followUpOption == "Fake Call" && (tempObj.enquiry.followStatus == 1 || tempObj.enquiry.followStatus == 2)) {
            c.fakeCall = c.fakeCall + 1
            if(tempObj.enquiry.todayDate == this.formatDate(this.ToDate)){
              c.enqDate = c.enqDate + 1
            }
            this.reportList[i].todate.push(tempObj.enquiry.todayDate)

          }

        }
        this.reportList[i].admitted = c.admitted
        this.reportList[i].enqDate = c.enqDate
        this.reportList[i].firstCall = c.firstCall
        this.reportList[i].followUp = c.followUp
        this.reportList[i].notInt = c.notInt
        this.reportList[i].centerNA = c.centerNA
        this.reportList[i].fakeCall = c.fakeCall
        this.reportList[i].totalEnquiry = c.admitted + c.firstCall + c.followUp + c.notInt + c.centerNA + c.fakeCall

      }
      this.tempReportList.push(this.reportList[i])

    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }
  getCenterPlace() {
    this.reportList = this.tempReportList.filter(s => s.district == this.district)
    console.log(this.reportList)
    // this.forDistrict=true
    // this.centerReportList = []
    // let counter: number
    // for (let i = 0; i < this.totalList.length; i++) {
    //   let temp = this.totalList[i]
    //   counter = 0
    //   let c = new EnquiryCenterReport()
    //   if (this.centerReportList.length == 0 && temp.enquiry.district == this.district) {
    //     c.Center = temp.enquiry.centerPlace
    //     this.centerReportList.push(c)
    //   }
    //   else if (temp.enquiry.district == this.district) {
    //     for (let j = 0; j < this.centerReportList.length; j++) {
    //       let Obj = this.centerReportList[j]
    //       if (temp.enquiry.centerPlace == Obj.Center) {
    //         counter = 2
    //         break
    //       }
    //     }
    //     if (counter != 2) {
    //       c.Center = temp.enquiry.centerPlace
    //       this.centerReportList.push(c)
    //     }
    //   }
    // }

    // for (let i = 0; i < this.centerReportList.length; i++) {
    //   let temp = this.centerReportList[i]
    //   let c = new EnquiryCenterReport()
    //   for (let j = 0; j < this.totalList.length; j++) {
    //     let tempObj = this.totalList[j]
    //     if (temp.Center == tempObj.enquiry.centerPlace) {

    //       if (tempObj.enquiry.followUpOption == "Joined" && tempObj.enquiry.followStatus == 2) {
    //         c.admitted = c.admitted + 1
    //       }
    //       else if (tempObj.enquiry.followUpOption == "Follow Up Needed" && tempObj.enquiry.followStatus == 1) {
    //         c.firstCall = c.firstCall + 1
    //       }
    //       else if ((tempObj.enquiry.followUpOption == "Follow Up Needed" && tempObj.enquiry.followStatus == 2) ||
    //         (tempObj.enquiry.followUpOption == "Will Come" && (tempObj.enquiry.followStatus == 1 || tempObj.enquiry.followStatus == 2))) {
    //         c.followUp = c.followUp + 1
    //       }
    //       else if (tempObj.enquiry.followUpOption == "Not Interested" && (tempObj.enquiry.followStatus == 1 || tempObj.enquiry.followStatus == 2)) {
    //         c.notInt = c.notInt + 1
    //       }
    //       else if (tempObj.enquiry.followUpOption == "Center Not Available" && (tempObj.enquiry.followStatus == 1 || tempObj.enquiry.followStatus == 2)) {
    //         c.centerNA = c.centerNA + 1
    //       }
    //       else if (tempObj.enquiry.followUpOption == "Fake Call" && (tempObj.enquiry.followStatus == 1 || tempObj.enquiry.followStatus == 2)) {
    //         c.fakeCall = c.fakeCall + 1
    //       }

    //     }
    //     this.centerReportList[i].admitted = c.admitted
    //     this.centerReportList[i].firstCall = c.firstCall
    //     this.centerReportList[i].followUp = c.followUp
    //     this.centerReportList[i].notInt = c.notInt
    //     this.centerReportList[i].centerNA = c.centerNA
    //     this.centerReportList[i].fakeCall = c.fakeCall
    //     this.centerReportList[i].totalEnquiry = c.admitted + c.firstCall + c.followUp + c.notInt + c.centerNA + c.fakeCall
    //   }
    // }
    // console.log(this.centerReportList)

  }

  ngOnInit() {
    // this.authenticationService.userName = this.cookieService.get('userName');
    // this.authenticationService.userId = this.cookieService.get('userId');
    // this.authenticationService.userType = this.cookieService.get('userType');
    // this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    // this.authenticationService.password = this.cookieService.get('password');
    // this.authenticationService.userAuthentication().subscribe(resdata => {
    //   if (resdata.Data.length == 1) {
    //     this.authenticationService.isAutherisedUser = true;
    //     if (this.authenticationService.userType != 'Super Admin') {
    //       console.log('this is not super admin');
    //       let whereBody = {
    //         "featureId": "d84427cc-606c-4f92-b572-1a1f7f034eef",
    //         "userTypeId": this.authenticationService.userTypeId,
    //       }
    //       this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
    //         console.log(resdata);
    //         if (resdata.Data.length > 0) {
    //           this.isprivilegeChecked = true;
    //           this.isAuthenticityChecked = true;
    //           this.dbservice.isLogin = true
    //         }
    //         else {
    //           this.router.navigate(['/error'])
    //         }



    //       },
    //         err => {
    //           this.dbservice.LogError(err);

    //         },
    //         () => {

    //         }
    //       )

    //     }
    //     else {
    //       this.isAuthenticityChecked = true;
    //       this.dbservice.isLogin = true
    //       this.authenticationService.isLoggedIn = true
    //     }
    //   }
    //   if (this.authenticationService.isAutherisedUser != true) {
    //     this.router.navigate(['login'])
    //   }
    // },
    //   err => {
    //     this.dbservice.LogError(err)
    //   },
    //   () => {
    //     console.log('completed');
    //   }
    // )
  }

}
