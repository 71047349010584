import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { AuthenticationService } from '../authentication.service';
import { CookieserviceService } from '../cookieservice.service';
import { Router } from '@angular/router';
import { CenterPlace } from '../models/Center';
import { AddEnquiryList, AddEnquiry } from '../models/AddEnquiry';
import { Course } from '../models/Course';
import { Source } from '../models/Source';
@Component({
  selector: 'app-nextyear',
  templateUrl: './nextyear.component.html',
  styleUrls: ['./nextyear.component.css']
})
export class NextyearComponent implements OnInit {
  isprivilegeChecked: boolean;
  isAuthenticityChecked: boolean
  districtList
  centerPlaceList: CenterPlace[] = []
  tempCenterPlaceList: CenterPlace[] = []
  district
  centerList
  nextyearList: AddEnquiryList[] = []
  enquiryDetailsList: AddEnquiryList[] = []
  fromDate;
  toDate
  centerPlace
  idFrom
  to
  Name
  centerData: CenterPlace[] = []
  enquiryDetails: AddEnquiry[] = []
  course: Course[] = []
  currentyear
  count: number
  previousyear
  nextyear
  financialYear
  sourcelist: Source[] = []
  constructor(private dbservice: DbService, private authenticationService: AuthenticationService,
    private cookieService: CookieserviceService, private router: Router) {
    this.dbservice.GetRowforDB("course").subscribe(data => {
      this.course = data['Data']
      this.dbservice.GetRows("AddEnquiry").subscribe(data => {
        this.enquiryDetails = data['Data']
        this.dbservice.GetRowforDB("centers").subscribe(data => {
          this.tempCenterPlaceList = data['Data']
          this.dbservice.GetRows('Source').subscribe(data => {
            this.sourcelist = data['Data']
            this.centerPlaceList = this.tempCenterPlaceList.filter(x => x.hidden != true && x.Financialyear == this.currentyear)
            this.centerData = this.centerPlaceList
            for (let i = 0; i < this.enquiryDetails.length; i++) {
              let element = this.enquiryDetails[i]
              if (element != null) {
                let enquiryDetails = new AddEnquiryList()
                let enquiryDetailsObj: AddEnquiry = element
                enquiryDetails.enquiry = enquiryDetailsObj
                let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
                enquiryDetails.course.push(itemData[0])
                let sourcedata = this.sourcelist.filter(s => s.uniqueId == (element.sourceId))
                if (sourcedata[0] != undefined) {
                  enquiryDetails.source.push(sourcedata[0])
                }
                this.enquiryDetailsList.push(enquiryDetails)
              }
            }
            if (this.cookieService.cookiecenter != 'undefined') {
              this.nextyearList = this.enquiryDetailsList.filter(s => s.enquiry.followUpOption == "Next Year" && s.enquiry.CenterName == this.cookieService.cookiecenter)
            }
            else {
              this.nextyearList = this.enquiryDetailsList.filter(s => s.enquiry.followUpOption == "Next Year")
            }
            this.enquiryDetailsList = this.nextyearList

            if (this.nextyearList.length != 0) {
              this.count = this.nextyearList.length
            }
            else {
              this.count = 0
            }
            this.isAuthenticityChecked = true
            console.log(this.nextyearList, 'List')
          })
        })
      })
    })
  }

  ngOnInit() {
  }

}
