import { Component, OnInit } from '@angular/core';
import { Routes, ActivatedRoute, Router } from '@angular/router';
import { DbService } from '../dbService';
import { AddEnquiry, AddEnquiryList } from '../models/AddEnquiry';
import { Course } from '../models/Course';
import { CenterPlace, Center } from '../models/Center';
import { Common } from '../models/common';
import { CookieserviceService } from '../cookieservice.service';

@Component({
  selector: 'app-save-information',
  templateUrl: './save-information.component.html',
  styleUrls: ['./save-information.component.css']
})
export class SaveInformationComponent implements OnInit {

  enquiryDetails: AddEnquiry[] = []
  course: Course[] = []
  enquiryDetailsList: AddEnquiryList[] = []
  newAddEnquiry: AddEnquiry = new AddEnquiry()
  name;
  tempCenter
  todayDate = new Date()
  Name;
  district;
  districtList
  courseNeeded;
  address;
  mobile1;
  comment
  id;
  followUpDate;
  centerPlace;
  days;
  studentId
  centerName;
  followUpOption
  followUpStatus
  courseArray: Course[] = []
  centerDetailsList: Center[] = []
  centers: CenterPlace[] = []
  correspondingDetails: CenterPlace[] = []
  tempCenters: Center[] = []
  currentyear
  message
  todate
  isChecked
  forSms: boolean
  enquirydropout: boolean
  constructor(private route: ActivatedRoute, private router: Router,
    private dbservice: DbService, public cookie: CookieserviceService) {
    let year = new Date().getFullYear()
    let month = new Date().getMonth()
    if (month > 2) {
      this.currentyear = year + "-" + (year + 1)
    }
    if (month <= 2) {
      this.currentyear = (year - 1) + "-" + (year)
    }
    this.dbservice.getCourse("course").subscribe(data => {
      this.course = data['Data']
      this.dbservice.GetRows("AddEnquiry").subscribe(data => {
        this.enquiryDetails = data['Data']
        this.dbservice.GetRowforDB("centers").subscribe(data => {
          this.centers = data['Data']
          this.centers = this.centers.filter(x => x.hidden != true && x.Financialyear == this.currentyear)
          for (let i = 0; i < this.centers.length; i++) {
            let Obj = this.centers[i]
            console.log(Obj,'important')
            if (Obj != null) {
              let centerDetails = new Center()
              let centerDetailsObj: CenterPlace = Obj
              centerDetails.centerDetails = centerDetailsObj
              if (Obj.courseId != null) {
                for (let j = 0; j < Obj.courseId.length; j++) {
                  let element = Obj.courseId[j]
                  let itemData = this.course.filter(s => s.UniqueId == (element))
                  centerDetails.course.push(itemData[0])
                }
              }
              this.centerDetailsList.push(centerDetails)
            }
          }
          this.tempCenters = this.centerDetailsList
          // this.centerDetailsList = this.tempCenters.filter(s => s.centerDetails.DistrictId == this.district)
          console.log(this.centerDetailsList, 'center list')
          this.id = this.route.snapshot.paramMap.get('uniquId');
          for (let i = 0; i < this.enquiryDetails.length; i++) {
            let element = this.enquiryDetails[i]
            if (element != null) {
              let enquiryDetails = new AddEnquiryList()
              let enquiryDetailsObj: AddEnquiry = element
              enquiryDetails.enquiry = enquiryDetailsObj
              let itemData = this.course.filter(s => s.UniqueId == (element.courseNeeded))
              enquiryDetails.course.push(itemData[0])
              this.enquiryDetailsList.push(enquiryDetails)
            }
          }
          if (this.id != undefined) {
            for (let i = 0; i < this.enquiryDetailsList.length; i++) {
              let Obj = this.enquiryDetailsList[i]
              if (Obj != null && Obj.enquiry.uniquId == this.id) {
                console.log(Obj, 'obj dertails')
                this.name = Obj.enquiry.name
                this.district = Obj.enquiry.district
                this.courseNeeded = Obj.enquiry.courseNeeded
                this.address = Obj.enquiry.address
                this.mobile1 = Obj.enquiry.mobile1
                this.followUpStatus = Obj.enquiry.followStatus
                this.followUpOption = "Follow Up Needed"
                this.studentId = Obj.enquiry.studentId
                this.comment = Obj.enquiry.comment
                this.centerName = Obj.enquiry.CenterName
                this.message = Obj.enquiry.message
                this.todate = Obj.enquiry.todayDate
                this.getCourse()
                this.getCenter()
              }
            }
          }

          // if (this.cookie.cookiecenter != 'undefined') {
          //   this.getCourse()
          // }
          console.log(this.centerDetailsList)
          console.log(this.district, 'district data')
          console.log(this.centerName, 'center details')
        })
      })
    })
  }
  getCourse() {
    let whereBody = {
      "CenterName": this.centerName,
      "DistrictId": this.district
    }
    console.log(this.centerName, 'centername')
    console.log(this.district, 'district')
    this.correspondingDetails = []
    this.dbservice.GetRowforDB("centers", whereBody).subscribe(data => {
      this.centers = data['Data']
      console.log(this.centers, 'data')
      for (let i = 0; i < this.centers.length; i++) {
        if (this.centers[i].hidden != true && this.centers[i].Financialyear == this.currentyear) {

          this.correspondingDetails.push(this.centers[i])
        }
      }

      this.courseArray = []
      for (let i = 0; i < this.course.length; i++) {
        for (let j = 0; j < this.centers.length; j++) {
          let element = this.centers[j]
          if (element.courseId != null && element.Financialyear == this.currentyear) {
            for (let k = 0; k < element.courseId.length; k++) {
              if (this.course[i].UniqueId == element.courseId[k]) {
                let c = new Course()
                c.Name = this.course[i].Name
                c.UniqueId = element.courseId[k]
                this.courseArray.push(c)
              }
            }
          }

        }
      }
    },
      err => {
        this.dbservice.LogError(err)
      })
  }
  getCenter() {
    this.centerDetailsList = this.tempCenters.filter(s => s.centerDetails.DistrictId == this.district)
  }

  notInterested() {
    this.followUpOption = "Not Interested"
    this.enquirydropout = false
    this.saveCall()
  }
  centerNotAvailable() {
    this.followUpOption = "Center Not Available"
    this.enquirydropout = false
    this.saveCall()
  }
  ddReceived() {

    this.followUpOption = "DD Received"
    this.enquirydropout = false
    this.saveCall()

  }
  fakeCall() {

    this.followUpOption = "Fake Call"
    this.enquirydropout = false
    this.saveCall()
  }
  getAdmission(){
    this.followUpOption = "Get Admission"
    this.enquirydropout = false
    this.saveCall()
  }
  enquiryDropOut() {
    this.followUpOption = "Enquiry DropOut"
    this.enquirydropout = true
    this.saveCall()
  }
  send() {

    if (this.correspondingDetails[0].CenterName != "U-TEK EDUCATION-PD,DM") {
      this.tempCenter = (this.correspondingDetails[0].CenterName.split(' ').join('_').split("-")[0]).trim()
    }
    else {
      this.tempCenter = "U-TEK_EDUCATION"
    }


    let message = "Hello," + this.name.split(' ').join('_') + "," + "%20" + "Centre%20Details" + "," + this.tempCenter

    if (this.correspondingDetails[0].BuildingName != null) {
      message = message + "," + this.correspondingDetails[0].BuildingName.split(' ').join('_')
    }
    if (this.correspondingDetails[0].Address != null) {
      message = message + "," + this.correspondingDetails[0].Address.split(' ').join('_')
    }
    if (this.correspondingDetails[0].CenterPlace != null) {
      message = message + "," + this.correspondingDetails[0].CenterPlace.split(' ').join('_')
    }

    if (this.correspondingDetails[0].Mobile != null) {
      message = message + "," + (this.correspondingDetails[0].Mobile.toString()).split(' ').join('')
    }
    if (this.correspondingDetails[0].LandPhone != null) {
      message = message + "," + (this.correspondingDetails[0].LandPhone.toString()).split(' ').join('')
    }

    this.dbservice.sendMessage(this.mobile1, message).subscribe(data => {
      console.log(data)
    },
      err => {
        this.dbservice.LogError(err)
      })
  }
  checkValue(event: any) {

    if (event == 'A') {
      this.forSms = true
    }
    else {
      this.forSms = false
    }
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }
  saveCall() {
    if (this.forSms == true) {
      this.send()
    }
    let whereBody = {
      "uniquId": this.id
    }
    let data = {
      "todayDate": this.todate,
      "lastFollowUp": this.formatDate(this.todayDate),
      "nextFollowUp": this.formatDate(this.followUpDate),
      "followUpOption": this.followUpOption,
      "comment": this.comment,
      "days": this.days,
      "district": this.district,
      "CenterName": this.centerName,
      "followStatus": 2,
      "courseNeeded": this.courseNeeded,
      "fromenquirydropout": this.enquirydropout,
    }
    this.dbservice.UpdateRows("AddEnquiry", data, whereBody).subscribe(data => {
      console.log(data)
      this.enquirydropout = false
      alert('Updated Successfully')
      this.router.navigate(['/follow-up'])
    },
      err => {
        this.dbservice.LogError(err)
      })
  }
  ngOnInit() {
  }

}
