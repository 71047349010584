import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AddEnquiry, AddEnquiryList } from './models/AddEnquiry';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  public pyUrl = "http://104.196.62.213:81/";
  public dbName;
  // public dbName="ETS_UTekITI";
  public title;
  public Details:AddEnquiryList=new AddEnquiryList
  public isAutherisedUser: boolean
  public isLogin: boolean
  // public py = "http://localhost:82/"                                                   
  public py = "http://104.196.62.213:82/"  
  public pyUr = "https://eu62.chat-api.com/instance60602/sendMessage?token=g9wv9guv2zwfxukd"  
  public pyUrl2= "https://api.chat-api.com/instance60602/sendFile?token=g9wv9guv2zwfxukd"                          
  // public dbName = "AccademicDBTest"
  public key = "xxx";
  
  constructor(private http: HttpClient) { }

  public AddRow(data, tableName) {
    const body = {
      "DbName": this.dbName,
      "Key": this.key,
      "Table": tableName,
      "Data": data
    };

    return this.http.post(this.pyUrl + 'AddRow', body)

  }
  /**
   * 
   * @param tableName eg:- {"Student"}
   * @param whereBody eg:- { "invoiceId": "100" }
   */

  public sendMessage(toNumber, message) {
    console.log(toNumber, message)
    const body = {
      "mobileTo": toNumber,
      "message": message,
    }
    return this.http.post<any>(this.py , body)
  
 
  }
  public messagesent(value1, value2) {
    var body;
    console.log('from parameter', value1)
    console.log('from parameter', value2)
        body = {
  
            "phone": value1,
            "body": value2,
            
  
        };

        console.log('messagesent', body);
        return this.http.post(this.pyUr, body) 
         
    }
  public filesent(phone,body,filename,caption){
    var body;
    console.log('from parameter', phone)
    console.log('from parameter', body)
    console.log('from parameter', filename)
    console.log('from parameter', caption)
    body={

      "phone": phone,
      "body": body,
      "filename": filename,
      "caption": caption,
    };
    console.log('filesent',body);
    return this.http.post(this.pyUrl2,body)
  }
  public imagesent(phone,body,filename,caption){
    var body;
    console.log('from parameter', phone)
    console.log('from parameter', body)
    console.log('from parameter', filename)
    console.log('from parameter', caption)
    body={

      "phone": phone,
      "body": body,
      "filename": filename,
      "caption": caption,
    };
    console.log('filesent',body);
    return this.http.post(this.pyUrl2,body)
  }
  public UploadFiles(files) {

    const formData: FormData = new FormData();
    formData.append('file', files[0], files[0].name);
    formData.append('UploadPath', 'UploadFiles')
    return this.http.post(this.py + 'UploadFile', formData);
  }
  public getExcel(filename) {
    console.log(filename,'name')
    const body = {
    "filename":'/'+filename
    }
    return this.http.post<any>(this.py + 'getExcel', body)
  }

  public GetRows(tableName, whereBody = null): Observable<any> {

    // whereBody = condtion for data eg {"id":10}

    var body;

    //Get all table data 
    if (whereBody == null) {
      body = {
        "DbName": this.dbName,
        "Key": this.key,
        "Table": tableName,
      };
    }

    //Get table data with where condition
    else {
      body = {
        "DbName": this.dbName,
        "Key": this.key,
        "Table": tableName,
        "Where": whereBody
      };
    }

    console.log('GETROWS****', body);
    return this.http.post<any>(this.pyUrl + 'GetRows', body)


  }

  public getddtrackerstudentdetails(tableName): Observable<any>{
    var body;
    body = {
      "DbName":"AccademicDB",
      "Key":this.key,
      "Table":tableName
    }
    return this.http.post<any>(this.pyUrl + 'GetRows', body)
  }
  
  public UpdateRows(tableName, updateCode, whereBody = null, Mode = null) {
    // updatecode = data to be updated eg : {"data":'10'}
    // whereBody = which data to be updated eg : {"id":'10'}
    // Mode = multiple entry updation. mode is always {"ALL"}

    var body;

    // single value updation
    if (Mode == null) {
      body = {

        "DbName": this.dbName,
        "Table": tableName,
        "Key": this.key,
        "Where": whereBody,
        "Data": updateCode

      };
    }

    // multiple value updation
    else {

      body = {
        "DbName": this.dbName,
        "Table": tableName,
        "Key": this.key,
        "Where": whereBody,
        "Data": updateCode,
        "Mode": Mode
      }
    }

    console.log('UPDATE ROWS****', body);
    return this.http.post(this.pyUrl + 'UpdateRows', body)
  }


  public DeleteRow(tableName, whereBody = null): Observable<any> {

    // whereBody = condtion for data eg {"id":10}

    var body;

    //Get all table data 
    if (whereBody == null) {
      body = {
        "DbName": this.dbName,
        "Key": this.key,
        "Table": tableName,
      };
    }

    //Get table data with where condition
    else {
      body = {
        "DbName": this.dbName,
        "Key": this.key,
        "Table": tableName,
        "Where": whereBody
      };
    }

    console.log('GETROWS****', body);
    return this.http.post<any>(this.pyUrl + 'DeleteRow', body)


  }
  public GetRowforDB(tableName, whereBody = null): Observable<any> {

    var body;

    if (whereBody == null) {
      body = {
        "DbName": "AccademicDB",
        "Key": this.key,
        "Table": tableName,
      };
    }
    else {
      body = {
        "DbName": "AccademicDB",
        "Key": this.key,
        "Table": tableName,
        "Where": whereBody
      };
    }
    console.log('GETROWS****', body);
    return this.http.post(this.pyUrl + 'GetRows', body)
  }

  public LogError(err) {
    console.log('Error: ' + err.error);
    console.log('Name: ' + err.name);
    console.log('Message: ' + err.message);
    console.log('Status: ' + err.status);
  }

  public getCenterPlace() {
    var body = {
      "Query": "select centername from tblcenter"
    }
    return this.http.post(this.pyUrl + 'SqlCommand', body)
  }
  public getEnquiry() {
    var body = {
      "Query": "select * from tblenquiry"
    }
    return this.http.post(this.pyUrl + 'SqlCommand', body)
  }
  public getAllCenter() {
    var body = {
      "Query": "select * from tblcenter"
    }
    return this.http.post(this.pyUrl + 'SqlCommand', body)
  }
  // public getDistrict() {
  //   var body = {
  //     "Query": "select Name from tbldistrict"
  //   }
  //   return this.http.post(this.pyUrl + 'SqlCommand', body)
  // }
  public getCourse(tableName) {
    var body = {
      "DbName": "AccademicDB",
      "Key": this.key,
      "Table": tableName,
    }
    return this.http.post(this.pyUrl + 'GetRows', body)
  }
  public getCorrespondingCourse(tableName, whereBody) {
    var body = {
      "DbName": "AccademicDB",
      "Key": this.key,
      "Table": tableName,
      "Where": whereBody


    }
    return this.http.post(this.pyUrl + 'GetRows', body)
  }
  public getCorrespondingCenterPlace(district) {
    console.log(district)
    var body = {
      "Query": "select * from tblcenter c left join tbldistrict d on c.DistrictId=d.Id where d.Name='" + district + "'"
    }
    return this.http.post(this.pyUrl + 'SqlCommand', body)
  }

  public getCorrespondingDistrict(district) {
    var body = {
      "Query":"select DistrictId from tblcenter c left join tbldistrict d on c.DistrictId=d.Id where c.CenterPlace= '"+ district +"'"
    }
    return this.http.post(this.pyUrl + 'SqlCommand', body)
  }
  public getDistrictId(Id) {
    var body = {
      "Query":"select Name from tbldistrict where Id='"+ Id+"'"
    }
    return this.http.post(this.pyUrl + 'SqlCommand', body)
  }

  

}

