import { Component, OnInit } from '@angular/core';
import { UserType } from '../models/usertype';
import { AuthenticationService } from '../../authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { DbService } from '../../dbService';
import { Common } from '../../models/common';

@Component({
  selector: 'app-add-new-usertype',
  templateUrl: './add-new-usertype.component.html',
  styleUrls: ['./add-new-usertype.component.css']
})
export class AddNewUsertypeComponent implements OnInit {
  isEditMode: boolean;
  isprivilegeChecked : boolean;
  newUserType: UserType = new UserType();
  userTypeList: UserType[] = [];
  isAuthenticityChecked : boolean
  constructor(private dbservice: DbService , private authenticationService:AuthenticationService,
  private cookieService  : CookieService , private router : Router) {
    this.dbservice.GetRows('UserType').subscribe(resdata => {
      this.userTypeList = new Array<UserType>();
      for (let i = 0; i <= resdata.Data.length; i++) {
        let c = new UserType();
        if (resdata.Data[i] != null) {

          c = resdata.Data[i];

          this.userTypeList.push(c);
        }
      }
      console.log(this.userTypeList, 'user type lIST')
    },
      err => {
        this.dbservice.LogError(err)
      })
  }

  ngOnInit() {
    this.authenticationService.userName = this.cookieService.get('userName');
    this.authenticationService.userId = this.cookieService.get('userId');
    this.authenticationService.userType = this.cookieService.get('userType');
    this.authenticationService.userTypeId = this.cookieService.get('userTypeId');
    this.authenticationService.password = this.cookieService.get('password');
    console.log('userName :', this.authenticationService.userName, 'userId :', this.authenticationService.userId, 'userType :',
      this.authenticationService.userType, 'userTypeId :', this.authenticationService.userTypeId, 'password', this.authenticationService.password);
    this.authenticationService.userAuthentication().subscribe(resdata => {
      console.log(resdata);
      console.log(resdata.Data.length)
      if (resdata.Data.length == 1) {
        this.authenticationService.isAutherisedUser = true;
        if (this.authenticationService.userType != 'Super Admin') {
          console.log('this is not super admin');
          let whereBody = {
            "featureId": "6263356d-89fd-4fe1-8bca-3c0ae1a3a45f",
            "userTypeId": this.authenticationService.userTypeId,
          }
          this.dbservice.GetRows('privilege', whereBody).subscribe(resdata => {
            console.log(resdata);
            if (resdata.Data.length > 0) {
              console.log('have the permission');
              this.isprivilegeChecked = true;
              this.isAuthenticityChecked = true;
              this.dbservice.isLogin = true
            }
            else {
              this.router.navigate(['/error'])
            }



          },
            err => {
              this.dbservice.LogError(err);

            },
            () => {

            }
          )

        }
        else {
          this.isAuthenticityChecked = true;
          this.dbservice.isLogin = true
          this.authenticationService.isLoggedIn=true
        }
      }
      if (this.authenticationService.isAutherisedUser != true) {
        this.router.navigate(['login'])
      }
    },
      err => {
        this.dbservice.LogError(err)
      },
      () => {
        console.log('completed');
      }
    )
  }
  register() {
    let uniqueId = Common.newGuid();
    this.newUserType.userTypeId = uniqueId
    console.log(this.newUserType);

    this.dbservice.AddRow(this.newUserType, 'UserType').subscribe(
      resdata => {
        console.log(resdata);
      },
      err => { this.dbservice.LogError(err) },
      () => {
        alert('user type added successfully');
        this.dbservice.GetRows('UserType').subscribe(resdata => {
          this.userTypeList = new Array<UserType>();
          for (let i = 0; i <= resdata.Data.length; i++) {
            let c = new UserType();
            if (resdata.Data[i] != null) {

              c = resdata.Data[i];

              this.userTypeList.push(c);
            }
          }
          console.log(this.userTypeList, 'user type lIST')
        },
          err => {
            this.dbservice.LogError(err)
          })
        //  this.userPrevilegeList.push(this.newUserPrevilege)
      }

    )
  }
}

