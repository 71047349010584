import { Component, OnInit } from '@angular/core';
import { DbService } from '../dbService';
import { Source } from '../models/Source';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-editsource',
  templateUrl: './editsource.component.html',
  styleUrls: ['./editsource.component.css']
})
export class EditsourceComponent implements OnInit {
  sourcelist:Source [] = []
  sources:Source = new Source()
  source
  constructor(
    private dbService:DbService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.dbService.GetRows('Source').subscribe(data => {
      this.sourcelist = data['Data']
      let id = this.route.snapshot.paramMap.get('uniqueId');
      let tempsource = this.sourcelist.filter(x => x.uniqueId == id)
      this.sources = tempsource[0]
      this.source = this.sources.sourcename
    })
   }

  update(){
    this.sources.sourcename = this.source
    let wherebody = {
      "uniqueId":this.sources.uniqueId
    }
    let data = {
      "sourcename":this.source
    }
    this.dbService.UpdateRows('Source',data,wherebody).subscribe(data => {
      this.router.navigate(['source'])
    })
  }

  ngOnInit() {
  }
  

}
