import { Course } from './Course';

export class CenterPlace {
    courseId: string
    CenterPlace: string
    CenterName: string;
    CenterCode: string;
    CenterCategory: string;
    CenterType: string;
    Address: string;
    Address2:string
    PostOffice: string;
    Pin: string;
    hidden:boolean;
    visible:boolean
    DistrictId: string;
    Financialyear:string
    BuildingName:string
    Mobile:number
    Mobile2:number
    LandPhone:number
}

export class Center{
    course:Course[] = new Array()
    centerDetails:CenterPlace = new CenterPlace()
}

export class tblCenter{
    Id:number;
    CenterName:string
    CenterPlace:string
    CenterCode:string;
    Mobile1:number;
    Mobile2:number
    LandPhone:number
    ExternalId:number
    CenterCategory:string;
    BuildingNo:string;
    BuildingName:string;
    HeadName:string;
    Address:string
    PostOffice:string;
    Pin:number;
    District:string;
    Taluk:string;
    Email:string
    Course:[]
    hidden:boolean
    UniqueId:string
}